<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onToArchive(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Перемещение товара в архив</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="alert alert-info">{{name}}</div>
						<div>Вы действительно хотите переместить в архив данный товар?</div>
						<div class="m-t"><span style="color: #900;">Внимание!</span> После перемещения данного товара в архив, он не будет доступен для выбора в продажах.</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Переместить в архив</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		name: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onToArchive(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doMoveToArchiveGoodsItem(this.$vfm.modals[0].params._value.goodsitem.id);
			if(ret.success){
				this.$emit('toArchive', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	mounted(){
		this.name = this.$vfm.modals[0].params._value.goodsitem.name;
	},
}
</script>