<template>
	<div class="passwordBox animated fadeInDown">
		<div class="row">
			<div class="col-md-12">
				<div class="ibox-content text-center">
					<h2 class="font-bold">Информация о ремонте</h2>
					<p>Следите за процессом ремонта Вашего музыкального инструмента по номеру ремонта</p>
					<div class="row">
						<div class="col-md-12">
							<form class="m-t" role="form" @submit.prevent="onOrderGoSubmit">
								<div class="form-group">
									<input
										type="number"
										ref="order_id"
										v-model="order_id"
										min="1"
										class="form-control text-center"
										placeholder="Номер ремонта"
										required
									/>
								</div>
								<button type="submit" class="btn btn-primary block full-width m-b" :disabled="btnOrderGoDisabled">Перейти в ремонт</button>
								<div class="alert alert-warning text-center m-t-sm m-b-none" v-if="errorOrderGo">{{errorOrderGo}}</div>
							</form>
						</div>
					</div>
				</div>
				<div class="text-center m-t">
					<router-link :to="{path: '/pricelist'}" class="btn btn-info">Прейскурант</router-link>
				</div>
			</div>
		</div>
		<hr/>
		<div class="row">
			<div class="col-md-9">MasterSMI Учет | Mikhalev's Guitars</div>
			<div class="col-md-3 text-right">
			   <small>&copy; 2018-{{new Date().getFullYear()}}</small>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Информация о ремонте | MasterSMI Учет'});
	},
	data: () => ({
		order_id: null,
		
		errorOrderGo: '',
		btnOrderGoDisabled: false,
	}),
	methods: {
		async onOrderGoSubmit(){
			this.errorOrderGo = '';
			this.btnOrderGoDisabled = true;
			
			let res = await this.appModel.doCheckOrderExists(this.order_id);
			
			this.btnOrderGoDisabled = false;
			if(res.success){
				if(res.exists){
					this.$router.push('/orderinfo/'+this.order_id);
				} else {
					this.errorOrderGo = 'Ремонт не найден';
				}
			} else {
				this.errorOrderGo = res.error;
			}
		},
	},
	mounted(){
		this.$refs.order_id.focus();
	},
	beforeMount(){
		window.scrollTo(0, 0);
		document.body.classList.add('gray-bg');
	},
	beforeUnmount(){
		document.body.classList.remove('gray-bg');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('gray-bg');
	},
};
</script>