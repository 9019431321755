import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout.vue';
import LayoutEmpty from '@/layouts/LayoutEmpty.vue';

import Login from '@/pages/Login.vue';
import Statistics from '@/pages/Statistics.vue';
import Orders from '@/pages/Orders.vue';
import OrdersCalc from '@/pages/OrdersCalc.vue';
import OrdersDetail from '@/pages/OrdersDetail.vue';
import Clients from '@/pages/Clients.vue';
import ClientsDetail from '@/pages/ClientsDetail.vue';
import Warehouse from '@/pages/Warehouse.vue';
import Sales from '@/pages/Sales.vue';
import SalesDetail from '@/pages/SalesDetail.vue';
import Goods from '@/pages/Goods.vue';
import GoodsArchive from '@/pages/GoodsArchive.vue';
import Lists from '@/pages/Lists.vue';
import ListsServices from '@/pages/ListsServices.vue';
import ListsInstrumentNames from '@/pages/ListsInstrumentNames.vue';
import ListsInstrumentTypes from '@/pages/ListsInstrumentTypes.vue';
import ListsInstrumentSizes from '@/pages/ListsInstrumentSizes.vue';
import ListsCaseNames from '@/pages/ListsCaseNames.vue';
import ListsFirms from '@/pages/ListsFirms.vue';
import ListsWhereTheyLearns from '@/pages/ListsWhereTheyLearns.vue';
import Reports from '@/pages/Reports.vue';
import ReportsFinancialOrders from '@/pages/ReportsFinancialOrders.vue';
import ReportsFinancialSales from '@/pages/ReportsFinancialSales.vue';
import ReportsOrders from '@/pages/ReportsOrders.vue';
import ReportsSales from '@/pages/ReportsSales.vue';
import ReportsInstruments from '@/pages/ReportsInstruments.vue';
import ReportsMaterials from '@/pages/ReportsMaterials.vue';
import ReportsServices from '@/pages/ReportsServices.vue';
import ReportsSalesGoods from '@/pages/ReportsSalesGoods.vue';
import ReportActPriemPril from '@/pages/ReportActPriemPril.vue';

import OutOrderInfo from '@/pages/OutOrderInfo.vue';
import OutOrderInfoDetail from '@/pages/OutOrderInfoDetail.vue';
import OutPriceList from '@/pages/OutPriceList.vue';

import Test1 from '@/pages/Test1.vue';
import Test2 from '@/pages/Test2.vue';

import PageNotFound from '@/pages/PageNotFound.vue';

const routes = [
	/*{
		path: '/',
		redirect: '/index',
	},*/
	{
		path: '/',
		component: Layout,
		children: [
			/*{
				path: '/',
				redirect: '/statistics',
			},*/
			{
				path: '',
				component: Statistics,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/orders',
				component: Orders,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/orders/calc',
				component: OrdersCalc,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/orders/:id',
				component: OrdersDetail,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/clients',
				component: Clients,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/clients/:id',
				component: ClientsDetail,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/warehouse',
				component: Warehouse,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/sales',
				component: Sales,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/sales/:id',
				component: SalesDetail,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/goods',
				component: Goods,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/goods/archive',
				component: GoodsArchive,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists',
				component: Lists,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/services',
				component: ListsServices,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/instrument-names',
				component: ListsInstrumentNames,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/instrument-types',
				component: ListsInstrumentTypes,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/instrument-sizes',
				component: ListsInstrumentSizes,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/case-names',
				component: ListsCaseNames,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/firms',
				component: ListsFirms,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/lists/where-they-learns',
				component: ListsWhereTheyLearns,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports',
				component: Reports,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/financial-orders',
				component: ReportsFinancialOrders,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/financial-sales',
				component: ReportsFinancialSales,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/orders',
				component: ReportsOrders,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/sales',
				component: ReportsSales,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/instruments',
				component: ReportsInstruments,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/materials',
				component: ReportsMaterials,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/services',
				component: ReportsServices,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports/sales-goods',
				component: ReportsSalesGoods,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/login',
		component: Login,
		meta: {
			guest: true,
		},
	},
	{
		path: '/logout',
		component: LayoutEmpty,
		beforeEnter(to, from, next){
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/login'});
			});
		},
	},
	{
		path: '/orders/:id/report-actpriempril',
		component: LayoutEmpty,
		children: [
			{
				path: '',
				component: ReportActPriemPril,
			},
		],
	},
	{
		path: '/orderinfo',
		component: LayoutEmpty,
		children: [
			{
				path: '',
				component: OutOrderInfo,
			},
			{
				path: '/orderinfo/:id',
				component: OutOrderInfoDetail,
			},
		],
	},
	{
		path: '/pricelist',
		component: LayoutEmpty,
		children: [
			{
				path: '',
				component: OutPriceList,
			},
		],
	},
	
	{
		path: '/test1',
		component: Test1,
	},
	{
		path: '/test2',
		component: Test2,
	},
	
	{
		path: '/:pathMatch(.*)*',
		component: PageNotFound,
	},
	{
		path: '/:pathMatch(.*)',
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					path: '/login',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				next()
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.userProfile !== null) next({path: '/'})
  	else next()
})*/

export default router;