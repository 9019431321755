import axios from 'axios';
import lib from '@/lib';

export default class AuthModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		// Инициализация
		this.authChecked = false;
		this.token = lib.localStorageGet('token')||'';
		//this.token = lib.getCookie('mastersmirsc_jwt')||'';
		
		this.userProfile = null;
	}
	
	/**
	 * Авторизация
	 */
	async doLogin(password)
	{
		return await fetch('/api/auth/login', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				password,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then((data) => {
			//console.log(data);
			if(data.error && data.error != ''){
				console.log(data.error||'');
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return {
					success: false,
					code: data.code,
					error: data.error,
				};
			}
			this.token = data.token;
			//user.accessToken = token;
			lib.localStorageSet('token', this.token);
			//axios.defaults.headers.common['Authorization'] = token;
			this.setUserProfile(data.user_data);
			//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
			this.authChecked = true;
			return {
				success: true,
			};
		}).catch(error => {
			console.log(error);
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Деавторизация
	 */
	async doLogout()
	{
		return await fetch('/api/auth/logout').then(stream => stream.json()).then(data => {
			//console.log(data);
			this.userProfile = null;
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return true;
		}).catch(error => {
			console.log(error);
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return false;
		});
	}
	
	/**
	 * Проверка на авторизованность
	 */
	async doCheckAuth()
	{
		if(!this.authChecked){
			return await fetch('/api/auth/checkauth').then(stream => stream.json()).then(async (data) => {
				//console.log(data);
				//console.log('auth check done');
				if(!data.auth){
					// session expired or not exists. logout
					this.userProfile = null;
					lib.localStorageRemove('token');
					this.token = '';
					this.authChecked = true;
					return true;
				}
				this.token = data.token;
				//let token = data.jwt_string;
				//user.accessToken = token;
				lib.localStorageSet('token', this.token);
				//axios.defaults.headers.common['Authorization'] = token;
				this.setUserProfile(data.user_data);
				//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
				this.authChecked = true;
				return true;
			}).catch(error => {
				console.log(error);
				this.userProfile = null;
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return false;
			});
		} else {
			return true;
		}
	}
	
	isAuth()
	{
		return this.userProfile != null;
	}
	
	getUserId()
	{
		return this.userProfile != null ? this.userProfile.user_id : '';
	}
	
	/**
	 * Устанавливает user profile
	 */
	setUserProfile(res)
	{
		if(res == null){
			this.userProfile = null;
		} else {
			this.userProfile = {
				user_id: res.id,
			};
		}
	}
}
