<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-10">
			<h2>Отчёт по продажам</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/reports'}">Отчёты</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/reports/sales'}">
						<strong>Отчёт по продажам</strong>
					</router-link>
				</li>
			</ol>
		</div>
		<div class="col-lg-2"></div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по продажам" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-8">
				<div class="ibox" :class="{'collapsed': !filterShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Фильтр <small class="badge m-l-xs">{{filter.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="filterShow = !filterShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="filterShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(fi, index) in filter">
								<label class="col-sm-3 control-label">{{getStructParam(fi.field).label}}</label>
								<div class="col-sm-2" :class="!$device.isMobile ? {paddingRight: 0} : {}">
									<select class="form-control" v-model="fi.operator">
										<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
									</select>
								</div>
								<div class="col-sm-6" :class="!$device.isMobile ? {paddingLeft: 0} : {}">
									<input type="text" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
									<input type="number" class="form-control" :class="{'m-t': $device.isMobile}" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
									<input type="date" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
									<input type="datetime-local" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
									<input type="email" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
									<select class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
										<option value=""></option>
										<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Фильтр по</label>
								<div class="col-sm-8">
									<select class="form-control" v-model="filterAddField" @change="onAddFilterParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<button type="button" class="btn btn-info" :class="{'btn-block m-t': $device.isMobile}" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
										<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="ibox" :class="{'collapsed': !sortShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Сортировка <small class="badge m-l-xs">{{sort.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="sortShow = !sortShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="sortShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(si, index) in sort">
								<label class="col-sm-6 control-label">{{getStructParam(si.field).label}}</label>
								<div class="col-sm-4">
									<select class="form-control" v-model="si.direction" @change="page = 1; loadData();">
										<option value="asc">а-я</option>
										<option value="desc">я-а</option>
									</select>
								</div>
								<div class="col-sm-2" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelSortParam(index)" content="Удалить параметр сортировки" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-6 control-label">Сортировка по</label>
								<div class="col-sm-6">
									<select class="form-control" v-model="sortAddField" @change="onAddSortParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsSort">{{elem.label}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content" :style="loading ? {background: '#eee'} : {}">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="sale in sales" :key="sale.id">
								<router-link :to="{path: '/sales/' + sale.act_id}">
									<div class="contact-box" :class="{'bg-warning': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">
										<div class="col-sm-12">
											<div class="row m-t-xs" v-if="sale.dateend != '0000-00-00 00:00:00'">
												<div class="col-xs-4">
													<b>Дата заверш.:</b>
												</div>
												<div class="col-xs-8">
													{{formatDate(sale.dateend, 'DD.MM.YYYY')}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Дата начала:</b>
												</div>
												<div class="col-xs-8">
													<div>{{formatDate(sale.dateadd, 'DD.MM.YYYY')}}</div>
													<div><small>({{getDateDuration(sale)}})</small></div>
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Статус:</b>
												</div>
												<div class="col-xs-8">
													{{sale.status_name}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-12">
													<div><b>Товары:</b></div>
													<ul style="padding: 0;" class="m-b-none">
														<li v-for="goodsitem in sale.goods" :key="goodsitem.id">
															{{goodsitem.name}} ({{number_format(goodsitem.price)}} * {{number_format(goodsitem.count)}})
														</li>
													</ul>
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>За товары:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(sale.amount_goods)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Сумма:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(sale.fullamount)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Внесено:</b>
												</div>
												<div class="col-xs-8" :class="{'text-danger': sale.amount_payed < sale.fullamount && sale.status_id != 6}">
													{{number_format(sale.amount_payed)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Долг:</b>
												</div>
												<div class="col-xs-8">
													<span v-if="sale.status_id != 6">{{number_format(parseInt(sale.fullamount) - parseInt(sale.amount_payed))}}</span>
													<span v-else>0</span>
												</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none">
								<thead>
									<tr>
										<th width="150" class="text-center">Дата, статус</th>
										<th>Клиент, товары</th>
										<th width="150" class="text-center">За товары</th>
										<th width="150" class="text-center">Сумма</th>
										<th width="150" class="text-center">Внесено</th>
										<th width="150" class="text-center">Долг</th>
										<th width="70" class="text-right" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="$device.isTablet">
									<tr @click="$router.push('/sales/' + sale.id)" v-for="sale in sales" :key="sale.id" :class="{'bg-warning': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">
										<td style="vertical-align: middle;" class="text-center">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="sale.dateend != '0000-00-00 00:00:00'">{{formatDate(sale.dateend, 'DD.MM.YYYY')}}</div>
											<div><small>({{getDateDuration(sale)}})</small></div>
											<div content="Дата начала" v-tippy="{placement: 'top'}">{{formatDate(sale.dateadd, 'DD.MM.YYYY')}}</div>
											<div>{{sale.status_name}}</div>
										</td>
										<td style="vertical-align: middle;">
											<div>{{sale.client_fio}}</div>
											<div class="hr-line-dashed m-t-xs m-b-xs"></div>
											<ul style="padding: 0;" class="m-b-none">
												<li v-for="goodsitem in sale.goods" :key="goodsitem.id">
													{{goodsitem.name}} ({{number_format(goodsitem.price)}} * {{number_format(goodsitem.count)}})
												</li>
											</ul>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(sale.amount_goods)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(sale.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">{{number_format(sale.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-center">
											<span v-if="sale.status_id != 6">{{number_format(parseInt(sale.fullamount) - parseInt(sale.amount_payed))}}</span>
											<span v-else>0</span>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="sale in sales" :key="sale.id" :class="{'bg-warning': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">
										<td style="vertical-align: middle;" class="text-center">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="sale.dateend != '0000-00-00 00:00:00'">{{formatDate(sale.dateend, 'DD.MM.YYYY')}}</div>
											<div><small>({{getDateDuration(sale)}})</small></div>
											<div content="Дата начала" v-tippy="{placement: 'top'}">{{formatDate(sale.dateadd, 'DD.MM.YYYY')}}</div>
											<div>{{sale.status_name}}</div>
										</td>
										<td style="vertical-align: middle;">
											<div>{{sale.client_fio}}</div>
											<div class="hr-line-dashed m-t-xs m-b-xs"></div>
											<ul style="padding: 0;" class="m-b-none">
												<li v-for="goodsitem in sale.goods" :key="goodsitem.id">
													{{goodsitem.name}} ({{number_format(goodsitem.price)}} * {{number_format(goodsitem.count)}})
												</li>
											</ul>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(sale.amount_goods)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(sale.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">{{number_format(sale.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-center">
											<span v-if="sale.status_id != 6">{{number_format(parseInt(sale.fullamount) - parseInt(sale.amount_payed))}}</span>
											<span v-else>0</span>
										</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/sales/' + sale.id}" style="margin-bottom: 0;" class="btn btn-default">
												<i class="fa fa-chevron-right"></i>
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Итоги:</b> <b>по товарам:</b> {{number_format(goodsSum)}}, <b>по суммам:</b> {{number_format(goodsSum)}}, <b>по внесенному:</b> {{number_format(paymentsSum)}}, <b>по долгам:</b> {{number_format(parseInt(goodsSum) - parseInt(paymentsSum))}}, <b>продаж:</b> {{number_format(salesCount)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчёт по продажам | MasterSMI Учет'});
	},
	data: () => ({
		structParams: [],
		
		salesStatuses: [],
		
		sales: [],
		salesCount: 0,
		
		goodsSum: 0,
		paymentsSum: 0,
		
		search: '',
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		sort: [
			{field: 'ls.status_id', direction: 'asc'},
			{field: 'ls.dateadd', direction: 'desc'},
		],
		sortAddField: '',
		sortShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		structParamsSort(){
			return this.structParams.filter((el) => el.sort && !this.sort.map(el => el.field).includes(el.field));
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		async loadSalesStatuses(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetSalesStatuses();
			if(ret.success){
				this.salesStatuses = ret.sales_statuses;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				search: this.search,
				filter: JSON.stringify(this.filter),
				sort: JSON.stringify(this.sort),
				page: this.page,
			})});
			let params = {
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			for(let [index, el] of Object.entries(this.sort)){
				params['sort['+index+'][field]'] = el.field;
				params['sort['+index+'][direction]'] = el.direction;
			}
			let ret = await this.appModel.doGetReportSales(params);
			if(ret.success){
				this.sales = ret.sales;
				this.salesCount = ret.sales_count;
				this.goodsSum = ret.goods_sum;
				this.paymentsSum = ret.payments_sum;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		onAddSortParam(){
			if(this.sortAddField != ''){
				this.sort.push({
					field: this.sortAddField,
					direction: 'asc',
				});
				this.sortAddField = '';
				this.page = 1;
				this.loadData();
			}
		},
		onDelSortParam(index){
			this.sort.splice(index, 1);
			this.page = 1;
			this.loadData();
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.salesCount / this.limit);
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadSalesStatuses();
		
		this.structParams = [
			{
				type: 'number',
				field: 'ls.id',
				label: 'ID продажи',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.client_id',
				label: 'ID клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'input',
				field: 'lcl.fio',
				label: 'ФИО клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'ls.status_id',
				label: 'Статус продажи',
				options: this.salesStatuses.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.amount_paid',
				label: 'Предполагаемая сумма оплаты',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.amount_goods',
				label: 'Сумма за товары',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.discount',
				label: 'Сумма скидки',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.amount_payed',
				label: 'Внесенная сумма',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'ls.dateadd',
				label: 'Дата добавления (начала)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'ls.dateend',
				label: 'Дата завершения',
				filter: true,
				sort: true,
			},
		];
		
		if(this.$route.query.search) this.search = this.$route.query.search;
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		if(this.$route.query.sort) this.sort = JSON.parse(this.$route.query.sort || '[]');
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>