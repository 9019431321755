<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Изменение элемента истории статусов ремонта</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Дата и время <span class="text-danger">*</span></label>
									<input type="datetime-local" ref="dateadd" class="form-control text-center" v-model="dateadd" required />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Статус <span class="text-danger">*</span></label>
									<select ref="status_id" class="form-control" v-model="status_id">
										<option :value="orderStat.id" v-for="orderStat in ordersStatuses" :key="orderStat.id">{{orderStat.name}}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Комментарий</label>
									<textarea ref="description" rows="2" placeholder="Текст комментария" class="form-control text-center" style="resize: none;" v-model="description"></textarea>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import autosize from 'autosize';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		ordersStatuses: [],
		
		status_id: null,
		description: '',
		dateadd: '',
		
		error: '',
		loading: false,
	}),
	computed: {},
	watch: {},
	methods: {
		async onEdit(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditHistoryStatusOrder(
				this.$vfm.modals[0].params._value.order_id,
				this.$vfm.modals[0].params._value.historystatus_id,
				{
					status_id: this.status_id,
					description: this.description,
					dateadd: moment.tz(this.dateadd, 'Asia/Almaty').utc().format('YYYY-MM-DDTHH:mm'),
				}
			);
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	mounted(){
		this.ordersStatuses = this.$vfm.modals[0].params._value.ordersStatuses;
		this.status_id = this.$vfm.modals[0].params._value.status_id;
		this.description = this.$vfm.modals[0].params._value.description;
		this.dateadd = this.formatDate(this.$vfm.modals[0].params._value.dateadd, 'YYYY-MM-DDTHH:mm');
		
		autosize(this.$refs.description);
		
		setTimeout(() => {
			this.$refs.status_id.focus();
		}, 300);
	},
	components: {},
}
</script>