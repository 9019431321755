<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-md-12">
			<h2>Информация о клиенте</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/clients'}">База клиентов</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/clients/' + $route.params.id}">
						<strong>Информация о клиенте</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row" v-if="tpl == 'loading'">
			<div class="col-md-12" style="text-align: center;">
				<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
				<h1>ЗАГРУЗКА ДАННЫХ...</h1>
			</div>
		</div>
		<div class="row" v-if="tpl == 'notexists'">
			<div class="col-md-12" style="color: #f00; text-align: center;">
				<h1>КЛИЕНТ НЕ НАЙДЕН</h1>
				<div class="m-t-lg">
					<router-link :to="{path: '/clients'}" class="btn btn-primary waves-effect waves-light">Назад</router-link>
				</div>
			</div>
		</div>
		<div class="row" v-if="tpl == 'info'">
			<div class="col-md-5">
				<div class="ibox float-e-margins">
					<div class="ibox-title">
						<h5>Информация о клиенте</h5>
					</div>
					<div>
						<div class="ibox-content no-padding border-left-right"></div>
						<div class="ibox-content profile-content">
							<h3><strong>{{clientinfo.fio}}</strong></h3>
							<div class="row m-t-xs text-danger" v-if="clientinfo.status">
								<div class="col-xs-4">
									<b>Статус:</b>
								</div>
								<div class="col-xs-8">{{clientinfo.status}}</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.tel.length > 0">
								<div class="col-xs-4">
									<b>Тел.:</b>
								</div>
								<div class="col-xs-8">
									<div v-for="client_tel in clientinfo.tel">{{format_tel(client_tel)}}</div>
								</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.mobtel.length > 0">
								<div class="col-xs-4">
									<b>Моб.тел.:</b>
								</div>
								<div class="col-xs-8">
									<div v-for="client_mobtel in clientinfo.mobtel">{{format_mobtel(client_mobtel)}}</div>
								</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.email">
								<div class="col-xs-4">
									<b>Email:</b>
								</div>
								<div class="col-xs-8">{{clientinfo.email}}</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.city">
								<div class="col-xs-4">
									<b>Город:</b>
								</div>
								<div class="col-xs-8">{{clientinfo.city}}</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.address">
								<div class="col-xs-4">
									<b>Адрес:</b>
								</div>
								<div class="col-xs-8">{{clientinfo.address}}</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.comment">
								<div class="col-xs-12">
									<b>Заметка к клиенту:</b>
								</div>
								<div class="col-xs-12">{{clientinfo.comment}}</div>
							</div>
							<div class="row m-t-xs" v-if="clientinfo.wheretheylearn_name">
								<div class="col-xs-12">
									<b>Откуда узнали о мастере:</b>
								</div>
								<div class="col-xs-12">{{clientinfo.wheretheylearn_name}}</div>
							</div>
							<div class="row m-t-sm text-muted">
								<div class="col-xs-4">
									<b>Дата:</b>
								</div>
								<div class="col-xs-8">{{formatDate(clientinfo.dateadd, 'DD.MM.YYYY в HH:mm:ss')}}</div>
							</div>
							<div v-if="clientinfo.mobtel.length > 0 || clientinfo.email">
								<hr/>
								<div class="user-button" v-if="clientinfo.mobtel.length > 0">
									<div class="visible-xs">
										<div class="row m-b-sm" v-for="client_mobtel in clientinfo.mobtel">
											<div class="col-md-4">
												<a :href="'tel:'+client_mobtel" class="btn btn-primary btn-block">
													<i class="fa fa-phone"></i> Позвонить на
													<div>{{format_mobtel(client_mobtel)}}</div>
												</a>
											</div>
											<div class="col-md-4">
												<a :href="'whatsapp://send?&phone='+client_mobtel" class="btn btn-primary btn-block">
													<i class="fa fa-envelope-o"></i> WhatsApp
													<div>{{format_mobtel(client_mobtel)}}</div>
												</a>
											</div>
											<div class="col-md-4">
												<a :href="'sms:'+client_mobtel" class="btn btn-success btn-block">
													<i class="fa fa-envelope-o"></i> Написать SMS
													<div>{{format_mobtel(client_mobtel)}}</div>
												</a>
											</div>
										</div>
									</div>
									<div class="hidden-xs">
										<div class="row m-b-sm">
											<div v-for="client_mobtel in clientinfo.mobtel">
												<div class="col-md-6">
													<a :href="'tel:'+client_mobtel" class="btn btn-primary btn-block">
														<i class="fa fa-phone"></i> Позвонить на
														<div>{{format_mobtel(client_mobtel)}}</div>
													</a>
												</div>
												<div class="col-md-6">
													<a :href="'https://wa.me/'+client_mobtel.replace('+', '')" target="_blank" class="btn btn-primary btn-block">
														<i class="fa fa-envelope-o"></i> WhatsApp
														<div>{{format_mobtel(client_mobtel)}}</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="user-button m-t-md" v-if="clientinfo.email">
									<div class="row">
										<div class="col-md-12">
											<a :href="'mailto:'+clientinfo.email" class="btn btn-info btn-block">
												<div><i class="fa fa-envelope-o"></i> Написать на почту</div>
												<div>{{clientinfo.email}}</div>
											</a>
										</div>
									</div>
								</div>
							</div>
							<hr/>
							<div class="row m-t-xs">
								<div class="col-md-4 col-xs-6">
									<b>Инструментов:</b>
								</div>
								<div class="col-md-8 col-xs-6">{{clientinfo.instruments_count}}</div>
							</div>
							<div class="row m-t-xs">
								<div class="col-md-4 col-xs-6">
									<b>Ремонтов:</b>
								</div>
								<div class="col-md-8 col-xs-6">{{orders.length}}</div>
							</div>
							<div class="row m-t-xs">
								<div class="col-md-4 col-xs-6">
									<b>На сумму:</b>
								</div>
								<div class="col-md-8 col-xs-6">{{number_format(ordersCost)}} тг</div>
							</div>
							<div class="row m-t-xs">
								<div class="col-md-4 col-xs-6">
									<b>Товаров:</b>
								</div>
								<div class="col-md-8 col-xs-6">{{sales.length}}</div>
							</div>
							<div class="row m-t-xs">
								<div class="col-md-4 col-xs-6">
									<b>На сумму:</b>
								</div>
								<div class="col-md-8 col-xs-6">{{number_format(salesCost)}} тг</div>
							</div>
							<div class="row m-t-xs">
								<div class="col-md-4 col-xs-6">
									<b>Всего внесено:</b>
								</div>
								<div class="col-md-8 col-xs-6">{{number_format(ordersPaidcost + salesPaidcost)}} тг</div>
							</div>
							<hr/>
							<div class="user-button">
								<div class="row">
									<div class="col-md-6">
										<button type="button" class="btn btn-success btn-block" @click="onShowEditModal()">
											<i class="fa fa-pencil"></i> Изменить
										</button>
									</div>
									<div class="col-md-6">
										<button type="button" class="btn btn-danger btn-block" @click="onShowDeleteModal()">
											<i class="fa fa-trash"></i> Удалить
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ibox float-e-margins">
					<div class="ibox-content no-padding border-left-right"></div>
					<div class="ibox-content profile-content">
						<div class="row m-b-n-sm">
							<div class="col-md-6">
								<button type="button" class="btn btn-primary btn-block" @click="onShowAddOrderModal()">
									<i class="fa fa-plus"></i> Добавить ремонт
								</button>
							</div>
							<div class="col-md-6">
								<button type="button" class="btn btn-primary btn-block" @click="onShowAddSaleModal()">
									<i class="fa fa-plus"></i> Добавить продажу
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-7">
				<div class="ibox float-e-margins" v-if="orders.length > 0">
					<div class="ibox-title">
						<h5>Ремонты клиента</h5>
					</div>
					<div class="ibox-content">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="order in orders" :key="order.id">
								<router-link :to="{path: '/orders/'+order.id}">
									<div class="contact-box" :class="{'bg-warning': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">
										<div class="col-sm-12">
											<div class="row m-t-xs" v-if="order.dateend != '0000-00-00 00:00:00'">
												<div class="col-xs-4">
													<b>Дата заверш.:</b>
												</div>
												<div class="col-xs-8">{{formatDate(order.dateend, 'DD.MM.YYYY')}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Дата начала:</b>
												</div>
												<div class="col-xs-8">
													<div>{{formatDate(order.dateadd, 'DD.MM.YYYY')}}</div>
													<div>({{getDateDuration(order)}})</div>
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Статус:</b>
												</div>
												<div class="col-xs-8">{{order.status_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-5">
													<b>Инструмент:</b>
												</div>
												<div class="col-xs-7">{{order.instrument_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Тип:</b>
												</div>
												<div class="col-xs-8">{{order.instrument_type_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Размер:</b>
												</div>
												<div class="col-xs-8">{{order.instrument_size_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Фирма:</b>
												</div>
												<div class="col-xs-8">{{order.instrument_firm_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Сумма:</b>
												</div>
												<div class="col-xs-8">{{number_format(order.fullamount)}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Внесено:</b>
												</div>
												<div class="col-xs-8" :class="{'text-danger': order.amount_payed < order.fullamount && order.status_id != 6}">{{number_format(order.amount_payed)}}</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none">
								<thead>
									<tr>
										<th width="100" style="vertical-align: middle;" class="text-center">Дата</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Статус</th>
										<th style="vertical-align: middle;">Инструмент, тип (размер), фирма</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Сумма</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Внесено</th>
										<th width="70" class="text-right" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="$device.isTablet">
									<tr @click="$router.push('/orders/' + order.id)" v-for="order in orders" :key="order.id" :class="{'bg-warning': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">
										<td style="vertical-align: middle; text-align: center;">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="order.dateend != '0000-00-00 00:00:00'">{{formatDate(order.dateend, 'DD.MM.YYYY')}}</div>
											<small>({{getDateDuration(order)}})</small>
											<div content="Дата начала" v-tippy="{placement: 'bottom'}">{{formatDate(order.dateadd, 'DD.MM.YYYY')}}</div>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{order.status_name}}</td>
										<td style="vertical-align: middle;">{{order.instrument_name}}, {{order.instrument_type_name}} ({{order.instrument_size_name}}), {{order.instrument_firm_name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(order.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">{{number_format(order.amount_payed)}}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="order in orders" :key="order.id" :class="{'bg-warning': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">
										<td style="vertical-align: middle; text-align: center;">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="order.dateend != '0000-00-00 00:00:00'">{{formatDate(order.dateend, 'DD.MM.YYYY')}}</div>
											<small>({{getDateDuration(order)}})</small>
											<div content="Дата начала" v-tippy="{placement: 'bottom'}">{{formatDate(order.dateadd, 'DD.MM.YYYY')}}</div>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{order.status_name}}</td>
										<td style="vertical-align: middle;">{{order.instrument_name}}, {{order.instrument_type_name}} ({{order.instrument_size_name}}), {{order.instrument_firm_name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(order.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">{{number_format(order.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/orders/'+order.id}" class="btn btn-default">
												<i class="fa fa-chevron-right"></i>
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="ibox float-e-margins" v-if="sales.length > 0">
					<div class="ibox-title">
						<h5>Приобретенные клиентом товары</h5>
					</div>
					<div class="ibox-content">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="sale in sales" :key="sale.id">
								<router-link :to="{path: '/sales/'+sale.id}">
									<div class="contact-box" :class="{'bg-warning': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">
										<div class="col-sm-12">
											<div class="row m-t-xs" v-if="sale.dateend != '0000-00-00 00:00:00'">
												<div class="col-xs-4">
													<b>Дата заверш.:</b>
												</div>
												<div class="col-xs-8">{{formatDate(sale.dateend, 'DD.MM.YYYY')}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Дата начала:</b>
												</div>
												<div class="col-xs-8">
													<div>{{formatDate(sale.dateadd, 'DD.MM.YYYY')}}</div>
													<div>({{getDateDuration(sale)}})</div>
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Статус:</b>
												</div>
												<div class="col-xs-8">
													{{sale.status_name}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-12">
													<div><b>Товары:</b></div>
													<ul style="padding: 0;" class="m-b-none">
														<li v-for="goodsitem in sale.goods" :key="goodsitem.id">
															{{goodsitem.name}} ({{number_format(goodsitem.price)}} * {{number_format(goodsitem.count)}})
														</li>
													</ul>
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Сумма:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(sale.fullamount)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Внесено:</b>
												</div>
												<div class="col-xs-8" :class="{'text-danger': sale.amount_payed < sale.fullamount && sale.status_id != 6}">
													{{number_format(sale.amount_payed)}}
												</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none">
								<thead>
									<tr>
										<th width="100" style="vertical-align: middle;" class="text-center">Дата</th>
										<th width="120" style="vertical-align: middle;" class="text-center">Статус</th>
										<th style="vertical-align: middle;">Товары</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Сумма</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Внесено</th>
										<th width="70" class="text-right" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="$device.isTablet">
									<tr @click="$router.push('/sales/' + sale.id)" v-for="sale in sales" :key="sale.id" :class="{'bg-warning': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">
										<td style="vertical-align: middle;" class="text-center">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="sale.dateend != '0000-00-00 00:00:00'">{{formatDate(sale.dateend, 'DD.MM.YYYY')}}</div>
											<small>({{getDateDuration(sale)}})</small>
											<div content="Дата начала" v-tippy="{placement: 'bottom'}">{{formatDate(sale.dateadd, 'DD.MM.YYYY')}}</div>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{sale.status_name}}</td>
										<td style="vertical-align: middle;">
											<ul style="padding: 0;" class="m-b-none">
												<li v-for="goodsitem in sale.goods" :key="goodsitem.id">
													{{goodsitem.name}} ({{number_format(goodsitem.price)}} * {{number_format(goodsitem.count)}})
												</li>
											</ul>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(sale.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">{{number_format(sale.amount_payed)}}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="sale in sales" :key="sale.id" :class="{'bg-warning': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">
										<td style="vertical-align: middle;" class="text-center">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="sale.dateend != '0000-00-00 00:00:00'">{{formatDate(sale.dateend, 'DD.MM.YYYY')}}</div>
											<small>({{getDateDuration(sale)}})</small>
											<div content="Дата начала" v-tippy="{placement: 'bottom'}">{{formatDate(sale.dateadd, 'DD.MM.YYYY')}}</div>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{sale.status_name}}</td>
										<td style="vertical-align: middle;">
											<ul style="padding: 0;" class="m-b-none">
												<li v-for="goodsitem in sale.goods" :key="goodsitem.id">
													{{goodsitem.name}} ({{number_format(goodsitem.price)}} * {{number_format(goodsitem.count)}})
												</li>
											</ul>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(sale.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': sale.amount_payed < sale.fullamount && sale.status_id != 6 && !loading}">{{number_format(sale.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/sales/'+sale.id}" class="btn btn-default">
												<i class="fa fa-chevron-right"></i>
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

import ClientEditModal from '@/components/modals/Clients/ClientEditModal.vue';
import ClientDeleteModal from '@/components/modals/Clients/ClientDeleteModal.vue';

import OrderAddModal from '@/components/modals/Orders/OrderAddModal.vue';
import SaleAddModal from '@/components/modals/Sales/SaleAddModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Информация о клиенте | MasterSMI Учет'});
	},
	data: () => ({
		tpl: 'loading',
		
		clientinfo: [],
		orders: [],
		sales: [],
		wheretheylearns: [],
		
		loading: true,
		error: '',
	}),
	computed: {
		ordersCost(){
			//return this.orders.reduce((acc, el) => acc + parseInt(el.amount_services) + parseInt(el.amount_materials), 0);
			return this.orders.reduce((acc, el) => acc + parseInt(el.fullamount), 0);
		},
		ordersPaidcost(){
			return this.orders.reduce((acc, el) => acc + parseInt(el.amount_payed), 0);
		},
		salesCost(){
			return this.sales.reduce((acc, el) => acc + parseInt(el.amount_goods), 0);
		},
		salesPaidcost(){
			return this.sales.reduce((acc, el) => acc + parseInt(el.amount_payed), 0);
		},
	},
	methods: {
		onShowEditModal(){
			let that = this;
			this.$vfm.show({
				component: ClientEditModal,
				on: {
					edit(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					wheretheylearns: that.wheretheylearns,
					client: that.clientinfo,
				},
			});
		},
		onShowDeleteModal(){
			let that = this;
			this.$vfm.show({
				component: ClientDeleteModal,
				on: {
					delete(close){
						that.$router.push({path: '/clients'});
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					client: that.clientinfo,
				},
			});
		},
		// добавление ремонта
		onShowAddOrderModal(){
			let that = this;
			this.$vfm.show({
				component: OrderAddModal,
				on: {
					add(order_ids, close){
						that.loadData();
						close();
					},
					addGoto(order_ids, close){
						if(order_ids.length > 1){
							that.filter = [];
							for(let id of order_ids){
								that.filter.push({
									field: 'lai.id',
									operator: '=',
									value: id,
								});
							}
							that.loadData();
						} else if(order_ids.length == 1){
							that.$router.push({path: '/orders/' + order_ids[0]});
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					client: that.clientinfo,
				},
			});
		},
		// добавление продажи
		onShowAddSaleModal(){
			let that = this;
			this.$vfm.show({
				component: SaleAddModal,
				on: {
					add(sale_id, close){
						that.loadData();
						close();
					},
					addGoto(sale_id, close){
						that.$router.push({path: '/sales/' + sale_id});
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					client: that.clientinfo,
				},
			});
		},
		
		async loadWhereTheyLearns(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListWhereTheyLearns();
			if(ret.success){
				this.wheretheylearns = ret.wheretheylearns;
				this.wheretheylearns.unshift({
					id: null,
					name: 'Неизвестно',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetClientData(this.$route.params.id);
			if(ret.success){
				if(ret.exists){
					this.clientinfo = ret.clientinfo;
					this.orders = ret.orders;
					this.sales = ret.sales;
					
					this.tpl = 'info';
				} else {
					this.tpl = 'notexists';
				}
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		format_tel(value){
			if(value.length == 6){
				let start = 0;
				let ch1 = value.substr(start, 2);
				let ch2 = value.substr(start + 2, 2);
				let ch3 = value.substr(start + 4, 2);
				value = ch1 + '-' + ch2 + '-' + ch3;
			}
			return value;
		},
		format_mobtel(value){
			if(value.length == 11 || value.length == 12){
				let start = 0;
				if(value.substr(0, 1) == '+') start = 1;
				let ch1 = value.substr(start, 1);
				let ch2 = value.substr(start + 1, 3);
				let ch3 = value.substr(start + 4, 3);
				let ch4 = value.substr(start + 7, 4);
				value = (start == 1 ? '+' : '') + ch1 + ' (' + ch2 + ') ' + ch3 + '-' + ch4;
			}
			return value;
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadWhereTheyLearns();
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>