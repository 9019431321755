<template>
	<div id="wrapper">
		<div id="page-wrapper" class="gray-bg">
			<div class="row border-bottom white-bg">
				<nav class="navbar navbar-static-top" role="navigation">
					<div class="navbar-header">
						<button aria-controls="navbar" aria-expanded="false" class="navbar-toggle" type="button" @click="navbarShow = !navbarShow">
							<i class="fa fa-reorder"></i>
						</button>
						<router-link :to="{path: '/orderinfo'}" class="navbar-brand">MasterSMI Учет</router-link>
					</div>
					<div class="navbar-collapse" :class="{'collapse': !navbarShow}">
						<ul class="nav navbar-top-links navbar-right">
							<li>
								<router-link :to="{path: '/orderinfo'}">
									<i class="fa fa-sign-out"></i>Назад
								</router-link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<div class="wrapper wrapper-content" :style="{padding: !$device.isMobile ? 'auto' : '20px 10px 40px'}">
				<div :class="{'container': !$device.isMobile}" v-if="loading">
					<div style="text-align: center;">
						<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
						<h1>ЗАГРУЗКА ДАННЫХ...</h1>
					</div>
				</div>
				<div :class="{'container': !$device.isMobile}" v-else>
					<div class="alert alert-info m-b-sm text-center">
						Внимание! Цены в данном прейскуранте являются ориентировочными!
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="ibox" style="margin-bottom: 10px;">
								<div class="ibox-content" style="padding: 6px 10px;">
									<select class="form-control" v-model="instrument_id" @change="page = 1; loadData();">
										<option value="" disabled="disabled">Выберите инструмент</option>
										<option :value="instrumentname.id" v-for="instrumentname in instrumentnames" :key="instrumentname.id">{{instrumentname.name}}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-md-8">
							<div class="ibox" style="margin-bottom: 10px;">
								<div class="ibox-content" style="padding: 6px 10px;">
									<form role="form" @submit.prevent="page = 1; loadData();">
										<div class="input-group">
											<input type="text" v-model="search" class="form-control" placeholder="Поиск по прейскуранту" :disabled="!instrument_id" />
											<div class="input-group-btn">
												<button type="submit" class="btn btn-info" :disabled="loading2 || !instrument_id" content="Найти" v-tippy="{placement: 'top'}">
													<i class="fa" :class="{'fa-search': !loading2, 'fa-spinner fa-spin': loading2}"></i>
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
						<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
						{{error}}
					</div>
					<div class="row" v-if="instrument_id">
						<div class="col-md-12">
							<div class="ibox float-e-margins">
								<div class="ibox-content scroll-x">
									<table class="table" style="margin-bottom: 0;">
										<thead>
											<tr>
												<th>Наименование производимой работы</th>
												<th width="15%">Стоимость</th>
											</tr>
										</thead>
										<tbody style="font-size: 16px;">
											<tr v-for="service in services" :key="service.id">
												<td style="vertical-align: middle;">{{service.name}}</td>
												<td style="vertical-align: middle;">{{number_format(service.price)}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="hr-line-dashed"></div>
							<div class="text-center m-b-lg">
								<!--
								<div class="btn-group">
									<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
								</div>
								-->
								<div v-if="$device.isMobile">
									<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
										<i class="fa fa-angle-double-left"></i>
									</span>
									<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
										<i class="fa fa-chevron-left"></i>
									</span>
									<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
										<i class="fa fa-angle-double-right"></i>
									</span>
									<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
										<i class="fa fa-chevron-right"></i>
									</span>
									<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
								</div>
								<div class="btn-group" v-else>
									<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
										<i class="fa fa-chevron-left"></i>
									</span>
									<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
									<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
									<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
									<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
									<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
									<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
										<i class="fa fa-chevron-right"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="pull-right">
					MasterSMI Учет
				</div>
				<div>
					Mikhalev's Guitars &copy; 2018-{{new Date().getFullYear()}}
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Прейскурант | MasterSMI Учет'});
	},
	data: () => ({
		navbarShow: false,
		
		instrument_id: '',
		
		instrumentnames: [],
		services: [],
		servicesCount: 0,
		
		search: '',
		page: 1,
		limit: 25,
		loading: true,
		loading2: false,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		async loadData(){
			this.loading2 = true;
			this.error = '';
			let ret = await this.appModel.doGetListServices({
				instrument_id: this.instrument_id,
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			});
			if(ret.success){
				this.services = ret.services;
				this.servicesCount = ret.services_count;
			} else {
				this.error = ret.message;
			}
			this.loading2 = false;
		},
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.servicesCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		await this.loadInstrumentNames();
		
		this.error = 'Выберите инструмент';
	},
	beforeMount(){
		window.scrollTo(0, 0);
		document.body.classList.add('top-navigation');
	},
	beforeUnmount(){
		document.body.classList.remove('top-navigation');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('top-navigation');
	},
	components: {},
};
</script>