<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-md-12">
			<h2>База товаров</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/goods'}">
						<strong>База товаров</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-1 col-md-push-8">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content hidden-xs" style="padding: 6px;">
						<router-link :to="{path: '/goods/archive'}" style="white-space: normal;" class="btn btn-block btn-info" content="Архив товаров" v-tippy="{placement: 'top'}">
							<i class="fa fa-archive"></i>
						</router-link>
					</div>
					<div class="ibox-content visible-xs" style="padding: 6px 10px;">
						<router-link :to="{path: '/goods/archive'}" style="white-space: normal;" class="btn btn-block btn-info">
							Архив товаров
						</router-link>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-md-push-8">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<button type="button" style="white-space: normal;" class="btn btn-block btn-info" @click="onShowAddModal">Добавить товар</button>
					</div>
				</div>
			</div>
			<div class="col-md-8 col-md-pull-4">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по товарам" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content scroll-x" :style="loading ? {background: '#eee'} : {}">
						<table class="table" style="margin-bottom: 0;">
							<thead>
								<tr>
									<th>&nbsp;</th>
									<th>Наименование товара</th>
									<th width="100" class="text-center">Количество</th>
									<th width="150" class="text-center">Стоимость</th>
									<th width="150" class="text-center">Сумма</th>
									<th width="100" style="min-width: 100px;" class="text-right">Операции</th>
								</tr>
							</thead>
							<tbody style="font-size: 16px;">
								<tr v-for="goodsitem in goods" :key="goodsitem.id">
									<td style="vertical-align: middle;">
										<lightgallery
											:settings="lightgallerySettings"
											:onInit="(detail) => goodsitem.lightGallery = detail.instance"
										>
											<a :href="getPoster(goodsitem.images).url">
												<img :src="getPoster(goodsitem.images).urlSmall" style="width: 50px; height: 50px;" />
											</a>
											<a :href="image.url" v-for="image in getImages(goodsitem.images)" :key="image.pkid" style="display: none;">
												<img :src="image.urlSmall" />
											</a>
										</lightgallery>
									</td>
									<td style="vertical-align: middle;">{{goodsitem.name}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(goodsitem.count)}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(goodsitem.price)}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(goodsitem.price * goodsitem.count)}}</td>
									<td style="vertical-align: middle;" class="text-right">
										<button type="button" class="btn btn-info m-r-xs" @click="onShowEditModal(goodsitem.id)">
											<i class="fa fa-pencil"></i>
										</button>
										<button type="button" class="btn btn-primary" @click="onShowToArchiveModal(goodsitem.id)" v-if="goodsitem.sales_count > 0" content="Переместить в архив" v-tippy="{placement: 'top'}">
											<i class="fa fa-archive"></i>
										</button>
										<button type="button" class="btn btn-danger" @click="onShowDeleteModal(goodsitem.id)" v-if="goodsitem.sales_count <= 0">
											<i class="fa fa-trash-o"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Товаров:</b> {{number_format(goodsCount)}}, <b>Сумма:</b> {{number_format(goodsSum)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

import GoodsItemAddModal from '@/components/modals/Goods/GoodsItemAddModal.vue';
import GoodsItemEditModal from '@/components/modals/Goods/GoodsItemEditModal.vue';
import GoodsItemToArchiveModal from '@/components/modals/Goods/GoodsItemToArchiveModal.vue';
import GoodsItemDeleteModal from '@/components/modals/Goods/GoodsItemDeleteModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'База товаров | MasterSMI Учет'});
	},
	data: () => ({
		goods: [],
		goodsCount: 0,
		goodsSum: 0,
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
		},
		
		search: '',
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	watch: {
		'goods.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					for(let goodsitem of this.goods){
						if(goodsitem.lightGallery){
							goodsitem.lightGallery.refresh();
						}
					}
				});
			},
			deep: true,
		},
	},
	methods: {
		range: lib.range,
		
		onShowAddModal(){
			let that = this;
			this.$vfm.show({
				component: GoodsItemAddModal,
				on: {
					add(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {},
			});
		},
		onShowEditModal(id){
			let that = this;
			this.$vfm.show({
				component: GoodsItemEditModal,
				on: {
					edit(close){
						that.loadData();
						close();
					},
					uploadImg(close){
						that.loadData();
					},
					deleteImg(close){
						that.loadData();
					},
					setPosterImg(close){
						that.loadData();
					},
					cancel(close){
						close();
					},
				},
				params: {
					goodsitem: that.goods.find((el) => el.id == id),
				},
			});
		},
		onShowToArchiveModal(id){
			let that = this;
			this.$vfm.show({
				component: GoodsItemToArchiveModal,
				on: {
					toArchive(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					goodsitem: that.goods.find((el) => el.id == id),
				},
			});
		},
		onShowDeleteModal(id){
			let that = this;
			this.$vfm.show({
				component: GoodsItemDeleteModal,
				on: {
					delete(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					goodsitem: that.goods.find((el) => el.id == id),
				},
			});
		},
		
		getPoster(images){
			return images.find((el) => el.poster);
		},
		getImages(images){
			return images.filter((el) => !el.poster);
		},
		
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				search: this.search,
			})});
			let ret = await this.appModel.doGetListGoods({
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			});
			if(ret.success){
				this.goods = ret.goods.map((el) => {
					if(el.images.length <= 0) el.images.push({
						id: null,
						pkid: null,
						poster: 1,
						url: '/assets/img/no-image.png',
						urlSmall: '/assets/img/no-image.png',
						descr: '',
						ugmtime_add: this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
					});
					el.lightGallery = (this.goods.find((el2) => el2.id == el.id) || {}).lightGallery || null;
					return el;
				});
				this.goodsCount = ret.goods_count;
				this.goodsSum = ret.goods_sum;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.goodsCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		if(this.$route.query.search) this.search = this.$route.query.search;
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
		Lightgallery,
	},
};
</script>