// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lightgallery.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-rotate.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-pager.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-thumbnail.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js!../../assets/css/lg-thumbnail.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-transitions.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-video.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-zoom.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lightBoxGallery .property-image[data-v-4e7ed7a7]{display:inline-block}.lightBoxGallery .property-image a.image[data-v-4e7ed7a7],.lightBoxGallery .property-image a.btn[data-v-4e7ed7a7]{display:block}.lightBoxGallery .property-image a.btn[data-v-4e7ed7a7]{margin:0px 5px 5px 5px}", "",{"version":3,"sources":["webpack://./src/pages/OutOrderInfoDetail.vue"],"names":[],"mappings":"AAUA,kDACC,oBAAA,CAED,kHAEC,aAAA,CAED,wDACC,sBAAA","sourcesContent":["\r\n@import 'lightgallery/css/lightgallery.css';\r\n@import 'lightgallery/css/lg-rotate.css';\r\n@import 'lightgallery/css/lg-pager.css';\r\n@import 'lightgallery/css/lg-thumbnail.css';\r\n@import '@/assets/css/lg-thumbnail.css';\r\n@import 'lightgallery/css/lg-transitions.css';\r\n@import 'lightgallery/css/lg-video.css';\r\n@import 'lightgallery/css/lg-zoom.css';\r\n\r\n.lightBoxGallery .property-image {\r\n\tdisplay: inline-block;\r\n}\r\n.lightBoxGallery .property-image a.image,\r\n.lightBoxGallery .property-image a.btn {\r\n\tdisplay: block;\r\n}\r\n.lightBoxGallery .property-image a.btn {\r\n\tmargin: 0px 5px 5px 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
