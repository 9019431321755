import { createApp } from 'vue/dist/vue.esm-bundler';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import axios, { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { FontAwesomeIcon } from '@/assets/plugins/font-awesome';
import Toaster from '@meforma/vue-toaster';
import device from '@/assets/plugins/vue3-device-detector';
import VueTippy from 'vue-tippy';
import { vfmPlugin } from 'vue-final-modal';
import app_mixin from "./mixin.js";
import lib from '@/lib';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap';

//import '@/assets/css/bootstrap.min.css';
//import '@/assets/css/icons.min.css';
//import '@/assets/css/app.min.css';
//import '@/assets/css/style.css';
//import '@/assets/js/date.format.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import '@/assets/plugins/bootstrap/bootstrap3-dialog/1.34.7/css/bootstrap-dialog.min.css';
import '@/main.scss';

const app = createApp(App);
const metaManager = createMetaManager();

window['appInstance'] = null;
window['appComponent'] = null;
window['storeInstance'] = null;

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
//app.config.productionTip = false;
//app.config.debug = true;
//app.config.devtools = true;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(metaManager);
app.use(Toaster);
app.use(device);
app.use(VueTippy, {
	directive: 'tippy', // => v-tippy
	component: 'tippy', // => <tippy/>
	componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
	defaultProps: {
		placement: 'auto-end',
		allowHTML: true,
		theme: 'material',
		maxWidth: 400,
		arrow: true,
		delay: [200, 0],
		duration: 0,
		//appendTo: 'parent',
    },
	content: (reference) => reference.getAttribute('title') || reference.getAttribute('content'),
});
app.use(vfmPlugin);
app.component('font-awesome-icon', FontAwesomeIcon);
lib.addMixin(app_mixin);
app.mount('#app');

window['lib'] = lib;
window['axios'] = axios;

window['appInstance'] = app;
window['storeInstance'] = store;
//window['routerInstance'] = router;

// Запускаем скрипт проверки авторизации
setTimeout(() => {
	//storeInstance.state.auth.SSI_step1();
}, 100);
