<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-danger size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Удаление элемента истории посещений</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="alert alert-info">
							<div class="row text-center elem-info">
								<div class="col-md-2">{{formatDate(historyvisit.dateadd, 'DD.MM.YYYY HH:mm')}}</div>
								<div class="col-md-10">
									<div v-if="historyvisit.ip">{{historyvisit.ip}}</div>
									<div v-if="historyvisit.country_code">Страна: {{historyvisit.country_name}} ({{historyvisit.country_code}})</div>
									<div v-if="historyvisit.device_type">Устройство: {{historyvisit.device_type}}</div>
									<div v-if="historyvisit.device_browser">Браузер: {{historyvisit.device_browser}} {{historyvisit.device_browser_version}}</div>
									<div v-if="historyvisit.device_os">ОС: {{historyvisit.device_os}} {{historyvisit.device_os_version}}</div>
								</div>
							</div>
						</div>
						<div>Вы действительно хотите безвозвратно удалить данный элемент истории посещений ремонта?</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-danger" :disabled="loading">Удалить</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>
.elem-info {
	display: flex;
	align-items: center;
}
</style>

<script>
import lib from '@/lib';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		historyvisit: {},
		
		error: '',
		loading: false,
	}),
	methods: {
		async onDelete(close){
			this.$emit('delete', close);
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	mounted(){
		this.historyvisit = this.$vfm.modals[0].params._value.historyvisit;
	},
}
</script>