<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-lg" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Добавление ремонта</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row" v-if="loading">
							<div class="col-md-12" style="text-align: center;">
								<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
								<h1>ЗАГРУЗКА ДАННЫХ...</h1>
							</div>
						</div>
						<div v-else>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Дата и время создания <span class="text-danger">*</span></label>
										<input type="datetime-local" ref="dateadd" class="form-control text-center" v-model="dateadd" required />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Фамилия, Имя и Отчество клиента <span class="text-danger">*</span></label>
										<vue-bootstrap-typeahead
											inputClass="text-center"
											placeholder="ФИО клиента"
											v-model="clientinfo.fio"
											:data="clients"
											:serializer="s => s.fio"
											@hit="onSelectClient($event)"
											@input="getClients($event.target.value)"
										>
											<template v-slot:prepend>
												<span class="input-group-btn">
													<button type="button" class="btn btn-white" :disabled="clientinfo.id == null" @click="onClearClient()" content="Отменить выбор клиента" v-tippy="{placement: 'top'}">
														<i class="fa fa-times"></i>
													</button>
												</span>
											</template>
											<template v-slot:append>
												<span class="input-group-btn" v-if="clientinfo.id">
													<router-link :to="{path: '/clients/' + clientinfo.id}" class="btn btn-white" content="Перейти к клиенту" v-tippy="{placement: 'top'}">
														<i class="fa fa-chevron-right"></i>
													</router-link>
												</span>
											</template>
										</vue-bootstrap-typeahead>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-5">
									<div class="form-group">
										<label>Телефоны</label>
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="clientinfo.tel[0].value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onTelAdd()">
													<i class="fa fa-plus"></i>
												</button>
											</span>
										</div>
									</div>
									<div class="form-group" v-for="(tel, index) in clientinfo.tel.slice(1)">
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="tel.value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onTelDel(index + 1)">
													<i class="fa fa-minus"></i>
												</button>
											</span>
										</div>
									</div>
								</div>
								<div class="col-md-7">
									<div class="form-group">
										<label>Мобильные телефоны</label>
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="clientinfo.mobtel[0].value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onMobtelAdd()">
													<i class="fa fa-plus"></i>
												</button>
											</span>
										</div>
									</div>
									<div class="form-group" v-for="(mobtel, index) in clientinfo.mobtel.slice(1)">
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="mobtel.value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onMobtelDel(index + 1)">
													<i class="fa fa-minus"></i>
												</button>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-5">
									<div class="form-group">
										<label>Откуда узнали о мастере</label>
										<select class="form-control" v-model="clientinfo.wheretheylearn_id">
											<option :value="wheretheylearn.id" v-for="wheretheylearn in wheretheylearns" :key="wheretheylearn.id">{{wheretheylearn.name}}</option>
										</select>
									</div>
								</div>
								<div class="col-md-7">
									<div class="form-group">
										<label>Статус клиента</label>
										<div class="input-group">
											<input type="text" placeholder="Статус клиента" class="form-control text-center text-danger" :style="{WebkitTextSecurity: clientStatusShow ? 'none' : 'disc'}" v-model="clientinfo.status" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="clientStatusShow = !clientStatusShow">
													<i class="fa fa-eye" :class="{'fa-eye': !clientStatusShow, 'fa-eye-slash': clientStatusShow}"></i>
												</button>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group" :class="{'m-b-none': !error}">
										<label>Заметка к клиенту</label>
										<textarea ref="description" placeholder="Заметка к клиенту" class="form-control" style="resize: none;" rows="1" v-model="clientinfo.comment"></textarea>
									</div>
								</div>
							</div>
							<div class="m-t-lg">
								<div class="panel panel-info" v-for="(instrument, index) in instruments">
									<div class="panel-body">
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label>Инструмент <span class="text-danger">*</span></label>
													<select class="form-control" v-model="instrument.instrument_id" @change="instrument.instrument_type_id = ''; instrument.instrument_size_id = '';" required>
														<option value="" disabled="disabled">Инструмент не выбран</option>
														<option :value="instrumentname.id" v-for="instrumentname in instrumentnames" :key="instrumentname.id">{{instrumentname.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label>Тип инструмента <span class="text-danger">*</span></label>
													<select class="form-control" v-model="instrument.instrument_type_id" :disabled="!instrument.instrument_id" required>
														<option value="" disabled="disabled">Тип инструмента не выбран</option>
														<option :value="instrumenttype.id" v-for="instrumenttype in instrumenttypesf(instrument.instrument_id)" :key="instrumenttype.id">{{instrumenttype.type}}</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label>Размер инструмента <span class="text-danger">*</span></label>
													<select class="form-control" v-model="instrument.instrument_size_id" :disabled="!instrument.instrument_id" required>
														<option value="" disabled="disabled">Размер инструмента не выбран</option>
														<option :value="instrumentsize.id" v-for="instrumentsize in instrumentsizesf(instrument.instrument_id)" :key="instrumentsize.id">{{instrumentsize.size}}</option>
													</select>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label>Фирма-производитель <span class="text-danger">*</span></label>
													<select class="form-control" v-model="instrument.instrument_firm_id" required>
														<option value="" disabled="disabled">Фирма не выбрана</option>
														<option :value="firm.id" v-for="firm in firms" :key="firm.id">{{firm.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label>Кейс <span class="text-danger">*</span></label>
													<select class="form-control" v-model="instrument.casename_id" required>
														<option value="" disabled="disabled">Кейс не выбран</option>
														<option :value="casename.id" v-for="casename in casenames" :key="casename.id">{{casename.name}}</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group"><label>Состояние и общее описание неисправностей</label>
													<textarea :ref="setAutosizeItemRef" class="form-control" placeholder="Состояние и общее описание неисправностей" style="resize: none;" v-model="instrument.defect_descr"></textarea>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label>Комплектность</label>
													<textarea :ref="setAutosizeItemRef" class="form-control" placeholder="Комплектность" style="resize: none;" v-model="instrument.complection"></textarea>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<label>Комментарий</label>
													<textarea :ref="setAutosizeItemRef" class="form-control" placeholder="Комментарий" style="resize: none;" v-model="instrument.comment"></textarea>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label>
														<span class="visible-xs">Предполаг. стоимость ремонта</span>
														<span class="hidden-xs">Предполагаемая стоимость ремонта</span>
													</label>
													<input type="number" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="instrument.amount_paid" placeholder="Предполагаемая стоимость" />
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label>Предоплата</label>
													<input type="number" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="instrument.amount_prepaid" placeholder="Сумма предоплаты" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12 text-center">
												<button type="button" class="btn btn-sm btn-danger" @click="onInstrumentDel(index)">Удалить данный инструмент</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 text-center">
									<div class="btn btn-primary" @click="onInstrumentAdd">Добавить инструмент</div>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" ref="submit" style="display: none;"></button>
						<button type="button" class="btn btn-primary" @click="onAddClose" :disabled="loading || loading2 || clientinfo.fio == '' || instruments.length <= 0">Сохранить</button>
						<button type="button" class="btn btn-primary" @click="onAddGoto" :disabled="loading || loading2 || clientinfo.fio == '' || instruments.length <= 0">Сохранить и перейти</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import { vMaska } from 'maska';
import autosize from 'autosize';
import moment from 'moment-timezone';
import underscore from 'underscore';

import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead/VueBootstrapTypeahead.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	directives: {
		maska: vMaska,
	},
	data: () => ({
		autosizeItemRefs: [],
		
		instrumentnames: [],
		instrumenttypes: [],
		instrumentsizes: [],
		firms: [],
		casenames: [],
		wheretheylearns: [],
		clients: [],
		
		clientStatusShow: false,
		
		dateadd: '',
		clientinfo: {
			id: null,
			fio: '',
			tel: [{
				value: '',
			}],
			mobtel: [{
				value: '',
			}],
			status: '',
			comment: '',
			wheretheylearn_id: null,
		},
		instruments: [],
		
		action: '',
		
		error: '',
		loading: false,
		loading2: false,
	}),
	computed: {},
	watch: {},
	methods: {
		instrumenttypesf(instrument_id){
			return this.instrumenttypes.filter((el) => el.instrument_id == instrument_id);
		},
		instrumentsizesf(instrument_id){
			return this.instrumentsizes.filter((el) => el.instrument_id == instrument_id);
		},
		
		// подгрузка списка клиентов для typeahead и изменение фио клиента при редактировании
		getClients: underscore.debounce(async function(query){
			//this.clientinfo.fio = query;
			let ret = await this.appModel.doGetListClients({
				'filter[0][field]': 'lcl.fio',
				'filter[0][operator]': 'like',
				'filter[0][value]': '%'+query+'%',
				'sort[0][field]': 'lcl.fio',
				'sort[0][direction]': 'asc',
				limit: 25,
			});
			if(ret.success){
				this.clients = ret.clients.map((el) => {
					el.tel = JSON.parse(el.tel || '[]');
					el.mobtel = JSON.parse(el.mobtel || '[]');
					return el;
				});
			}
		}, 500),
		// загрузка списка инструментов
		async loadInstrumentNames(){
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
			} else {
				this.error = ret.message;
			}
		},
		// загрузка списка типов инструментов
		async loadInstrumentTypes(){
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentTypes();
			if(ret.success){
				this.instrumenttypes = ret.instrumenttypes;
			} else {
				this.error = ret.message;
			}
		},
		// загрузка списка размеров инструментов
		async loadInstrumentSizes(){
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentSizes();
			if(ret.success){
				this.instrumentsizes = ret.instrumentsizes;
			} else {
				this.error = ret.message;
			}
		},
		// загрузка списка фирм-производителей
		async loadFirms(){
			this.error = '';
			let ret = await this.appModel.doGetListFirms();
			if(ret.success){
				this.firms = ret.firms;
			} else {
				this.error = ret.message;
			}
		},
		// загрузка списка кейсов
		async loadCaseNames(){
			this.error = '';
			let ret = await this.appModel.doGetListCaseNames();
			if(ret.success){
				this.casenames = ret.casenames;
			} else {
				this.error = ret.message;
			}
		},
		// загрузка списка "откуда узнали о мастере"
		async loadWhereTheyLearns(){
			this.error = '';
			let ret = await this.appModel.doGetListWhereTheyLearns();
			if(ret.success){
				this.wheretheylearns = ret.wheretheylearns;
				this.wheretheylearns.unshift({
					id: null,
					name: 'Неизвестно',
				});
			} else {
				this.error = ret.message;
			}
		},
		
		// выбор клиента через typeahead
		onSelectClient(val){
			//let tel = JSON.parse(val.tel || '[]');
			//let mobtel = JSON.parse(val.mobtel || '[]');
			this.clientinfo = {
				id: val.id,
				fio: val.fio,
				tel: (val.tel.length > 0 ? val.tel : ['']).map((el) => ({value: el})),
				mobtel: (val.mobtel.length > 0 ? val.mobtel : ['']).map((el) => ({value: el})),
				status: val.status,
				comment: val.comment,
				wheretheylearn_id: val.wheretheylearn_id,
			};
		},
		// отмена выбора клиента
		onClearClient(){
			if(confirm('Вы уверены?')){
				this.clientinfo = {
					id: null,
					fio: '',
					tel: [{
						value: '',
					}],
					mobtel: [{
						value: '',
					}],
					status: '',
					comment: '',
					wheretheylearn_id: null,
				};
			}
		},
		// добавление новой строки для ввода телефона клиента
		onTelAdd(){
			this.clientinfo.tel.push({value: ''});
		},
		// удаление строки ввода телефона клиента
		onTelDel(index){
			this.clientinfo.tel.splice(index, 1);
		},
		// добавление новой строки для ввода мобильного телефона клиента
		onMobtelAdd(){
			this.clientinfo.mobtel.push({value: ''});
		},
		// удаление строки ввода мобильного телефона клиента
		onMobtelDel(index){
			this.clientinfo.mobtel.splice(index, 1);
		},
		// добавление данных об инструменте в список
		onInstrumentAdd(){
			this.instruments.push({
				instrument_id: '',
				instrument_type_id: '',
				instrument_size_id: '',
				instrument_firm_id: '',
				casename_id: '',
				complection: '',
				defect_descr: '',
				comment: '',
				amount_paid: '',
				amount_prepaid: '',
			});
		},
		// удаление данных об инструменте из списка
		onInstrumentDel(index){
			this.instruments.splice(index, 1);
		},
		
		setAutosizeItemRef(el){
			if(el){
				this.autosizeItemRefs.push(el);
				autosize(el);
			}
		},
		
		onAddClose(){
			this.action = 'add';
			this.$refs.submit.click();
		},
		onAddGoto(){
			this.action = 'addGoto';
			this.$refs.submit.click();
		},
		async onAdd(close){
			this.loading2 = true;
			this.error = '';
			let ret = await this.appModel.doAddOrder({
				client_id: this.clientinfo.id,
				fio: this.clientinfo.fio,
				tel: this.clientinfo.tel.filter((el) => el.value != '').map((el) => el.value),
                mobtel: this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value),
				status: this.clientinfo.status,
				description: this.clientinfo.comment,
				wheretheylearn_id: this.clientinfo.wheretheylearn_id,
				dateadd: moment.tz(this.dateadd, 'Asia/Almaty').utc().format('YYYY-MM-DDTHH:mm'),
				instruments: this.instruments,
			});
			if(ret.success){
				this.$emit(this.action, ret.id, close);
			} else {
				this.error = ret.message;
			}
			this.action = '';
			this.loading2 = false;
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		if(this.$vfm.modals[0].params._value.client){
			this.onSelectClient(this.$vfm.modals[0].params._value.client);
		}
		
		this.dateadd = this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm');
		
		this.loading = true;
		await this.loadInstrumentNames();
		await this.loadInstrumentTypes();
		await this.loadInstrumentSizes();
		await this.loadFirms();
		await this.loadCaseNames();
		await this.loadWhereTheyLearns();
		this.loading = false;
		
		this.onInstrumentAdd();
		
		autosize(this.$refs.description);
	},
	components: {
		VueBootstrapTypeahead,
	},
	beforeUpdate(){
		this.autosizeItemRefs = [];
	},
}
</script>