<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Изменение кейса</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="form-group" :class="{'m-b-none': !error}">
							<label>Название кейса <span class="text-danger">*</span></label>
							<input type="text" ref="caseName" placeholder="Название кейса" class="form-control" v-model="caseName" required />
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		caseName: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onEdit(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditCaseName(this.$vfm.modals[0].params._value.casename.id, {
				name: this.caseName,
			});
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	mounted(){
		this.caseName = this.$vfm.modals[0].params._value.casename.name;
		
		setTimeout(() => {
			this.$refs.caseName.focus();
		}, 300);
	},
}
</script>