<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-12">
			<h2>Статистика</h2>
			<ol class="breadcrumb">
				<li class="active">
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="alert alert-warning alert-dismissable" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="widget style1 m-b" :class="{'red-bg': fullDolgOrders > 0 && !loading, 'lazur-bg': fullDolgOrders <= 0 && !loading, 'yellow-bg': loading}">
					<div class="row">
						<div class="col-xs-4">
							<i class="fa fa-5x">₸</i>
						</div>
						<div class="col-xs-8 text-right">
							<b>Клиент. долг (ремонты)</b>
							<h2 class="font-bold" v-if="loading">
								<i class="fa fa-spinner fa-spin"></i>
							</h2>
							<h2 class="font-bold" v-else>{{number_format(fullDolgOrders)}} тг</h2>
						</div>
					</div>
				</div>
				<div class="ibox m-b">
					<div class="ibox-title">
						<h5>Статистика ремонтов</h5>
					</div>
					<div class="ibox-content no-padding">
						<div class="list-group">
							<li class="list-group-item text-center" v-if="loading">
								<i class="fa fa-spinner fa-spin"></i> Загрузка...
							</li>
							<router-link :to="{path: '/orders', query: (orderStat.id ? {filter: JSON.stringify([{
								field: 'lai.status_id',
								operator: '=',
								value: orderStat.id
							}])} : {})}" class="list-group-item" v-for="orderStat in ordersStatuses" :key="orderStat.id">
								<span class="badge badge-info">{{number_format(orderStat.count)}}</span>
								{{orderStat.name}}
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="widget style1 m-b" :class="{'red-bg': fullDolgSales > 0 && !loading, 'lazur-bg': fullDolgSales <= 0 && !loading, 'yellow-bg': loading}">
					<div class="row">
						<div class="col-xs-4">
							<i class="fa fa-5x">₸</i>
						</div>
						<div class="col-xs-8 text-right">
							<b>Клиент. долг (продажи)</b>
							<h2 class="font-bold" v-if="loading">
								<i class="fa fa-spinner fa-spin"></i>
							</h2>
							<h2 class="font-bold" v-else>{{number_format(fullDolgSales)}} тг</h2>
						</div>
					</div>
				</div>
				<div class="ibox m-b">
					<div class="ibox-title">
						<h5>Статистика продаж</h5>
					</div>
					<div class="ibox-content no-padding">
						<div class="list-group">
							<li class="list-group-item text-center" v-if="loading">
								<i class="fa fa-spinner fa-spin"></i> Загрузка...
							</li>
							<router-link :to="{path: '/sales', query: (saleStat.id ? {filter: JSON.stringify([{
								field: 'ls.status_id',
								operator: '=',
								value: saleStat.id
							}])} : {})}" class="list-group-item" v-for="saleStat in salesStatuses" :key="saleStat.id">
								<span class="badge badge-info">{{number_format(saleStat.count)}}</span>
								{{saleStat.name}}
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="widget style1 m-b" :class="{'lazur-bg': !loading, 'yellow-bg': loading}">
					<div class="row">
						<div class="col-xs-4">
							<i class="fa fa-hdd-o fa-5x"></i>
						</div>
						<div class="col-xs-8 text-right">
							<b>Занято на диске</b>
							<h2 class="font-bold" v-if="loading">
								<i class="fa fa-spinner fa-spin"></i>
							</h2>
							<h2 class="font-bold" v-else>{{formatSizeUnits(diskSizeValue)}}</h2>
						</div>
					</div>
				</div>
				<div class="ibox m-b">
					<div class="ibox-title">
						<h5>Откуда узнали о мастере</h5>
					</div>
					<div class="ibox-content no-padding">
						<ul class="list-group">
							<li class="list-group-item text-center" v-if="loading">
								<i class="fa fa-spinner fa-spin"></i> Загрузка...
							</li>
							<router-link :to="{path: '/clients', query: (wtl.id ? {filter: JSON.stringify([{
								field: 'lcl.wheretheylearn_id',
								operator: '=',
								value: wtl.id
							}])} : {})}" class="list-group-item" v-for="wtl in wheretheylearnsStat" :key="wtl.id">
								<span class="badge badge-info">{{number_format(wtl.count)}}</span>
								<span class="badge badge-info">{{wtl.percentage}}%</span>
								{{wtl.name}}
							</router-link>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="ibox m-b">
					<div class="ibox-title">
						<h5>Курс валют</h5>
					</div>
					<div class="ibox-content text-center">
						<iframe src="https://kurs.kz/informers/informer_frame.php" width="240" height="350" frameborder="no" scrolling="no"></iframe>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="ibox m-b">
					<div class="ibox-title">
						<h5>Дата запуска сервиса: 11.08.2017</h5>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="ibox m-b">
					<div class="ibox-title">
						<h5>Дата обновления сервиса: 10.12.2023</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Статистика | MasterSMI Учет'});
	},
	data: () => ({
		diskSizeValue: 0,
		fullDolgOrders: 0,
		fullDolgSales: 0,
		wheretheylearnsStat: [],
		ordersStatuses: [],
		salesStatuses: [],
		
		loading: false,
		error: '',
	}),
	computed: {
		
	},
	methods: {
		async loadData(){
			this.loading = true;
			this.error = '';
			let stat = await this.appModel.doGetStatisticsData();
			if(stat.success){
				this.diskSizeValue = stat.disk_size_value;
				this.fullDolgOrders = stat.full_dolg_orders;
				this.fullDolgSales = stat.full_dolg_sales;
				this.wheretheylearnsStat = stat.wheretheylearns_stat;
				this.ordersStatuses = stat.orders_statuses;
				this.salesStatuses = stat.sales_statuses;
			} else {
				this.error = stat.message;
			}
			this.loading = false;
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatSizeUnits(value){
			return lib.formatSizeUnits(value, 2, ['B', 'KB', 'MB', 'GB', 'TB']);
		},
	},
	async mounted(){
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>