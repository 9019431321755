<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-danger size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Удаление производимой работы</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="alert alert-info">{{name}}</div>
						<div>Вы действительно хотите безвозвратно удалить данную производимую работу?</div>
						<div class="tabs-container m-t-lg">
							<ul class="nav nav-tabs">
								<li :class="{'active': action == 'only-delete'}">
									<a @click="action = 'only-delete'">Только удалить</a>
								</li>
								<li :class="{'active': action == 'delete-replace'}" v-if="orders_count > 0">
									<a @click="action = 'delete-replace'">Удалить с заменой</a>
								</li>
							</ul>
							<div class="tab-content">
								<div class="tab-pane" :class="{'active': action == 'only-delete'}" v-show="action == 'only-delete'">
									<div class="panel-body">
										<div><span style="color: #900;">Внимание!</span> При удалении данной производимой работы, в ремонтах, где она была добавлена, будет потеряна с ней связь, но сами производимые работы в ремонтах удалены не будут.</div>
									</div>
								</div>
								<div class="tab-pane" :class="{'active': action == 'delete-replace'}" v-show="action == 'delete-replace'">
									<div class="panel-body">
										<div><span style="color: #900;">Внимание!</span> При удалении данной производимой работы, в ремонтах, где она была добавлена, будет произведена ее замена на выбранную ниже. Предыдущие названия производимой работы в ремонтах будут изменены на заменяемую.</div>
										<div class="row m-t">
											<div class="col-md-12">
												<div class="form-group">
													<label>Название производимой работы</label>
													<input type="text" ref="serviceName" placeholder="Название производимой работы" class="form-control" v-model="serviceName" />
												</div>
											</div>
										</div>
										<div class="row m-b-n">
											<div class="col-md-12 m-b text-center" v-if="loadingServices && services.length <= 0">
												<i class="fa fa-spinner fa-spin m-r-sm"></i> Поиск производимых работ...
											</div>
											<div class="col-md-12" :style="loadingServices ? {background: '#eee'} : {}" v-else>
												<a @click="onSelect(index)" class="list-group-item" :class="{'active': service.id == replace_service_id}" v-for="(service, index) in services" :key="service.id">
													<div class="m-l-md pull-right">
														<small>{{number_format(service.price)}} тг</small>
													</div>
													<span>{{service.name}}</span>
												</a>
												<div class="text-center m-t" v-if="getPagesCount() > 1">
													<!--
													<div class="btn-group">
														<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadServices();" v-for="p in getPagesCount()">{{p}}</a>						
													</div>
													-->
													<div v-if="$device.isMobile">
														<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
															<i class="fa fa-angle-double-left"></i>
														</span>
														<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
															<i class="fa fa-chevron-left"></i>
														</span>
														<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
															<i class="fa fa-angle-double-right"></i>
														</span>
														<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
															<i class="fa fa-chevron-right"></i>
														</span>
														<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
													</div>
													<div class="btn-group" v-else>
														<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
															<i class="fa fa-chevron-left"></i>
														</span>
														<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
														<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
														<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
														<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
														<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
														<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
															<i class="fa fa-chevron-right"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-danger" :disabled="loading" v-if="action == 'only-delete'">Удалить</button>
						<button type="submit" class="btn btn-danger" :disabled="loading || !replace_service_id" v-if="action == 'delete-replace'">Удалить с заменой</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		action: 'only-delete',
		
		instrument_id: null,
		name: '',
		orders_count: 0,
		
		serviceName: '',
		servicesTimeoutId: null,
		services: [],
		servicesCount: 0,
		
		replace_service_id: null,
		
		page: 1,
		limit: 10,
		error: '',
		loadingServices: false,
		loading: false,
		
		paginationAlpha: 3,
	}),
	computed: {
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	watch: {
		serviceName(val, oldVal){
			this.replace_service_id = null;
			clearTimeout(this.servicesTimeoutId);
			this.servicesTimeoutId = setTimeout(async () => await this.loadServices(), 500);
		},
	},
	methods: {
		range: lib.range,
		
		async loadServices(){
			this.loadingServices = true;
			this.error = '';
			let params = {
				instrument_id: this.instrument_id,
				search: this.serviceName,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			let filter = [
				{
					field: 'ls.id',
					operator: '!=',
					value: this.$vfm.modals[0].params._value.service.id,
				},
			];
			for(let [index, el] of Object.entries(filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			let ret = await this.appModel.doGetListServices(params);
			if(ret.success){
				this.services = ret.services;
				this.servicesCount = ret.services_count;
			} else {
				this.error = ret.message;
			}
			this.loadingServices = false;
		},
		
		// выбрать услугу
		onSelect(index){
			this.replace_service_id = this.services[index].id;
		},
		async onDelete(close){
			this.loading = true;
			this.error = '';
			let ret = {
				success: false,
				message: 'unknown method',
			};
			if(this.action == 'only-delete'){
				ret = await this.appModel.doDeleteService(this.$vfm.modals[0].params._value.service.id);
			} else if(this.action == 'delete-replace'){
				ret = await this.appModel.doReplaceAndDeleteService(this.$vfm.modals[0].params._value.service.id, this.replace_service_id);
			}
			if(ret.success){
				this.$emit('delete', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadServices();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.servicesCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		this.orders_count = this.$vfm.modals[0].params._value.service.orders_count;
		this.instrument_id = this.$vfm.modals[0].params._value.service.instrument_id;
		this.name = this.$vfm.modals[0].params._value.service.name;
		
		await this.loadServices();
	},
}
</script>