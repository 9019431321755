<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-md-12">
			<h2>База клиентов</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/clients'}">
						<strong>База клиентов</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-3 col-md-push-9">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<button type="button" style="white-space: normal;" class="btn btn-block btn-info" @click="onShowAddModal">Добавить клиента</button>
					</div>
				</div>
			</div>
			<div class="col-md-9 col-md-pull-3">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по клиентам (ФИО, городу, адресу, телефонам, email, статусу, комментарию)" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-8">
				<div class="ibox" :class="{'collapsed': !filterShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Фильтр <small class="badge m-l-xs">{{filter.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="filterShow = !filterShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="filterShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(fi, index) in filter">
								<label class="col-sm-3 control-label">{{getStructParam(fi.field).label}}</label>
								<div class="col-sm-2" :class="!$device.isMobile ? {paddingRight: 0} : {}">
									<select class="form-control" v-model="fi.operator">
										<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
									</select>
								</div>
								<div class="col-sm-6" :class="!$device.isMobile ? {paddingLeft: 0} : {}">
									<input type="text" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
									<input type="number" class="form-control" :class="{'m-t': $device.isMobile}" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
									<input type="date" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
									<input type="datetime-local" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
									<input type="email" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
									<select class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
										<option value=""></option>
										<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Фильтр по</label>
								<div class="col-sm-8">
									<select class="form-control" v-model="filterAddField" @change="onAddFilterParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<button type="button" class="btn btn-info" :class="{'btn-block m-t': $device.isMobile}" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
										<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="ibox" :class="{'collapsed': !sortShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Сортировка <small class="badge m-l-xs">{{sort.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="sortShow = !sortShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="sortShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(si, index) in sort">
								<label class="col-sm-6 control-label">{{getStructParam(si.field).label}}</label>
								<div class="col-sm-4">
									<select class="form-control" v-model="si.direction" @change="page = 1; loadData();">
										<option value="asc">а-я</option>
										<option value="desc">я-а</option>
									</select>
								</div>
								<div class="col-sm-2" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelSortParam(index)" content="Удалить параметр сортировки" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-6 control-label">Сортировка по</label>
								<div class="col-sm-6">
									<select class="form-control" v-model="sortAddField" @change="onAddSortParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsSort">{{elem.label}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content" :style="loading ? {background: '#eee'} : {}">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="client in clients" :key="client.id">
								<router-link :to="{path: '/clients/'+client.id}">
									<div class="contact-box">
										<div class="col-sm-12">
											<h3><strong>{{client.fio}}</strong></h3>
											<div class="row m-t-xs text-danger" v-if="client.status">
												<div class="col-xs-4">
													<b>Статус:</b>
												</div>
												<div class="col-xs-8">{{client.status}}</div>
											</div>
											<div class="row m-t-xs" v-if="JSON.parse(client.tel).length > 0">
												<div class="col-xs-4">
													<b>Тел.:</b>
												</div>
												<div class="col-xs-8">
													<div v-for="client_tel in JSON.parse(client.tel)">{{format_tel(client_tel)}}</div>
												</div>
											</div>
											<div class="row m-t-xs" v-if="JSON.parse(client.mobtel).length > 0">
												<div class="col-xs-4">
													<b>Моб.тел.:</b>
												</div>
												<div class="col-xs-8">
													<div v-for="client_mobtel in JSON.parse(client.mobtel)">{{format_mobtel(client_mobtel)}}</div>
												</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none table-clients">
								<thead>
									<tr>
										<th>Фамилия, Имя и Отчество клиента</th>
										<th width="100" v-if="!$device.isTablet">&nbsp;</th>
										<th width="100" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody style="font-size: 16px;" v-if="$device.isTablet">
									<tr @click="$router.push('/clients/'+client.id)" v-for="client in clients" :key="client.id">
										<td style="vertical-align: middle;">
											<div>{{client.fio}}</div>
											<div class="text-danger" v-if="client.status">({{client.status}})</div>
										</td>
									</tr>
								</tbody>
								<tbody style="font-size: 16px;" v-else>
									<tr v-for="client in clients" :key="client.id">
										<td style="vertical-align: middle;">{{client.fio}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<div class="btn btn-danger" style="margin-bottom: 0;" data-toggle="tooltip" data-html="true" data-placement="left" :content="client.status" v-tippy="{placement: 'left'}" v-if="client.status">
												<i class="fa fa-warning"></i>
											</div>
										</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/clients/'+client.id}" style="margin-bottom: 0;" class="btn btn-default">Подробнее</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Клиентов:</b> {{number_format(clientsCount)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import ClientAddModal from '@/components/modals/Clients/ClientAddModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'База клиентов | MasterSMI Учет'});
	},
	data: () => ({
		structParams: [],
		
		wheretheylearns: [],
		
		clients: [],
		clientsCount: 0,
		
		search: '',
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		sort: [
			{field: 'lcl.dateadd', direction: 'desc'},
		],
		sortAddField: '',
		sortShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		structParamsSort(){
			return this.structParams.filter((el) => el.sort && !this.sort.map(el => el.field).includes(el.field));
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		onShowAddModal(){
			let that = this;
			this.$vfm.show({
				component: ClientAddModal,
				on: {
					add(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					wheretheylearns: that.wheretheylearns,
				},
			});
		},
		
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				search: this.search,
				filter: JSON.stringify(this.filter),
				sort: JSON.stringify(this.sort),
				page: this.page,
			})});
			let params = {
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			for(let [index, el] of Object.entries(this.sort)){
				params['sort['+index+'][field]'] = el.field;
				params['sort['+index+'][direction]'] = el.direction;
			}
			let ret = await this.appModel.doGetListClients(params);
			if(ret.success){
				this.clients = ret.clients;
				this.clientsCount = ret.clients_count;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadWhereTheyLearns(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListWhereTheyLearns();
			if(ret.success){
				this.wheretheylearns = ret.wheretheylearns;
				this.wheretheylearns.unshift({
					id: null,
					name: 'Неизвестно',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		onAddSortParam(){
			if(this.sortAddField != ''){
				this.sort.push({
					field: this.sortAddField,
					direction: 'asc',
				});
				this.sortAddField = '';
				this.page = 1;
				this.loadData();
			}
		},
		onDelSortParam(index){
			this.sort.splice(index, 1);
			this.page = 1;
			this.loadData();
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.clientsCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		format_tel(value){
			if(value.length == 6){
				let start = 0;
				let ch1 = value.substr(start, 2);
				let ch2 = value.substr(start + 2, 2);
				let ch3 = value.substr(start + 4, 2);
				value = ch1 + '-' + ch2 + '-' + ch3;
			}
			return value;
		},
		format_mobtel(value){
			if(value.length == 11 || value.length == 12){
				let start = 0;
				if(value.substr(0, 1) == '+') start = 1;
				let ch1 = value.substr(start, 1);
				let ch2 = value.substr(start + 1, 3);
				let ch3 = value.substr(start + 4, 3);
				let ch4 = value.substr(start + 7, 4);
				value = (start == 1 ? '+' : '') + ch1 + ' (' + ch2 + ') ' + ch3 + '-' + ch4;
			}
			return value;
		},
	},
	async mounted(){
		await this.loadWhereTheyLearns();
		
		this.structParams = [
			{
				type: 'number',
				field: 'lcl.id',
				label: 'ID клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lcl.fio',
				label: 'ФИО клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lcl.city',
				label: 'Город проживания клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lcl.address',
				label: 'Адрес клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'input',
				field: 'lcl.tel',
				label: 'Телефоны клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'input',
				field: 'lcl.mobtel',
				label: 'Мобильные телефоны клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'input',
				field: 'lcl.email',
				label: 'Email клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lcl.status',
				label: 'Статус клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lcl.comment',
				label: 'Комментарий к клиенту',
				filter: true,
				sort: false,
			},
			{
				type: 'select',
				field: 'lcl.wheretheylearn_id',
				label: 'Откуда узнали о мастере',
				options: this.wheretheylearns.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lcl.dateadd',
				label: 'Дата добавления',
				filter: true,
				sort: true,
			},
		];
		
		if(this.$route.query.search) this.search = this.$route.query.search;
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		if(this.$route.query.sort) this.sort = JSON.parse(this.$route.query.sort || '[]');
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>