<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-10">
			<h2>Отчёты</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/reports'}">
						<strong>Отчёты</strong>
					</router-link>
				</li>
			</ol>
		</div>
		<div class="col-lg-2"></div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/financial-orders'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Финансовый отчёт по ремонтам</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/orders'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Отчёт по ремонтам</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/instruments'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Отчёт по инструментам</h5>
						</div>
					</div>
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/materials'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Отчёт по использованным материалам и комплектующим</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/services'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Отчёт по оказанным услугам</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4"></div>
		</div>
		<div class="hr-line-dashed"></div>
		<div class="row">
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/financial-sales'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Финансовый отчёт по продажам</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/sales'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Отчёт по продажам</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/reports/sales-goods'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5 style="float: none;">Отчёт по проданным товарам</h5>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчёты | MasterSMI Учет'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>