<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-10">
			<h2>Отчёт по инструментам</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/reports'}">Отчёты</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/reports/instruments'}">
						<strong>Отчёт по инструментам</strong>
					</router-link>
				</li>
			</ol>
		</div>
		<div class="col-lg-2"></div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" :class="{'collapsed': !filterShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Фильтр <small class="badge m-l-xs">{{filter.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="filterShow = !filterShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="filterShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(fi, index) in filter">
								<label class="col-sm-3 control-label">{{getStructParam(fi.field).label}}</label>
								<div class="col-sm-2" :class="!$device.isMobile ? {paddingRight: 0} : {}">
									<select class="form-control" v-model="fi.operator">
										<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
									</select>
								</div>
								<div class="col-sm-6" :class="!$device.isMobile ? {paddingLeft: 0} : {}">
									<input type="text" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
									<input type="number" class="form-control" :class="{'m-t': $device.isMobile}" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
									<input type="date" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
									<input type="datetime-local" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
									<input type="email" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
									<select class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
										<option value=""></option>
										<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Фильтр по</label>
								<div class="col-sm-8">
									<select class="form-control" v-model="filterAddField" @change="onAddFilterParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<button type="button" class="btn btn-info" :class="{'btn-block m-t': $device.isMobile}" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
										<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Показывать инструменты без ремонтов</label>
								<div class="col-sm-9">
									<div class="btn-group">
										<button class="btn" :class="{'btn-primary': showCount0, 'btn-white': !showCount0}" type="button" @click="showCount0 = true">Да</button>
										<button class="btn" :class="{'btn-primary': !showCount0, 'btn-white': showCount0}" type="button" @click="showCount0 = false">Нет</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content" :style="loading ? {background: '#eee'} : {}">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="inel in instrumentsf" :key="inel.id">
								<router-link :to="{path: '/orders', query: (inel.orders_count > 0 ? {filter: JSON.stringify([{
									field: 'lai.instrument_id',
									operator: '=',
									value: inel.id
								}])} : {})}">
									<div class="contact-box">
										<div class="col-sm-12">
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Инструмент:</b>
												</div>
												<div class="col-xs-8">
													{{inel.name}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Ремонты:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.orders_count)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Услуги:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.amount_services)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Материалы:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.amount_materials)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Сумма:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.fullamount)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Внесено:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.amount_payed)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Долг:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(parseInt(inel.fullamount) - parseInt(inel.amount_payed))}}
												</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none table-orders">
								<thead>
									<tr>
										<th>Инструмент</th>
										<th width="100" class="text-center">Ремонты</th>
										<th width="150" class="text-center">Услуги</th>
										<th width="150" class="text-center">Материалы</th>
										<th width="150" class="text-center">Сумма</th>
										<th width="150" class="text-center">Внесено</th>
										<th width="150" class="text-center">Долг</th>
										<th width="70" class="text-right" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="$device.isTablet">
									<tr @click="$router.push({path: '/orders', query: (inel.orders_count > 0 ? {filter: JSON.stringify([{
										field: 'lai.instrument_id',
										operator: '=',
										value: inel.id
									}])} : {})})" v-for="inel in instrumentsf" :key="inel.id">
										<td style="vertical-align: middle;">{{inel.name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.orders_count)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.amount_services)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.amount_materials)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(parseInt(inel.fullamount) - parseInt(inel.amount_payed))}}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="inel in instrumentsf" :key="inel.id">
										<td style="vertical-align: middle;">{{inel.name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.orders_count)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.amount_services)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.amount_materials)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(parseInt(inel.fullamount) - parseInt(inel.amount_payed))}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/orders', query: (inel.orders_count > 0 ? {filter: JSON.stringify([{
												field: 'lai.instrument_id',
												operator: '=',
												value: inel.id
											}])} : {})}" style="margin-bottom: 0;" class="btn btn-default">
												<i class="fa fa-chevron-right"></i>	
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчёт по инструментам | MasterSMI Учет'});
	},
	data: () => ({
		structParams: [],
		
		ordersStatuses: [],
		instrumentnames: [],
		firms: [],
		casenames: [],
		
		instruments: [],
		
		showCount0: false,
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		loading: true,
		error: '',
	}),
	computed: {
		instrumentsf(){
			if(this.showCount0) return this.instruments;
			else return this.instruments.filter((el) => el.orders_count > 0);
		},
		
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
	},
	methods: {
		async loadOrdersStatuses(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetOrdersStatuses();
			if(ret.success){
				this.ordersStatuses = ret.orders_statuses;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadFirms(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListFirms();
			if(ret.success){
				this.firms = ret.firms;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadCaseNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListCaseNames();
			if(ret.success){
				this.casenames = ret.casenames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				filter: JSON.stringify(this.filter),
			})});
			let params = {};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			let ret = await this.appModel.doGetReportInstruments(params);
			if(ret.success){
				this.instruments = ret.instruments;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		await this.loadOrdersStatuses();
		await this.loadInstrumentNames();
		await this.loadFirms();
		await this.loadCaseNames();
		
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		
		await this.loadData();
		
		this.structParams = [
			{
				type: 'number',
				field: 'lai.client_id',
				label: 'ID клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'select',
				field: 'lai.status_id',
				label: 'Статус ремонта',
				options: this.ordersStatuses.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.instrument_id',
				label: 'Инструмент',
				options: this.instrumentnames.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.instrument_type_id',
				label: 'Тип инструмента',
				filter: false,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.instrument_size_id',
				label: 'Размер инструмента',
				filter: false,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.instrument_firm_id',
				label: 'Фирма инструмента',
				options: this.firms.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.casename_id',
				label: 'Кейс',
				options: this.casenames.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_serial_number',
				label: 'Серийный номер инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_color',
				label: 'Цвет инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_model',
				label: 'Модель инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateadd',
				label: 'Дата добавления (начала)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateend',
				label: 'Дата завершения',
				filter: true,
				sort: true,
			},
		];
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>