<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-10">
			<h2>Отчёт по проданным товарам (за всё время)</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/reports'}">Отчёты</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/reports/sales-goods'}">
						<strong>Отчёт по проданным товарам (за всё время)</strong>
					</router-link>
				</li>
			</ol>
		</div>
		<div class="col-lg-2"></div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по товарам" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" :class="{'collapsed': !filterShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Фильтр <small class="badge m-l-xs">{{filter.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="filterShow = !filterShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="filterShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(fi, index) in filter">
								<label class="col-sm-3 control-label">{{getStructParam(fi.field).label}}</label>
								<div class="col-sm-2" :class="!$device.isMobile ? {paddingRight: 0} : {}">
									<select class="form-control" v-model="fi.operator">
										<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
									</select>
								</div>
								<div class="col-sm-6" :class="!$device.isMobile ? {paddingLeft: 0} : {}">
									<input type="text" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
									<input type="number" class="form-control" :class="{'m-t': $device.isMobile}" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
									<input type="date" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
									<input type="datetime-local" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
									<input type="email" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
									<select class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
										<option value=""></option>
										<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Фильтр по</label>
								<div class="col-sm-8">
									<select class="form-control" v-model="filterAddField" @change="onAddFilterParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<button type="button" class="btn btn-info" :class="{'btn-block m-t': $device.isMobile}" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
										<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Показывать не проданные товары</label>
								<div class="col-sm-9">
									<div class="btn-group">
										<button class="btn" :class="{'btn-primary': showCount0, 'btn-white': !showCount0}" type="button" @click="showCount0 = true">Да</button>
										<button class="btn" :class="{'btn-primary': !showCount0, 'btn-white': showCount0}" type="button" @click="showCount0 = false">Нет</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content" :style="loading ? {background: '#eee'} : {}">
						<table class="table" style="margin-bottom: 0;">
							<thead>
								<tr>
									<th width="70">&nbsp;</th>
									<th>Товар</th>
									<th width="100" class="text-center">Продаж</th>
									<th width="100" class="text-center">Продано</th>
									<th width="150" class="text-center">Сумма</th>
									<th style="min-width: 100px;" width="100" class="text-right">Операции</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="inel in goodsf" :key="inel.id">
									<td style="vertical-align: middle;">
										<lightgallery
											:settings="lightgallerySettings"
											:onInit="(detail) => inel.lightGallery = detail.instance"
										>
											<a :href="getPoster(inel.images).url">
												<img :src="getPoster(inel.images).urlSmall" style="width: 50px; height: 50px;" />
											</a>
											<a :href="image.url" v-for="image in getImages(inel.images)" :key="image.pkid" style="display: none;">
												<img :src="image.urlSmall" />
											</a>
										</lightgallery>
									</td>
									<td style="vertical-align: middle;">{{inel.name}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(inel.sales_count)}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(inel.count)}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(inel.fullamount)}}</td>
									<td style="vertical-align: middle;" class="text-right">
										<router-link :to="{path: '/sales', query: (inel.count > 0 ? {filter: JSON.stringify(inel.sales_id.map((el) => ({
											field: 'ls.id',
											operator: '=',
											value: el
										})))} : {})}" style="margin-bottom: 0;" class="btn btn-default" v-if="inel.sales_id.length > 0">
											<i class="fa fa-chevron-right"></i>
										</router-link>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Товаров:</b> {{number_format(goodsCount)}}, <b>Итого:</b> {{number_format(goodsSum)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчёт по проданным товарам (за всё время) | MasterSMI Учет'});
	},
	data: () => ({
		structParams: [],
		
		showCount0: false,
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
		},
		
		goods: [],
		goodsCount: 0,
		goodsSum: 0,
		
		search: '',
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	watch: {
		'goods.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					for(let goodsitem of this.goods){
						if(goodsitem.lightGallery){
							goodsitem.lightGallery.refresh();
						}
					}
				});
			},
			deep: true,
		},
	},
	computed: {
		goodsf(){
			if(this.showCount0) return this.goods;
			else return this.goods.filter((el) => el.count > 0);
		},
		
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		getPoster(images){
			return images.find((el) => el.poster);
		},
		getImages(images){
			return images.filter((el) => !el.poster);
		},
		
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				search: this.search,
				filter: JSON.stringify(this.filter),
				page: this.page,
			})});
			let params = {
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			let ret = await this.appModel.doGetReportSalesGoods(params);
			if(ret.success){
				this.goods = ret.goods.map((el) => {
					if(el.images.length <= 0) el.images.push({
						id: null,
						pkid: null,
						poster: 1,
						url: '/assets/img/no-image.png',
						urlSmall: '/assets/img/no-image.png',
						descr: '',
						ugmtime_add: this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
					});
					el.lightGallery = (this.goods.find((el2) => el2.id == el.id) || {}).lightGallery || null;
					return el;
				});
				this.goodsCount = ret.goods_count;
				this.goodsSum = ret.goods_sum;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.goodsCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		this.structParams = [
		{
				type: 'date',
				field: 'ls.dateadd',
				label: 'Дата добавления (начала)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'ls.dateend',
				label: 'Дата завершения',
				filter: true,
				sort: true,
			},
		];
		
		if(this.$route.query.search) this.search = this.$route.query.search;
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
		Lightgallery,
	},
};
</script>