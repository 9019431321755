<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-md-12">
			<h2>База производимых работ</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/lists'}">Справочники</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/lists/services'}">
						<strong>База производимых работ</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-3 col-md-push-9">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<button type="button" style="white-space: normal;" class="btn btn-block btn-info" :disabled="instrumentnames.length <= 0" @click="onShowAddModal">Добавить производимую работу</button>
					</div>
				</div>
			</div>
			<div class="col-md-9 col-md-pull-3">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по названию производимых работ" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<select class="form-control" v-model="instrument_id" @change="page = 1; loadData();">
							<option :value="instrumentname.id" v-for="instrumentname in instrumentnames" :key="instrumentname.id">{{instrumentname.name}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content scroll-x" :style="loading ? {background: '#eee'} : {}">
						<table class="table" style="margin-bottom: 0;">
							<thead>
								<tr>
									<th>Наименование производимой работы</th>
									<th width="15%" class="text-center">Инструмент</th>
									<th width="15%" class="text-center">Стоимость</th>
									<th width="10%" class="text-center">Ремонтов</th>
									<th style="min-width: 100px;" width="100" class="text-right">Операции</th>
								</tr>
							</thead>
							<tbody style="font-size: 16px;">
								<tr v-for="service in services" :key="service.id">
									<td style="vertical-align: middle;">{{service.name}}</td>
									<td style="vertical-align: middle;" class="text-center">{{service.instrument_name}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(service.price)}}</td>
									<td style="vertical-align: middle;" class="text-center">{{number_format(service.orders_count)}}</td>
									<td style="vertical-align: middle;" class="text-right">
										<button type="button" class="btn btn-info m-r-xs" @click="onShowEditModal(service.id)">
											<i class="fa fa-pencil"></i>
										</button>
										<button type="button" class="btn btn-danger" @click="onShowDeleteModal(service.id)">
											<i class="fa fa-trash-o"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Услуг:</b> {{number_format(servicesCount)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import ServiceAddModal from '@/components/modals/ListServices/ServiceAddModal.vue';
import ServiceEditModal from '@/components/modals/ListServices/ServiceEditModal.vue';
import ServiceDeleteModal from '@/components/modals/ListServices/ServiceDeleteModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'База производимых работ | MasterSMI Учет'});
	},
	data: () => ({
		instrument_id: '',
		
		instrumentnames: [],
		services: [],
		servicesCount: 0,
		
		search: '',
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		onShowAddModal(){
			let that = this;
			this.$vfm.show({
				component: ServiceAddModal,
				on: {
					add(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					instrument_id: that.instrument_id,
					instrumentnames: that.instrumentnames,
				},
			});
		},
		onShowEditModal(id){
			let that = this;
			this.$vfm.show({
				component: ServiceEditModal,
				on: {
					edit(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					instrumentnames: that.instrumentnames,
					service: that.services.find((el) => el.id == id),
				},
			});
		},
		onShowDeleteModal(id){
			let that = this;
			this.$vfm.show({
				component: ServiceDeleteModal,
				on: {
					delete(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					service: that.services.find((el) => el.id == id),
				},
			});
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				search: this.search,
				instrument_id: this.instrument_id,
			})});
			let ret = await this.appModel.doGetListServices({
				instrument_id: this.instrument_id,
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			});
			if(ret.success){
				this.services = ret.services;
				this.servicesCount = ret.services_count;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
				this.instrumentnames.unshift({
					id: '',
					name: 'Все',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.servicesCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		await this.loadInstrumentNames();
		
		if(this.$route.query.search) this.search = this.$route.query.search;
		if(this.$route.query.instrument_id) this.instrument_id = this.$route.query.instrument_id;
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>