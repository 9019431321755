<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-danger size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Удаление элемента истории статусов</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="alert alert-info">
							<div class="row text-center elem-info">
								<div class="col-md-2">{{formatDate(dateadd, 'DD.MM.YYYY HH:mm')}}</div>
								<div class="col-md-4">{{statusName}}</div>
								<div class="col-md-6">{{description}}</div>
							</div>
						</div>
						<div>Вы действительно хотите безвозвратно удалить данный элемент истории статусов продажи?</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-danger" :disabled="loading">Удалить</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>
.elem-info {
	display: flex;
	align-items: center;
}
</style>

<script>
import lib from '@/lib';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		statusName: '',
		description: '',
		dateadd: null,
		
		error: '',
		loading: false,
	}),
	methods: {
		async onDelete(close){
			this.$emit('delete', close);
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	mounted(){
		this.statusName = this.$vfm.modals[0].params._value.statusName;
		this.description = this.$vfm.modals[0].params._value.description;
		this.dateadd = this.$vfm.modals[0].params._value.dateadd;
	},
}
</script>