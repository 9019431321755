//import axios from 'axios';

import AuthModel from '@/models/AuthModel.js';

export default class AppModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.title = '';
		//this.project_url = 'https://mastersmirsc.bsrv.su';
		this.project_url = 'https://uchet.remuzin.kz';
		
		this.auth = new AuthModel();
	}
	
	/**
	 * Set page title
	 */
	setPageTitle(title)
	{
		this.title = title;
		document.title = title;
	}
	
	pageReload()
	{
		document.location.reload();
	}
	
	/**
	 * Получение данных для страницы статистики
	 */
	async doGetStatisticsData()
	{
		/*return await fetch('/api/statistics/get-2fa?' + new URLSearchParams({
			lang: storeInstance.state.app.getLang(),
		}), {*/
		
		return await fetch('/api/data/get-statistics', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы услуг
	 */
	async doGetListServices(params)
	{
		return await fetch('/api/data/lists/services?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление услуги
	 */
	async doAddService(params)
	{
		return await fetch('/api/data/lists/services', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных об услуге по ее id
	 */
	async doGetService(service_id)
	{
		return await fetch('/api/data/lists/services/' + service_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных об услуге по ее id
	 */
	async doEditService(service_id, params)
	{
		return await fetch('/api/data/lists/services/' + service_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление услуги по ее id
	 */
	async doDeleteService(service_id)
	{
		return await fetch('/api/data/lists/services/' + service_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление услуги по ее id
	 */
	async doReplaceAndDeleteService(service_id, replace_service_id)
	{
		return await fetch('/api/data/lists/services/' + service_id + '/' + replace_service_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы инструментов
	 */
	async doGetListInstrumentNames(params)
	{
		return await fetch('/api/data/lists/instrument-names?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление инструмента
	 */
	async doAddInstrumentName(params)
	{
		return await fetch('/api/data/lists/instrument-names', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о инструменте по его id
	 */
	async doGetInstrumentName(instrument_id)
	{
		return await fetch('/api/data/lists/instrument-names/' + instrument_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о инструменте по его id
	 */
	async doEditInstrumentName(instrument_id, params)
	{
		return await fetch('/api/data/lists/instrument-names/' + instrument_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление инструмента по его id
	 */
	async doDeleteInstrumentName(instrument_id)
	{
		return await fetch('/api/data/lists/instrument-names/' + instrument_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы типов инструментов
	 */
	async doGetListInstrumentTypes(params)
	{
		return await fetch('/api/data/lists/instrument-types?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление типа инструмента
	 */
	async doAddInstrumentType(params)
	{
		return await fetch('/api/data/lists/instrument-types', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о типе инструмента по его id
	 */
	async doGetInstrumentType(instrument_type_id)
	{
		return await fetch('/api/data/lists/instrument-types/' + instrument_type_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о типе инструмента по его id
	 */
	async doEditInstrumentType(instrument_type_id, params)
	{
		return await fetch('/api/data/lists/instrument-types/' + instrument_type_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление типа инструмента по его id
	 */
	async doDeleteInstrumentType(instrument_type_id)
	{
		return await fetch('/api/data/lists/instrument-types/' + instrument_type_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы размеров инструментов
	 */
	async doGetListInstrumentSizes(params)
	{
		return await fetch('/api/data/lists/instrument-sizes?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление размера инструмента
	 */
	async doAddInstrumentSize(params)
	{
		return await fetch('/api/data/lists/instrument-sizes', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о размере инструмента по его id
	 */
	async doGetInstrumentSize(instrument_size_id)
	{
		return await fetch('/api/data/lists/instrument-sizes/' + instrument_size_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о размерt инструмента по его id
	 */
	async doEditInstrumentSize(instrument_size_id, params)
	{
		return await fetch('/api/data/lists/instrument-sizes/' + instrument_size_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление размера инструмента по его id
	 */
	async doDeleteInstrumentSize(instrument_size_id)
	{
		return await fetch('/api/data/lists/instrument-sizes/' + instrument_size_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы кейсов
	 */
	async doGetListCaseNames(params)
	{
		return await fetch('/api/data/lists/case-names?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление кейса
	 */
	async doAddCaseName(params)
	{
		return await fetch('/api/data/lists/case-names', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о кейсе по его id
	 */
	async doGetCaseName(casename_id)
	{
		return await fetch('/api/data/lists/case-names/' + casename_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о кейсе по его id
	 */
	async doEditCaseName(casename_id, params)
	{
		return await fetch('/api/data/lists/case-names/' + casename_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление кейса по его id
	 */
	async doDeleteCaseName(casename_id)
	{
		return await fetch('/api/data/lists/case-names/' + casename_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы фирм-производителей
	 */
	async doGetListFirms(params)
	{
		return await fetch('/api/data/lists/firms?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление фирмы-производителя
	 */
	async doAddFirm(params)
	{
		return await fetch('/api/data/lists/firms', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о фирме-производителе по ее id
	 */
	async doGetFirm(firm_id)
	{
		return await fetch('/api/data/lists/firms/' + firm_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о фирме-производителе по ее id
	 */
	async doEditFirm(firm_id, params)
	{
		return await fetch('/api/data/lists/firms/' + firm_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление фирмы-производителя по ее id
	 */
	async doDeleteFirm(firm_id)
	{
		return await fetch('/api/data/lists/firms/' + firm_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы "откуда узнали о мастере"
	 */
	async doGetListWhereTheyLearns(params)
	{
		return await fetch('/api/data/lists/where-they-learns?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление записи "откуда узнали о мастере"
	 */
	async doAddWhereTheyLearn(params)
	{
		return await fetch('/api/data/lists/where-they-learns', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о записи "откуда узнали о мастере" по ее id
	 */
	async doGetWhereTheyLearn(wheretheylearn_id)
	{
		return await fetch('/api/data/lists/where-they-learns/' + wheretheylearn_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о записи "откуда узнали о мастере" по v id
	 */
	async doEditWhereTheyLearn(wheretheylearn_id, params)
	{
		return await fetch('/api/data/lists/where-they-learns/' + wheretheylearn_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление записи "откуда узнали о мастере" по ее id
	 */
	async doDeleteWhereTheyLearn(wheretheylearn_id)
	{
		return await fetch('/api/data/lists/where-they-learns/' + wheretheylearn_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы материалов и комплектующих
	 */
	async doGetListMaterials(params)
	{
		return await fetch('/api/data/lists/materials?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление материала
	 */
	async doAddMaterial(params)
	{
		return await fetch('/api/data/lists/materials', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о материале по его id
	 */
	async doGetMaterial(material_id)
	{
		return await fetch('/api/data/lists/materials/' + material_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о материале по его id
	 */
	async doEditMaterial(material_id, params)
	{
		return await fetch('/api/data/lists/materials/' + material_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление материала по его id
	 */
	async doDeleteMaterial(material_id)
	{
		return await fetch('/api/data/lists/materials/' + material_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы товаров
	 */
	async doGetListGoods(params)
	{
		return await fetch('/api/data/lists/goods?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы архива товаров
	 */
	async doGetListGoodsArchive(params)
	{
		return await fetch('/api/data/lists/goods-archive?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление товара
	 */
	async doAddGoodsItem(params)
	{
		return await fetch('/api/data/lists/goods', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о товаре по его id
	 */
	async doGetGoodsItem(goodsitem_id)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о товаре по его id
	 */
	async doEditGoodsItem(goodsitem_id, params)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Перемещение товара в архив
	 */
	async doMoveToArchiveGoodsItem(goodsitem_id)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id + '/to-archive', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			//body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Извлечение товара из архива
	 */
	async doMoveFromArchiveGoodsItem(goodsitem_id)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id + '/from-archive', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			//body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление товара по его id
	 */
	async doDeleteGoodsItem(goodsitem_id)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение изображений товара
	 */
	async doGetImagesGoodsitem(goodsitem_id)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id + '/images', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Установка изображения товара в качестве постера
	 */
	async doSetPosterImageGoodsitem(goodsitem_id, image_pkid)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id + '/image-set-poster/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			//body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление изображения товара
	 */
	async doDeleteImageGoodsitem(goodsitem_id, image_pkid)
	{
		return await fetch('/api/data/lists/goods/' + goodsitem_id + '/images/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы клиентов
	 */
	async doGetListClients(params)
	{
		return await fetch('/api/data/lists/clients?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о клиенте, а также его ремонтов и приобретенных товаров, по его id
	 */
	async doGetClientData(client_id)
	{
		return await fetch('/api/data/lists/clients/' + client_id + '/data', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление клиента
	 */
	async doAddClient(params)
	{
		return await fetch('/api/data/lists/clients', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о клиенте по его id
	 */
	async doGetClient(client_id)
	{
		return await fetch('/api/data/lists/clients/' + client_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о клиенте по его id
	 */
	async doEditClient(client_id, params)
	{
		return await fetch('/api/data/lists/clients/' + client_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление клиента по его id
	 */
	async doDeleteClient(client_id)
	{
		return await fetch('/api/data/lists/clients/' + client_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление клиента по его id вместе с его ремонтами
	 */
	async doDeleteClientWithOrders(client_id)
	{
		return await fetch('/api/data/lists/clients/' + client_id + '/orders', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление клиента по его id вместе с его продажами
	 */
	async doDeleteClientWithSales(client_id)
	{
		return await fetch('/api/data/lists/clients/' + client_id + '/sales', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление клиента по его id вместе с его ремонтами и продажами
	 */
	async doDeleteClientWithOrdersAndSales(client_id)
	{
		return await fetch('/api/data/lists/clients/' + client_id + '/orders-sales', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы ремонтов
	 */
	async doGetListOrders(params)
	{
		return await fetch('/api/data/lists/orders?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение статусов ремонтов
	 */
	async doGetOrdersStatuses()
	{
		return await fetch('/api/data/lists/orders-statuses', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о ремонте (дополненный)
	 */
	async doGetOrderData(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/data', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о ремонте
	 */
	async doGetOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление ремонта
	 */
	async doAddOrder(params)
	{
		return await fetch('/api/data/lists/orders', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о ремонте по его id
	 */
	async doEditOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление ремонта по его id
	 */
	async doDeleteOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение изображений ремонта
	 */
	async doGetImagesOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/images', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение типа изображения ремонта
	 */
	async doEditTypeImageOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/image-edit-type', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление изображения ремонта
	 */
	async doDeleteImageOrder(order_id, image_pkid)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/images/' + image_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение файлов ремонта
	 */
	async doGetFilesOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/files', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление файла ремонта
	 */
	async doDeleteFileOrder(order_id, file_pkid)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/files/' + file_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение производимых работ ремонта
	 */
	async doGetServicesOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/services', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление производимой работы в ремонт
	 */
	async doAddServiceOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/services', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение производимой работы ремонта
	 */
	async doEditServiceOrder(order_id, order_service_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/services/' + order_service_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление производимой работы из ремонта
	 */
	async doDeleteServiceOrder(order_id, order_service_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/services/' + order_service_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение требуемых материалов ремонта
	 */
	async doGetMaterialsOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/materials', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление требуемого материала в ремонт
	 */
	async doAddMaterialOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/materials', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение требуемого материала ремонта
	 */
	async doEditMaterialOrder(order_id, order_material_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/materials/' + order_material_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление требуемого материала из ремонта
	 */
	async doDeleteMaterialOrder(order_id, order_material_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/materials/' + order_material_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление предоплаты в ремонт
	 */
	async doAddPrepaymentOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/prepayment', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление оплаты в ремонт
	 */
	async doAddPaymentOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/payment', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение истории платежей ремонта
	 */
	async doGetHistoryPaymentsOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-payments', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение элемента истории платежей ремонта
	 */
	async doEditHistoryPaymentOrder(order_id, order_historypayment_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-payments/' + order_historypayment_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление элемента истории платежей ремонта
	 */
	async doDeleteHistoryPaymentOrder(order_id, order_historypayment_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-payments/' + order_historypayment_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение истории статусов ремонта
	 */
	async doGetHistoryStatusesOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-statuses', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение элемента истории статусов ремонта
	 */
	async doEditHistoryStatusOrder(order_id, order_historystatus_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-statuses/' + order_historystatus_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление элемента истории статусов ремонта
	 */
	async doDeleteHistoryStatusOrder(order_id, order_historystatus_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-statuses/' + order_historystatus_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение истории посещений ремонта
	 */
	async doGetHistoryVisitsOrder(order_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-visits', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление элемента истории посещений ремонта
	 */
	async doDeleteHistoryVisitOrder(order_id, order_historyvisit_id)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/history-visits/' + order_historyvisit_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение статуса ремонта по его id
	 */
	async doChangeStatusOrder(order_id, params)
	{
		return await fetch('/api/data/lists/orders/' + order_id + '/change-status', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение базы продаж товаров
	 */
	async doGetListSales(params)
	{
		return await fetch('/api/data/lists/sales?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение статусов продаж товаров
	 */
	async doGetSalesStatuses(params)
	{
		return await fetch('/api/data/lists/sales-statuses', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о продаже товаров (дополненный)
	 */
	async doGetSaleData(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/data', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о продаже товаров
	 */
	async doGetSale(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление продажи
	 */
	async doAddSale(params)
	{
		return await fetch('/api/data/lists/sales', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о продаже по ее id
	 */
	async doEditSale(sale_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление продажи по ее id
	 */
	async doDeleteSale(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение файлов продажи
	 */
	async doGetFilesSale(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/files', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление файла продажи
	 */
	async doDeleteFileSale(sale_id, file_pkid)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/files/' + file_pkid, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение товаров продажи
	 */
	async doGetGoodsSale(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/goods', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление товара в продажу
	 */
	async doAddGoodsItemSale(sale_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/goods', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение товара продажи
	 */
	async doEditGoodsItemSale(sale_id, sale_goodsitem_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/goods/' + sale_goodsitem_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление товара из продажи
	 */
	async doDeleteGoodsItemSale(sale_id, sale_goodsitem_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/goods/' + sale_goodsitem_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление предоплаты в продажу
	 */
	async doAddPrepaymentSale(sale_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/prepayment', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление оплаты в продажу
	 */
	async doAddPaymentSale(sale_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/payment', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение истории платежей продажи
	 */
	async doGetHistoryPaymentsSale(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/history-payments', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение элемента истории платежей продажи
	 */
	async doEditHistoryPaymentSale(sale_id, sale_historypayment_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/history-payments/' + sale_historypayment_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление элемента истории платежей продажи
	 */
	async doDeleteHistoryPaymentSale(sale_id, sale_historypayment_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/history-payments/' + sale_historypayment_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение истории статусов продажи
	 */
	async doGetHistoryStatusesSale(sale_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/history-statuses', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение элемента истории статусов продажи
	 */
	async doEditHistoryStatusSale(sale_id, sale_historystatus_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/history-statuses/' + sale_historystatus_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление элемента истории статусов продажи
	 */
	async doDeleteHistoryStatusSale(sale_id, sale_historystatus_id)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/history-statuses/' + sale_historystatus_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение статуса продажи по ее id
	 */
	async doChangeStatusSale(sale_id, params)
	{
		return await fetch('/api/data/lists/sales/' + sale_id + '/change-status', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение отчета по оказанным услугам
	 */
	async doGetReportServices(params)
	{
		return await fetch('/api/data/reports/services?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение отчета по использованным материалам
	 */
	async doGetReportMaterials(params)
	{
		return await fetch('/api/data/reports/materials?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение отчета по проданным товарам
	 */
	async doGetReportSalesGoods(params)
	{
		return await fetch('/api/data/reports/sales-goods?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение финансового отчета по ремонтам
	 */
	async doGetReportFinancialOrders(params)
	{
		return await fetch('/api/data/reports/financial-orders?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение финансового отчета по продажам
	 */
	async doGetReportFinancialSales(params)
	{
		return await fetch('/api/data/reports/financial-sales?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение отчета по инструментам
	 */
	async doGetReportInstruments(params)
	{
		return await fetch('/api/data/reports/instruments?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение отчета по ремонтам
	 */
	async doGetReportOrders(params)
	{
		return await fetch('/api/data/reports/orders?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение отчета по продажам
	 */
	async doGetReportSales(params)
	{
		return await fetch('/api/data/reports/sales?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных приложения к акту приемки инструмента в ремонт
	 */
	async doGetOrderReportActPriemPril(order_id)
	{
		return await fetch('/api/data/report-actpriempril/' + order_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Проверка на существование ремонта по его номеру
	 */
	async doCheckOrderExists(order_id)
	{
		return await fetch('/api/data/orderinfo/check-exists/' + order_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных для страницы статистики
	 */
	async doGetOrderOutData(order_id)
	{
		return await fetch('/api/data/orderinfo/' + order_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
}
