<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-sm" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Внесение оплаты за ремонт</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>К оплате</label>
									<input type="text" ref="debtcost" class="form-control text-center" :value="number_format(debtcost)" readonly />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Получено <span class="text-danger">*</span></label>
									<input type="number" ref="inputcost" class="form-control text-center" v-model.number="inputcost" @wheel="$event.target.blur()" required />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Сдача</label>
									<input type="text" ref="surrcost" class="form-control text-center" :value="number_format(surrcost)" readonly />
								</div>
							</div>
						</div>
						<div class="hr-line-dashed"></div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Описание платежа</label>
									<textarea ref="description" rows="2" placeholder="Например: Предоплата" class="form-control text-center" style="resize: none;" v-model="description"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Дата и время <span class="text-danger">*</span></label>
									<input type="datetime-local" ref="dateadd" class="form-control text-center" v-model="dateadd" required />
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Внести оплату</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import autosize from 'autosize';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		debtcost: 0,
		inputcost: null,
		description: '',
		dateadd: '',
		
		error: '',
		loading: false,
	}),
	computed: {
		surrcost(){
			let ret = this.debtcost - this.inputcost;
			if(ret > 0) ret = 0;
			else ret = Math.abs(ret);
			return ret;
		},
	},
	watch: {},
	methods: {
		async onAdd(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doAddPaymentOrder(this.$vfm.modals[0].params._value.order_id, {
				value: this.inputcost,
				description: this.description,
				dateadd: moment.tz(this.dateadd, 'Asia/Almaty').utc().format('YYYY-MM-DDTHH:mm'),
			});
			if(ret.success){
				this.$emit('add', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	mounted(){
		this.debtcost = this.$vfm.modals[0].params._value.debtcost;
		this.dateadd = this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm');
		
		autosize(this.$refs.description);
		
		setTimeout(() => {
			this.$refs.inputcost.focus();
		}, 300);
	},
	components: {},
}
</script>