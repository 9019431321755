<template>
	<div id="wrapper">
		<div id="page-wrapper" class="gray-bg">
			<div class="row border-bottom white-bg">
				<nav class="navbar navbar-static-top" role="navigation">
					<div class="navbar-header">
						<div class="navbar-brand">MasterSMI Учет</div>
					</div>
				</nav>
			</div>
			<div class="wrapper wrapper-content" :style="{padding: !$device.isMobile ? 'auto' : '20px 10px 40px'}">
				<div :class="{'container': !$device.isMobile}" v-if="tpl == 'loading'">
					<div style="text-align: center;">
						<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
						<h1>ЗАГРУЗКА ДАННЫХ...</h1>
					</div>
				</div>
				<div :class="{'container': !$device.isMobile}" v-if="tpl == 'notexists'">
					<div style="color: #f00; text-align: center;">
						<h1>РЕМОНТ НЕ НАЙДЕН</h1>
						<div class="m-t-lg">
							<router-link :to="{path: '/orderinfo'}" class="btn btn-primary waves-effect waves-light">Назад</router-link>
						</div>
					</div>
				</div>
				<div :class="{'container': !$device.isMobile}" v-if="tpl == 'info'">
					<div class="ibox float-e-margins">
						<div class="ibox-content">
							<div class="text-right" style="color: #ccc; font-size: 8pt;">Учет Remuzin.kz</div>
							<div class="text-center" style="font-size: 14pt;">
								<h3>ПРИЛОЖЕНИЕ К АКТУ ПРИЕМКИ ИНСТРУМЕНТА В РЕМОНТ</h3>
							</div>
							<div class="text-center" style="font-size: 11pt;">
								<div>
									<b>Акт № {{orderinfo.id}} от {{formatDate(orderinfo.dateadd, 'DD.MM.YYYY')}}</b>
								</div>
								<div>(фотографии проблемных мест принятого инструмента)</div>
							</div>
							<div class="text-center" style="font-size: 11pt;">
								{{appModel.project_url}}/orderinfo/{{orderinfo.id}}
							</div>
							<div class="row m-t">
								<div class="col-xs-6 m-b text-center" v-for="image in images">
									<img style="height: 275px;" :src="image.urlSmall" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
body{
	margin: 0px;
	padding: 0px;
	font-family: "Calibri";
	font-size: 11pt;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Учет Remuzin.kz: Приложение к акту приемки инструмента в ремонт'});
	},
	data: () => ({
		tpl: 'loading',
		
		orderinfo: {},
		images: [],
		
		error: '',
	}),
	computed: {},
	methods: {
		async loadData(){
			this.error = '';
			let ret = await this.appModel.doGetOrderReportActPriemPril(this.$route.params.id);
			if(ret.success){
				if(ret.exists){
					this.orderinfo = ret.orderinfo;
					this.images = ret.images;
					
					this.tpl = 'info';
				} else {
					this.tpl = 'notexists';
				}
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadData();
		
		if(this.tpl == 'info'){
			setTimeout(() => {
				
			}, 1000);
		}
	},
	beforeMount(){
		window.scrollTo(0, 0);
		document.body.classList.add('top-navigation');
	},
	beforeUnmount(){
		document.body.classList.remove('top-navigation');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('top-navigation');
	},
	components: {},
};
</script>