<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-12">
			<h2>Информация о продаже № {{$route.params.id}}</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/sales'}">База продаж товаров</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/sales/' + $route.params.id}">
						<strong>Информация о продаже</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row" v-if="tpl == 'loading'">
			<div class="col-md-12" style="text-align: center;">
				<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
				<h1>ЗАГРУЗКА ДАННЫХ...</h1>
			</div>
		</div>
		<div class="row" v-if="tpl == 'notexists'">
			<div class="col-md-12" style="color: #f00; text-align: center;">
				<h1>ПРОДАЖА НЕ НАЙДЕНА</h1>
				<div class="m-t-lg">
					<router-link :to="{path: '/sales'}" class="btn btn-primary waves-effect waves-light">Назад</router-link>
				</div>
			</div>
		</div>
		<div v-if="tpl == 'info'">
			<div class="row">
				<div class="col-md-12">
					<div class="tabs-container">
						<ul class="nav nav-tabs">
							<li :class="{'active': tab1 == '1'}">
								<a @click="tab1 = '1'">Данные клиента</a>
							</li>
							<li :class="{'active': tab1 == '2'}">
								<a @click="tab1 = '2'">Подробности</a>
							</li>
							<li :class="{'active': tab1 == '3'}">
								<a @click="tab1 = '3'">Товары</a>
							</li>
							<li :class="{'active': tab1 == '4'}">
								<a @click="tab1 = '4'">Файлы</a>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{'active': tab1 == '1'}" v-show="tab1 == '1'">
								<div class="panel-body" style="padding-bottom: 5px;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>Фамилия, Имя и Отчество клиента <span class="text-danger">*</span></label>
												<vue-bootstrap-typeahead
													inputClass="text-center"
													placeholder="ФИО клиента"
													v-model="clientinfo.fio"
													:data="clients"
													:serializer="s => s.fio"
													@hit="onSelectClient($event)"
													@input="getClients($event.target.value)"
												>
													<template v-slot:prepend>
														<span class="input-group-btn">
															<button type="button" class="btn btn-white" :disabled="clientinfo.id == null" @click="onClearClient()" content="Отменить выбор клиента" v-tippy="{placement: 'top'}">
																<i class="fa fa-times"></i>
															</button>
														</span>
													</template>
													<template v-slot:append>
														<span class="input-group-btn" v-if="clientinfo.id">
															<router-link :to="{path: '/clients/' + clientinfo.id}" class="btn btn-white" content="Перейти к клиенту" v-tippy="{placement: 'top'}">
																<i class="fa fa-chevron-right"></i>
															</router-link>
														</span>
													</template>
												</vue-bootstrap-typeahead>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-5">
											<div class="form-group">
												<label>Телефоны</label>
												<div class="input-group">
													<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="clientinfo.tel[0].value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onTelAdd()">
															<i class="fa fa-plus"></i>
														</button>
													</span>
												</div>
											</div>
											<div class="form-group" v-for="(tel, index) in clientinfo.tel.slice(1)">
												<div class="input-group">
													<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="tel.value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onTelDel(index + 1)">
															<i class="fa fa-minus"></i>
														</button>
													</span>
												</div>
											</div>
										</div>
										<div class="col-md-7">
											<div class="form-group">
												<label>Мобильные телефоны</label>
												<div class="input-group">
													<span class="input-group-btn">
														<a :href="'tel:'+clientinfo.mobtel[0].value.replace(/\s+/g, '')" class="btn btn-white" content="Позвонить" v-tippy="{placement: 'top'}">
															<i class="fa fa-phone"></i>
														</a>
													</span>
													<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="clientinfo.mobtel[0].value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onMobtelAdd()">
															<i class="fa fa-plus"></i>
														</button>
													</span>
												</div>
											</div>
											<div class="form-group" v-for="(mobt, index) in clientinfo.mobtel.slice(1)">
												<div class="input-group">
													<span class="input-group-btn">
														<a :href="'tel:'+mobt.value.replace(/\s+/g, '')" class="btn btn-white" content="Позвонить" v-tippy="{placement: 'top'}">
															<i class="fa fa-phone"></i>
														</a>
													</span>
													<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="mobt.value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onMobtelDel(index + 1)">
															<i class="fa fa-minus"></i>
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label>Город</label>
												<input type="text" placeholder="Город" class="form-control text-center" v-model="clientinfo.city" />
											</div>
										</div>
										<div class="col-md-8">
											<div class="form-group">
												<label>Адрес клиента</label>
												<input type="text" placeholder="Адрес клиента" class="form-control text-center" v-model="clientinfo.address" />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-5">
											<div class="form-group">
												<label>Email клиента</label>
												<input type="text" placeholder="Email клиента" class="form-control text-center" v-model="clientinfo.email" />
											</div>
										</div>
										<div class="col-md-7">
											<div class="form-group">
												<label>Статус клиента</label>
												<div class="input-group">
													<input :type="clientStatusShow ? 'text' : 'password'" autocomplete="new-password" placeholder="Статус клиента" class="form-control text-center text-danger" v-model="clientinfo.status" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="clientStatusShow = !clientStatusShow">
															<i class="fa fa-eye" :class="{'fa-eye': !clientStatusShow, 'fa-eye-slash': clientStatusShow}"></i>
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-8">
											<div class="form-group">
												<label>Заметка к клиенту</label>
												<textarea ref="client_comment" placeholder="Заметка к клиенту" class="form-control" style="resize: none;" v-model="clientinfo.comment"></textarea>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label>Откуда узнали о мастере</label>
												<select class="form-control" v-model="clientinfo.wheretheylearn_id">
													<option :value="wheretheylearn.id" v-for="wheretheylearn in wheretheylearns" :key="wheretheylearn.id">{{wheretheylearn.name}}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '2'}" v-show="tab1 == '2'">
								<div class="panel-body">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>Комментарий</label>
												<textarea ref="sale_comment" class="form-control" placeholder="Комментарий" style="resize: none;" rows="5" v-model="saleinfo.comment"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '3'}" v-show="tab1 == '3'">
								<div class="panel-body">
									<div class="row">
										<div class="col-md-12">
											<button type="button" style="white-space: normal;" class="btn btn-info pull-right m-b" @click="onShowAddGoodsitemModal">Добавить товар</button>
										</div>
									</div>
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th width="70">&nbsp;</th>
													<th>Товар</th>
													<th width="100" class="text-center">Кол-во</th>
													<th width="150" class="text-center">Цена</th>
													<th width="150" class="text-center">Сумма</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="goodsitem in saleinfo.goods" :key="goodsitem.id">
													<td style="vertical-align: middle;">
														<lightgallery
															:settings="lightgallerySettings"
															:onInit="(detail) => goodsitem.lightGallery = detail.instance"
														>
															<a :href="getPoster(goodsitem.images).url">
																<img :src="getPoster(goodsitem.images).urlSmall" style="width: 50px; height: 50px;" />
															</a>
															<a :href="image.url" v-for="image in getImages(goodsitem.images)" :key="image.pkid" style="display: none;">
																<img :src="image.urlSmall" />
															</a>
														</lightgallery>
													</td>
													<td style="vertical-align: middle;">{{goodsitem.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{goodsitem.count}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(goodsitem.price)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(parseInt(goodsitem.price) * parseInt(goodsitem.count))}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditGoodsitemModal(goodsitem.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteGoodsitemModal(goodsitem.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '4'}" v-show="tab1 == '4'">
								<div class="panel-body">
									<div class="row">
										<div class="col-lg-12 text-center">
											<div class="file-box" v-for="file in saleinfo.files" :key="file.id">
												<div class="file">
													<div class="file-pre image" @click="onShowSaleFIlePreviewModal(file.pkid)" v-if="contains(file.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)">
														<img alt="image" class="img-responsive" :src="file.url" />
													</div>
													<div class="file-pre icon" @click="onShowSaleFIlePreviewModal(file.pkid)" v-else-if="contains(file.extension, ['mp3', 'amr', 'wav', 'ogg', 'm4a'], true)">
														<i class="fa fa-music"></i>
													</div>
													<div class="file-pre icon" @click="onShowSaleFIlePreviewModal(file.pkid)" v-else-if="contains(file.extension, ['mp4', 'avi', 'wmv', 'mov', 'mkv', 'mpg', 'mpeg', 'mpeg4', '3gp', 'webm', 'ogv', 'm4v'], true)">
														<i class="fa fa-film"></i>
													</div>
													<div class="file-pre icon" @click="onShowSaleFIlePreviewModal(file.pkid)" v-else>
														<i class="fa fa-file"></i>
													</div>
													<div class="file-name">
														<div class="file-extension">{{file.extension}}</div>
														<div class="file-date">{{formatDate(file.ugmtime_add, 'DD.MM.YYYY HH:mm')}}</div>
														<button type="button" class="btn btn-sm m-t-sm btn-danger" @click="onShowSaleFileDeleteModal(file.pkid)" :disabled="loadingFile">Удалить</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div class="row">
										<div class="col-md-12 m-t-sm">
											<div class="bigUploadContainer">
												<h1 class="m-t-none">Загрузка файлов</h1>
												<big-upload
													inputFileAccept="image/*,audio/*,video/*,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.rtf,.csv,.svg,capture=camera,capture=camcorder,capture=microphone,capture=filesystem"
													:scriptPath="'/api/data/lists/sales/'+saleinfo.id+'/files/upload-chunk'"
													:onSuccess="onBiguploadFileSuccess"
												></big-upload>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row m-t-lg">
				<div class="col-md-7">
					<div class="ibox float-e-margins">
						<div class="ibox-content">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Стоимость товаров</label>
										<input type="text" class="form-control text-center" :value="number_format(saleGoodsCost)" disabled="disabled" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Сумма скидки</label>
										<input type="number" class="form-control text-center" min="0" @wheel="$event.target.blur()" v-model.number="saleinfo.discount" />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group has-error">
										<label>Сумма по документу</label>
										<input type="text" class="form-control text-center" :value="number_format(saleCost)" disabled="disabled" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group has-success">
										<label>Внесенная сумма</label>
										<input type="text" class="form-control text-center" :value="number_format(salePaidcost)" disabled="disabled" />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group has-error">
										<label>Долг по документу</label>
										<input type="text" class="form-control text-center" :value="number_format(saleDebtcost)" disabled="disabled" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<button type="button" style="white-space: normal;" class="btn btn-block btn-warning" @click="onShowAddPrepaymentModal()">Внести предоплату</button>
								</div>
								<div class="col-md-6">
									<button type="button" style="white-space: normal;" class="btn btn-block btn-warning" @click="onShowAddPaymentModal()">Внести оплату</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="ibox float-e-margins">
								<div class="ibox-content">
									<div class="form-group m-b-none">
										<label>Дата создания</label>
										<input type="datetime-local" placeholder="Дата и время" class="form-control text-center" v-model="saleinfo.dateadd" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="ibox float-e-margins">
								<div class="ibox-content">
									<div class="form-group m-b-none">
										<label>Длительность продажи</label>
										<input type="text" readonly class="form-control text-center" :value="getDateDuration(saleinfo)" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="ibox float-e-margins">
						<div class="ibox-content">
							<div class="form-group m-b-none">
								<label>Текущий статус</label>
								<select class="form-control" v-model="saleinfo.status_id">
									<option :value="saleStat.id" v-for="saleStat in salesStatuses" :key="saleStat.id">{{saleStat.name}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="tabs-container">
						<ul class="nav nav-tabs">
							<li :class="{'active': tab2 == '1'}">
								<a @click="tab2 = '1'">История оплат</a>
							</li>
							<li :class="{'active': tab2 == '2'}">
								<a @click="tab2 = '2'">История статусов</a>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{'active': tab2 == '1'}" v-show="tab2 == '1'">
								<div class="panel-body">
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th width="15%" class="text-center">Дата</th>
													<th width="30%" class="text-center">Сумма</th>
													<th class="text-center">Описание</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="historypayment in saleinfo.historypayments" :key="historypayment.id">
													<td style="vertical-align: middle;" class="text-center">{{formatDate(historypayment.dateadd, 'DD.MM.YYYY HH:mm')}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(historypayment.amount)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{historypayment.descr}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditHistoryPaymentModal(historypayment.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteHistoryPaymentModal(historypayment.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab2 == '2'}" v-show="tab2 == '2'">
								<div class="panel-body">
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th width="15%" class="text-center">Дата</th>
													<th width="30%" class="text-center">Статус</th>
													<th class="text-center">Описание</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="historystatus in saleinfo.historystatuses" :key="historystatus.id">
													<td style="vertical-align: middle;" class="text-center">{{formatDate(historystatus.dateadd, 'DD.MM.YYYY HH:mm')}}</td>
													<td style="vertical-align: middle;" class="text-center">{{historystatus.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{historystatus.descr}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditHistoryStatusModal(historystatus.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteHistoryStatusModal(historystatus.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row m-t-md">
				<div class="col-xs-3">
					<button type="button" style="white-space: normal;" class="btn btn-danger" @click="onShowDeleteSaleModal" :disabled="loading2">
						<i class="fa fa-trash" :class="{'m-r-sm': !$device.isMobile}"></i>
						<span v-if="!$device.isMobile">Удалить продажу</span>
					</button>
				</div>
				<div class="col-xs-9 text-right">
					<button type="button" @click="onShowSaleSaveVidatModal()" style="white-space: normal;" class="btn btn-warning m-r-xs" :disabled="loading2 || loadingSaveVidat">
						<span v-if="!$device.isMobile">Выдать товары</span>
						<span v-else>Выдать</span>
						<i class="fa m-l-sm" :class="{'fa-angle-double-right': !loadingSaveVidat, 'fa-spinner fa-spin': loadingSaveVidat}"></i>
					</button>
					<button type="button" @click="onSaleSave()" style="white-space: normal;" class="btn btn-info" :disabled="!saleSaveEnable || loading2 || loadingSave">
						<i class="fa m-r-sm" :class="{'fa-floppy-o': !loadingSave, 'fa-spinner fa-spin': loadingSave}"></i>
						<span v-if="!$device.isMobile">Сохранить изменения</span>
						<span v-else>Сохранить</span>
					</button>
				</div>
			</div>
			<div class="alert alert-warning alert-dismissable m-t-md m-b-sm" v-if="error">
				<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
				{{error}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import '@/assets/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';

.bigUploadContainer {
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	text-align: center;
}

.file-box {
	float: none;
	display: inline-block;
	width: 256px;
}
.file {
	margin: 10px;
}
.file .file-pre {
	cursor: pointer;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import { vMaska } from 'maska';
import autosize from 'autosize';
import moment from 'moment-timezone';
import underscore from 'underscore';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead/VueBootstrapTypeahead.vue';
import BigUpload from '@/components/BigUpload.vue';

import FilePreviewModal from '@/components/modals/Sales/FilePreviewModal.vue';
import FileDeleteModal from '@/components/modals/Sales/FileDeleteModal.vue';
import ChangeStatusModal from '@/components/modals/Sales/ChangeStatusModal.vue';
import PrepaymentAddModal from '@/components/modals/Sales/PrepaymentAddModal.vue';
import PaymentAddModal from '@/components/modals/Sales/PaymentAddModal.vue';
import GoodsitemAddModal from '@/components/modals/Sales/GoodsitemAddModal.vue';
import GoodsitemEditModal from '@/components/modals/Sales/GoodsitemEditModal.vue';
import GoodsitemDeleteModal from '@/components/modals/Sales/GoodsitemDeleteModal.vue';
import HistoryPaymentEditModal from '@/components/modals/Sales/HistoryPaymentEditModal.vue';
import HistoryPaymentDeleteModal from '@/components/modals/Sales/HistoryPaymentDeleteModal.vue';
import HistoryStatusEditModal from '@/components/modals/Sales/HistoryStatusEditModal.vue';
import HistoryStatusDeleteModal from '@/components/modals/Sales/HistoryStatusDeleteModal.vue';
import SaleSaveVidatModal from '@/components/modals/Sales/SaleSaveVidatModal.vue';
import SaleDeleteModal from '@/components/modals/Sales/SaleDeleteModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Информация о продаже | MasterSMI Учет'});
	},
	directives: {
		maska: vMaska,
	},
	data: () => ({
		tpl: 'loading',
		
		tab1: '1',
		tab2: '1',
		
		clientStatusShow: false,
		
		salesStatuses: [],
		
		clients: [],
		
		clientinfo: {
			id: null,
			fio: '',
			tel: [{
				value: '',
			}],
			mobtel: [{
				value: '',
			}],
			city: 'Астана',
			address: '',
			email: '',
			status: '',
			comment: '',
			wheretheylearn_id: null,
		},
		saleinfo: {},
		saleinfo_showStatusModal: false,
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
		},
		
		saleSaveEnable: false,
		
		loadingSaveVidat: false,
		loadingSave: false,
		loadingFile: false,
		loading2: false,
		loading: true,
		error: '',
	}),
	computed: {
		// стоимость товаров
		saleGoodsCost(){
			let ret = 0;
			for(let goodsitem of this.saleinfo.goods){
				ret += (goodsitem.price * goodsitem.count);
			}
			return ret;
		},
		// сумма по документу
		saleCost(){
			return this.saleGoodsCost - parseInt(this.saleinfo.discount);
		},
		// внесенная сумма
		salePaidcost(){
			let ret = 0;
			for(let historypayment of this.saleinfo.historypayments){
				ret += historypayment.amount;
			}
			return ret;
		},
		// долг по документу
		saleDebtcost(){
			let ret = this.saleCost - this.salePaidcost;
			if(ret < 0) ret = 0;
			return ret;
		},
	},
	watch: {
		clientinfo: {
			handler(val, oldVal){
				this.saleSaveEnable = true;
			},
			deep: true,
		},
		'saleinfo.comment'(val, oldVal){
			this.saleSaveEnable = true;
		},
		'saleinfo.discount'(val, oldVal){
			this.saleSaveEnable = true;
		},
		'saleinfo.dateadd'(val, oldVal){
			this.saleSaveEnable = true;
		},
		'saleinfo.status_id'(val, oldVal){
			if(this.saleinfo_showStatusModal){
				let that = this;
				this.$vfm.show({
					component: ChangeStatusModal,
					on: {
						async change(close){
							let ret = await that.appModel.doGetHistoryStatusesSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.historystatuses = ret.sale_history_statuses;
							} else {
								that.error = ret.message;
							}
							close();
						},
						cancel(close){
							that.saleinfo.status_id = oldVal;
							that.saleinfo_showStatusModal = false;
							close();
						},
					},
					params: {
						sale_id: that.saleinfo.id,
						status_id: val,
					},
				});
			} else {
				this.saleinfo_showStatusModal = true;
			}
		},
		'saleinfo.goods.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					for(let goodsitem of this.saleinfo.goods){
						if(goodsitem.lightGallery){
							goodsitem.lightGallery.refresh();
						}
					}
				});
			},
			deep: true,
		},
	},
	methods: {
		contains: lib.contains,
		
		getPoster(images){
			return images.find((el) => el.poster);
		},
		getImages(images){
			return images.filter((el) => !el.poster);
		},
		
		// загрузки данных
		// подгрузка списка клиентов для typeahead и изменение фио клиента при редактировании
		getClients: underscore.debounce(async function(query){
			//this.clientinfo.fio = query;
			let ret = await this.appModel.doGetListClients({
				'filter[0][field]': 'lcl.fio',
				'filter[0][operator]': 'like',
				'filter[0][value]': '%'+query+'%',
				'sort[0][field]': 'lcl.fio',
				'sort[0][direction]': 'asc',
				limit: 25,
			});
			if(ret.success){
				this.clients = ret.clients;
			}
		}, 500),
		// загрузка данных продажи
		async loadData(){
			let ret = await this.appModel.doGetSaleData(this.$route.params.id);
			if(ret.success){
				if(ret.exists){
					if(ret.clientinfo){
						this.clientinfo.id = ret.clientinfo.id;
						this.clientinfo.fio = ret.clientinfo.fio;
						this.clientinfo.tel = (ret.clientinfo.tel.length > 0 ? ret.clientinfo.tel : ['']).map((el) => ({value: el}));
						this.clientinfo.mobtel = (ret.clientinfo.mobtel.length > 0 ? ret.clientinfo.mobtel : ['']).map((el) => ({value: el}));
						this.clientinfo.city = ret.clientinfo.city;
						this.clientinfo.address = ret.clientinfo.address;
						this.clientinfo.email = ret.clientinfo.email;
						this.clientinfo.status = ret.clientinfo.status;
						this.clientinfo.comment = ret.clientinfo.comment;
						this.clientinfo.wheretheylearn_id = ret.clientinfo.wheretheylearn_id;
					}
					ret.saleinfo.goods = ret.saleinfo.goods.map((el) => {
						if(el.images.length <= 0) el.images.push({
							id: null,
							pkid: null,
							poster: 1,
							url: '/assets/img/no-image.png',
							urlSmall: '/assets/img/no-image.png',
							descr: '',
							ugmtime_add: this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
						});
						el.lightGallery = (((this.saleinfo || {}).goods || []).find((el2) => el2.id == el.id) || {}).lightGallery || null;
						return el;
					});
					this.saleinfo = ret.saleinfo;
					this.saleinfo = ret.saleinfo;
					this.saleinfo.dateadd = this.formatDate(ret.saleinfo.dateadd, 'YYYY-MM-DDTHH:mm');
					if(ret.saleinfo.dateend != '0000-00-00 00:00:00'){
						this.saleinfo.dateend = this.formatDate(ret.saleinfo.dateend, 'YYYY-MM-DDTHH:mm');
					}
					
					setTimeout(() => {
						this.saleSaveEnable = false;
					}, 200);
					
					this.tpl = 'info';
				} else {
					this.tpl = 'notexists';
				}
			}
		},
		// загрузка списка статусов продаж
		async loadSalesStatuses(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetSalesStatuses();
			if(ret.success){
				this.salesStatuses = ret.sales_statuses;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка "откуда узнали о мастере"
		async loadWhereTheyLearns(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListWhereTheyLearns();
			if(ret.success){
				this.wheretheylearns = ret.wheretheylearns;
				this.wheretheylearns.unshift({
					id: null,
					name: 'Неизвестно',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		// диалоговые окна
		// показ окна предпросмотра файла
		onShowSaleFIlePreviewModal(file_pkid){
			let that = this;
			this.$vfm.show({
				component: FilePreviewModal,
				on: {
					cancel(close){
						close();
					},
				},
				params: {
					file: that.saleinfo.files.find((el) => el.pkid == file_pkid),
				},
			});
		},
		// показ окна удаления файла
		async onShowSaleFileDeleteModal(file_pkid){
			let that = this;
			this.$vfm.show({
				component: FileDeleteModal,
				on: {
					async delete(close){
						that.loading2 = true;
						that.loadingFile = true;
						that.error = '';
						let ret = await that.appModel.doDeleteFileSale(that.saleinfo.id, file_pkid);
						if(ret.success){
							ret = await that.appModel.doGetFilesSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.files = ret.sales_files;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						that.loading2 = false;
						that.loadingFile = false;
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					file: that.saleinfo.files.find((el) => el.pkid == file_pkid),
				},
			});
		},
		// показ окна добавления предоплаты
		onShowAddPrepaymentModal(){
			let that = this;
			this.$vfm.show({
				component: PrepaymentAddModal,
				on: {
					async add(close){
						let ret = await that.appModel.doGetHistoryPaymentsSale(that.saleinfo.id);
						if(ret.success){
							that.saleinfo.historypayments = ret.sale_history_payments;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					sale_id: that.saleinfo.id,
				},
			});
		},
		// показ окна добавления оплаты
		onShowAddPaymentModal(){
			let that = this;
			this.$vfm.show({
				component: PaymentAddModal,
				on: {
					async add(close){
						let ret = await that.appModel.doGetHistoryPaymentsSale(that.saleinfo.id);
						if(ret.success){
							that.saleinfo.historypayments = ret.sale_history_payments;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					sale_id: that.saleinfo.id,
					debtcost: that.saleDebtcost,
				},
			});
		},
		// показ окна добавления товара в продажу
		onShowAddGoodsitemModal(){
			let that = this;
			this.$vfm.show({
				component: GoodsitemAddModal,
				on: {
					async add(selectedGoodsitem, close){
						let ret = await that.appModel.doAddGoodsItemSale(that.saleinfo.id, {
							goodsitem_id: selectedGoodsitem.id,
							name: selectedGoodsitem.name,
							count: selectedGoodsitem.count,
							price: selectedGoodsitem.price,
						});
						if(ret.success){
							ret = await that.appModel.doGetGoodsSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.goods = ret.sale_goods.map((el) => {
									if(el.images.length <= 0) el.images.push({
										id: null,
										pkid: null,
										poster: 1,
										url: '/assets/img/no-image.png',
										urlSmall: '/assets/img/no-image.png',
										descr: '',
										ugmtime_add: that.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
									});
									el.lightGallery = (that.saleinfo.goods.find((el2) => el2.id == el.id) || {}).lightGallery || null;
									return el;
								});
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {},
			});
		},
		// показ окна изменения товара продажи
		onShowEditGoodsitemModal(id){
			let that = this;
			this.$vfm.show({
				component: GoodsitemEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetGoodsSale(that.saleinfo.id);
						if(ret.success){
							that.saleinfo.goods = ret.sale_goods.map((el) => {
								if(el.images.length <= 0) el.images.push({
									id: null,
									pkid: null,
									poster: 1,
									url: '/assets/img/no-image.png',
									urlSmall: '/assets/img/no-image.png',
									descr: '',
									ugmtime_add: that.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
								});
								el.lightGallery = (that.saleinfo.goods.find((el2) => el2.id == el.id) || {}).lightGallery || null;
								return el;
							});
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					sale_id: that.saleinfo.id,
					sale_goodsitem_id: id,
					goodsitem: that.saleinfo.goods.find((el) => el.id == id),
				},
			});
		},
		// показ окна удаления товара из продажи
		onShowDeleteGoodsitemModal(id){
			let that = this;
			this.$vfm.show({
				component: GoodsitemDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteGoodsItemSale(that.saleinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetGoodsSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.goods = ret.sale_goods.map((el) => {
									if(el.images.length <= 0) el.images.push({
										id: null,
										pkid: null,
										poster: 1,
										url: '/assets/img/no-image.png',
										urlSmall: '/assets/img/no-image.png',
										descr: '',
										ugmtime_add: that.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
									});
									el.lightGallery = (that.saleinfo.goods.find((el2) => el2.id == el.id) || {}).lightGallery || null;
									return el;
								});
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					goodsitem: that.saleinfo.goods.find((el) => el.id == id),
				},
			});
		},
		// показ окна изменения элемента истории оплат продажи
		onShowEditHistoryPaymentModal(id){
			let that = this;
			let payment = that.saleinfo.historypayments.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryPaymentEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetHistoryPaymentsSale(that.saleinfo.id);
						if(ret.success){
							that.saleinfo.historypayments = ret.sale_history_payments;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					sale_id: that.saleinfo.id,
					historypayment_id: id,
					amount: payment.amount,
					description: payment.descr,
					dateadd: payment.dateadd,
				},
			});
		},
		// показ окна удаления элемента истории оплат продажи
		onShowDeleteHistoryPaymentModal(id){
			let that = this;
			let payment = that.saleinfo.historypayments.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryPaymentDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteHistoryPaymentSale(that.saleinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetHistoryPaymentsSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.historypayments = ret.sale_history_payments;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					amount: payment.amount,
					description: payment.descr,
					dateadd: payment.dateadd,
				},
			});
		},
		// показ окна измения элемента истории статусов продажи
		onShowEditHistoryStatusModal(id){
			let that = this;
			let status = that.saleinfo.historystatuses.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryStatusEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetHistoryStatusesSale(that.saleinfo.id);
						if(ret.success){
							that.saleinfo.historystatuses = ret.sale_history_statuses;
							
							ret = await that.appModel.doGetSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.status_id = ret.sale.status_id;
								that.saleinfo_showStatusModal = false;
								
								that.saleinfo.dateadd = that.formatDate(ret.sale.dateadd, 'YYYY-MM-DDTHH:mm');
								if(ret.sale.dateend != '0000-00-00 00:00:00'){
									that.saleinfo.dateend = that.formatDate(ret.sale.dateend, 'YYYY-MM-DDTHH:mm');
								}
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					salesStatuses: that.salesStatuses,
					sale_id: that.saleinfo.id,
					historystatus_id: id,
					status_id: status.status_id,
					description: status.descr,
					dateadd: status.dateadd,
				},
			});
		},
		// показ окна удаления элемента истории статусов продажи
		onShowDeleteHistoryStatusModal(id){
			let that = this;
			let status = that.saleinfo.historystatuses.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryStatusDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteHistoryStatusSale(that.saleinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetHistoryStatusesSale(that.saleinfo.id);
							if(ret.success){
								that.saleinfo.historystatuses = ret.sale_history_statuses;
								
								ret = await that.appModel.doGetSale(that.saleinfo.id);
								if(ret.success){
									that.saleinfo.status_id = ret.order.status_id;
									that.saleinfo_showStatusModal = false;
								} else {
									that.error = ret.message;
								}
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					statusName: status.name,
					description: status.descr,
					dateadd: status.dateadd,
				},
			});
		},
		// показ окна удаления продажи
		onShowDeleteSaleModal(){
			let that = this;
			this.$vfm.show({
				component: SaleDeleteModal,
				on: {
					async delete(close){
						that.$router.push({path: '/sales'});
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					sale_id: that.saleinfo.id,
				},
			});
		},
		// сохранение изменений продажи и выдача
		async onShowSaleSaveVidatModal(){
			this.loadingSaveVidat = true;
			if(this.saleSaveEnable){
				await this.onSaleSave();
			}
			if(!this.loading2){
				let that = this;
				this.$vfm.show({
					component: SaleSaveVidatModal,
					on: {
						saveVidat(close){
							that.saleSaveEnable = false;
							that.$router.push({path: '/sales'});
							close();
						},
						cancel(close){
							close();
						},
					},
					params: {
						sale_id: that.saleinfo.id,
					},
				});
			}
			this.loadingSaveVidat = false;
		},
		
		// события-действия
		// выбор клиента через typeahead
		onSelectClient(val){
			let tel = JSON.parse(val.tel || '[]');
			let mobtel = JSON.parse(val.mobtel || '[]');
			this.clientinfo = {
				id: val.id,
				fio: val.fio,
				tel: (tel.length > 0 ? tel : ['']).map((el) => ({value: el})),
				mobtel: (mobtel.length > 0 ? mobtel : ['']).map((el) => ({value: el})),
				city: val.city,
				address: val.address,
				email: val.email,
				status: val.status,
				comment: val.comment,
				wheretheylearn_id: val.wheretheylearn_id,
			};
		},
		// отмена выбора клиента
		onClearClient(){
			if(confirm('Вы уверены?')){
				this.clientinfo = {
					id: null,
					fio: '',
					tel: [{
						value: '',
					}],
					mobtel: [{
						value: '',
					}],
					city: 'Астана',
					address: '',
					email: '',
					status: '',
					comment: '',
					wheretheylearn_id: null,
				};
			}
		},
		// успешная загрузка файла
		async onBiguploadFileSuccess(response){
			if(response.success || false){
				let ret = await this.appModel.doGetFilesSale(this.saleinfo.id);
				if(ret.success){
					this.saleinfo.files = ret.sale_files;
				} else {
					this.error = ret.message;
				}
			}
		},
		
		// добавление новой строки для ввода телефона клиента
		onTelAdd(){
			this.clientinfo.tel.push({value: ''});
		},
		// удаление строки ввода телефона клиента
		onTelDel(index){
			this.clientinfo.tel.splice(index, 1);
		},
		// добавление новой строки для ввода мобильного телефона клиента
		onMobtelAdd(){
			this.clientinfo.mobtel.push({value: ''});
		},
		// удаление строки ввода мобильного телефона клиента
		onMobtelDel(index){
			this.clientinfo.mobtel.splice(index, 1);
		},
		// сохранение изменений продажи
		async onSaleSave(){
			this.loading2 = true;
			this.loadingSave = true;
			this.error = '';
			let ret = await this.appModel.doEditSale(this.saleinfo.id, {
				client_id: this.clientinfo.id,
				fio: this.clientinfo.fio,
				tel: this.clientinfo.tel.filter((el) => el.value != '').map((el) => el.value),
				mobtel: this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value),
				city: this.clientinfo.city,
				address: this.clientinfo.address,
				email: this.clientinfo.email,
				status: this.clientinfo.status,
				description: this.clientinfo.comment,
				wheretheylearn_id: this.clientinfo.wheretheylearn_id,
				comment: this.saleinfo.comment,
				discount: this.saleinfo.discount,
				dateadd: moment.tz(this.saleinfo.dateadd, 'Asia/Almaty').utc().format('YYYY-MM-DDTHH:mm'),
			});
			if(ret.success){
				await this.loadData();
			} else {
				this.error = ret.message;
			}
			this.loading2 = false;
			this.loadingSave = false;
		},
		
		// функции
		// получение длительности продажи
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadSalesStatuses();
		await this.loadWhereTheyLearns();
		
		await this.loadData();
		
		autosize(this.$refs.sale_comment);
		
		setTimeout(() => {
			this.saleSaveEnable = false;
		}, 200);
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteLeave(to, from, next){
		if(this.saleSaveEnable){
			if(confirm('Имеются не сохраненные изменения! Вы действительно хотите их отменить?')){
				next();
			}
		} else {
			next();
		}
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		VueBootstrapTypeahead,
		Lightgallery,
		BigUpload,
	},
};
</script>