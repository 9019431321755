<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-lg" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Добавление продажи</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row" v-if="loading">
							<div class="col-md-12" style="text-align: center;">
								<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
								<h1>ЗАГРУЗКА ДАННЫХ...</h1>
							</div>
						</div>
						<div v-else>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Дата и время создания <span class="text-danger">*</span></label>
										<input type="datetime-local" ref="dateadd" class="form-control text-center" v-model="dateadd" required />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Фамилия, Имя и Отчество клиента <span class="text-danger">*</span></label>
										<vue-bootstrap-typeahead
											inputClass="text-center"
											placeholder="ФИО клиента"
											v-model="clientinfo.fio"
											:data="clients"
											:serializer="s => s.fio"
											@hit="onSelectClient($event)"
											@input="getClients($event.target.value)"
										>
											<template v-slot:prepend>
												<span class="input-group-btn">
													<button type="button" class="btn btn-white" :disabled="clientinfo.id == null" @click="onClearClient()" content="Отменить выбор клиента" v-tippy="{placement: 'top'}">
														<i class="fa fa-times"></i>
													</button>
												</span>
											</template>
											<template v-slot:append>
												<span class="input-group-btn" v-if="clientinfo.id">
													<router-link :to="{path: '/clients/' + clientinfo.id}" class="btn btn-white" content="Перейти к клиенту" v-tippy="{placement: 'top'}">
														<i class="fa fa-chevron-right"></i>
													</router-link>
												</span>
											</template>
										</vue-bootstrap-typeahead>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-5">
									<div class="form-group">
										<label>Телефоны</label>
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="clientinfo.tel[0].value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onTelAdd()">
													<i class="fa fa-plus"></i>
												</button>
											</span>
										</div>
									</div>
									<div class="form-group" v-for="(tel, index) in clientinfo.tel.slice(1)">
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="tel.value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onTelDel(index + 1)">
													<i class="fa fa-minus"></i>
												</button>
											</span>
										</div>
									</div>
								</div>
								<div class="col-md-7">
									<div class="form-group">
										<label>Мобильные телефоны</label>
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="clientinfo.mobtel[0].value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onMobtelAdd()">
													<i class="fa fa-plus"></i>
												</button>
											</span>
										</div>
									</div>
									<div class="form-group" v-for="(mobtel, index) in clientinfo.mobtel.slice(1)">
										<div class="input-group">
											<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="mobtel.value" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="onMobtelDel(index + 1)">
													<i class="fa fa-minus"></i>
												</button>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-5">
									<div class="form-group">
										<label>Откуда узнали о мастере</label>
										<select class="form-control" v-model="clientinfo.wheretheylearn_id">
											<option :value="wheretheylearn.id" v-for="wheretheylearn in wheretheylearns" :key="wheretheylearn.id">{{wheretheylearn.name}}</option>
										</select>
									</div>
								</div>
								<div class="col-md-7">
									<div class="form-group">
										<label>Статус клиента</label>
										<div class="input-group">
											<input type="text" placeholder="Статус клиента" class="form-control text-center text-danger" :style="{WebkitTextSecurity: clientStatusShow ? 'none' : 'disc'}" v-model="clientinfo.status" />
											<span class="input-group-btn">
												<button type="button" class="btn btn-white" @click="clientStatusShow = !clientStatusShow">
													<i class="fa fa-eye" :class="{'fa-eye': !clientStatusShow, 'fa-eye-slash': clientStatusShow}"></i>
												</button>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Заметка к клиенту</label>
										<textarea ref="description" placeholder="Заметка к клиенту" class="form-control" style="resize: none;" rows="1" v-model="clientinfo.comment"></textarea>
									</div>
								</div>
							</div>
							<div class="hr-line-dashed"></div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Комментарий к продаже</label>
										<textarea ref="comment" placeholder="Комментарий" class="form-control" style="resize: none;" rows="1" v-model="saleinfo.comment"></textarea>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Предоплата</label>
										<input type="number" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="saleinfo.amount_prepaid" placeholder="Сумма предоплаты" />
									</div>
								</div>
							</div>
							<div class="hr-line-dashed"></div>
							<div class="table-responsive">
								<table class="table table-hover m-b-none">
									<thead>
										<tr>
											<th width="70">&nbsp;</th>
											<th>Товар</th>
											<th width="100" style="min-width: 100px;" class="text-center">Кол-во</th>
											<th width="100" style="min-width: 100px;" class="text-center">Цена</th>
											<th width="50">&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(goodsitem, index) in saleinfo.goods">
											<td style="vertical-align: middle;">
												<lightgallery
													:settings="lightgallerySettings"
													:onInit="(detail) => goodsitem.lightGallery = detail.instance"
												>
													<a :href="getPoster(goodsitem.images).url">
														<img :src="getPoster(goodsitem.images).urlSmall" style="width: 50px; height: 50px;" />
													</a>
													<a :href="image.url" v-for="image in getImages(goodsitem.images)" :key="image.pkid" style="display: none;">
														<img :src="image.urlSmall" />
													</a>
												</lightgallery>
											</td>
											<td style="vertical-align: middle;">{{goodsitem.name}}</td>
											<td style="vertical-align: middle;" class="text-center">{{number_format(goodsitem.count)}}</td>
											<td style="vertical-align: middle;" class="text-center">{{number_format(goodsitem.price)}} тг</td>
											<td style="vertical-align: middle;" class="text-right">
												<button class="btn btn-sm btn-danger" @click="onDeleteGoodsitem(index)">
													<i class="fa fa-times"></i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="row m-t">
								<div class="col-md-12 text-center">
									<div class="btn btn-primary" @click="onShowAddGoodsitemModal">Добавить товар</div>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" ref="submit" style="display: none;"></button>
						<button type="button" class="btn btn-primary" @click="onAddClose" :disabled="loading || loading2 || clientinfo.fio == '' || saleinfo.goods.length <= 0">Добавить</button>
						<button type="button" class="btn btn-primary" @click="onAddGoto" :disabled="loading || loading2 || clientinfo.fio == '' || saleinfo.goods.length <= 0">Добавить и перейти</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import { vMaska } from 'maska';
import autosize from 'autosize';
import moment from 'moment-timezone';
import underscore from 'underscore';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead/VueBootstrapTypeahead.vue';
import GoodsitemAddModal from '@/components/modals/Sales/GoodsitemAddModal.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	directives: {
		maska: vMaska,
	},
	data: () => ({
		wheretheylearns: [],
		clients: [],
		
		clientStatusShow: false,
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
		},
		
		dateadd: '',
		clientinfo: {
			id: null,
			fio: '',
			tel: [{
				value: '',
			}],
			mobtel: [{
				value: '',
			}],
			status: '',
			comment: '',
			wheretheylearn_id: null,
		},
		saleinfo: {
			comment: '',
			amount_prepaid: '',
			goods: [],
		},
		
		action: '',
		
		error: '',
		loading: false,
		loading2: false,
	}),
	computed: {},
	watch: {
		'saleinfo.goods.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					for(let goodsitem of this.saleinfo.goods){
						if(goodsitem.lightGallery){
							goodsitem.lightGallery.refresh();
						}
					}
				});
			},
			deep: true,
		},
	},
	methods: {
		getPoster(images){
			return images.find((el) => el.poster);
		},
		getImages(images){
			return images.filter((el) => !el.poster);
		},
		
		// подгрузка списка клиентов для typeahead и изменение фио клиента при редактировании
		getClients: underscore.debounce(async function(query){
			//this.clientinfo.fio = query;
			let ret = await this.appModel.doGetListClients({
				'filter[0][field]': 'lcl.fio',
				'filter[0][operator]': 'like',
				'filter[0][value]': '%'+query+'%',
				'sort[0][field]': 'lcl.fio',
				'sort[0][direction]': 'asc',
				limit: 25,
			});
			if(ret.success){
				this.clients = ret.clients.map((el) => {
					el.tel = JSON.parse(el.tel || '[]');
					el.mobtel = JSON.parse(el.mobtel || '[]');
					return el;
				});;
			}
		}, 500),
		// загрузка списка "откуда узнали о мастере"
		async loadWhereTheyLearns(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListWhereTheyLearns();
			if(ret.success){
				this.wheretheylearns = ret.wheretheylearns;
				this.wheretheylearns.unshift({
					id: null,
					name: 'Неизвестно',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		// выбор клиента через typeahead
		onSelectClient(val){
			//let tel = JSON.parse(val.tel || '[]');
			//let mobtel = JSON.parse(val.mobtel || '[]');
			this.clientinfo = {
				id: val.id,
				fio: val.fio,
				tel: (val.tel.length > 0 ? val.tel : ['']).map((el) => ({value: el})),
				mobtel: (val.mobtel.length > 0 ? val.mobtel : ['']).map((el) => ({value: el})),
				status: val.status,
				comment: val.comment,
				wheretheylearn_id: val.wheretheylearn_id,
			};
		},
		// отмена выбора клиента
		onClearClient(){
			if(confirm('Вы уверены?')){
				this.clientinfo = {
					id: null,
					fio: '',
					tel: [{
						value: '',
					}],
					mobtel: [{
						value: '',
					}],
					status: '',
					comment: '',
					wheretheylearn_id: null,
				};
			}
		},
		// добавление новой строки для ввода телефона клиента
		onTelAdd(){
			this.clientinfo.tel.push({value: ''});
		},
		// удаление строки ввода телефона клиента
		onTelDel(index){
			this.clientinfo.tel.splice(index, 1);
		},
		// добавление новой строки для ввода мобильного телефона клиента
		onMobtelAdd(){
			this.clientinfo.mobtel.push({value: ''});
		},
		// удаление строки ввода мобильного телефона клиента
		onMobtelDel(index){
			this.clientinfo.mobtel.splice(index, 1);
		},
		
		// показ окна добавления товара
		onShowAddGoodsitemModal(){
			let that = this;
			this.$vfm.show({
				component: GoodsitemAddModal,
				on: {
					add(selectedGoodsitem, close){
						that.saleinfo.goods.push({
							goodsitem_id: selectedGoodsitem.id,
							name: selectedGoodsitem.name,
							count: selectedGoodsitem.count,
							price: selectedGoodsitem.price,
							images: selectedGoodsitem.images,
							lightGallery: null,
						});
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {},
			});
		},
		onDeleteGoodsitem(index){
			this.saleinfo.goods.splice(index, 1);
		},
		
		onAddClose(){
			this.action = 'add';
			this.$refs.submit.click();
		},
		onAddGoto(){
			this.action = 'addGoto';
			this.$refs.submit.click();
		},
		async onAdd(close){
			this.loading2 = true;
			this.error = '';
			let ret = await this.appModel.doAddSale({
				client_id: this.clientinfo.id,
				fio: this.clientinfo.fio,
				tel: this.clientinfo.tel.filter((el) => el.value != '').map((el) => el.value),
                mobtel: this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value),
				status: this.clientinfo.status,
				description: this.clientinfo.comment,
				wheretheylearn_id: this.clientinfo.wheretheylearn_id,
				dateadd: moment.tz(this.dateadd, 'Asia/Almaty').utc().format('YYYY-MM-DDTHH:mm'),
				amount_prepaid: this.saleinfo.amount_prepaid,
				comment: this.saleinfo.comment,
				goods: this.saleinfo.goods.map((el) => {
					delete el.lightGallery;
					return el;
				}),
			});
			if(ret.success){
				this.$emit(this.action, ret.id, close);
			} else {
				this.error = ret.message;
			}
			this.action = '';
			this.loading2 = false;
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		if(this.$vfm.modals[0].params._value.client){
			this.onSelectClient(this.$vfm.modals[0].params._value.client);
		}
		
		this.dateadd = this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm');
		
		await this.loadWhereTheyLearns();
		
		autosize(this.$refs.description);
		autosize(this.$refs.comment);
	},
	components: {
		VueBootstrapTypeahead,
		Lightgallery,
	},
}
</script>