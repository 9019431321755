<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Изменение товара</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="form-group">
							<label>Название товара <span class="text-danger">*</span></label>
							<input type="text" ref="name" placeholder="Название товара" class="form-control" v-model="name" :required="sales_count <= 0" :disabled="sales_count > 0" />
						</div>
						<div class="form-group">
							<label>Доступное количество товара <span class="text-danger">*</span></label>
							<input type="number" ref="count" placeholder="Количество товара" class="form-control" @wheel="$event.target.blur()" v-model="count" required />
						</div>
						<div class="form-group">
							<label>Стоимость товара <span class="text-danger">*</span></label>
							<input type="number" ref="price" placeholder="Стоимость товара" class="form-control" @wheel="$event.target.blur()" v-model="price" required />
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="order-images-gallery">
									<lightgallery
										:settings="lightgallerySettings"
										:onInit="onLightGalleryInit"
									>
										<div class="property-image imgbl" v-for="(image, index) in images" :key="image.id">
											<a :href="image.url" class="image block">
												<img class="img-responsive" :src="image.urlSmall" :style="{width: (!$device.isMobile ? '150px' : '225px'), height: (!$device.isMobile ? '150px' : '225px')}" />
											</a>
											<div class="btn-group">
												<button type="button" class="btn btn-info" @click="onGoodsitemImageSetPoster(image.pkid)" content="Сделать постером" v-tippy="{placement: 'top'}" v-if="!image.poster">Постер</button>
												<button type="button" class="btn btn-danger" @click="onGoodsitemImageDelete(image.pkid)" content="Удалить изображение" v-tippy="{placement: 'top'}" v-if="sales_count <= 0">
													<i class="fa fa-times"></i>
												</button>
											</div>
										</div>
									</lightgallery>
								</div>
							</div>
						</div>
						<div class="row m-t" v-if="sales_count <= 0">
							<div class="col-md-12">
								<div class="bigUploadContainer">
									<h1 class="m-t-none">Загрузка изображений</h1>
									<big-upload
										inputFileAccept="image/jpeg,image/png,image/gif,capture=camera"
										:scriptPath="'/api/data/lists/goods/'+id+'/images/upload-chunk'"
										:onSuccess="onBiguploadImgSuccess"
									></big-upload>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>
.bigUploadContainer {
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	text-align: center;
}

.order-images-gallery {
	margin: 0;
	padding: 0;
	text-align: center;
}
.order-images-gallery .property-image {
	display: inline-block;
	margin: 10px 10px;
}
.order-images-gallery .property-image a.image,
.order-images-gallery .property-image a.btn {
	display: block;
}
.order-images-gallery .property-image a.image {
	padding-bottom: 5px;
}
</style>

<script>
import lib from '@/lib';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

import BigUpload from '@/components/BigUpload.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
		lightGallery: null,
		
		id: null,
		name: '',
		count: 1,
		price: '',
		archived: 0,
		sales_count: 0,
		images: [],
		
		error: '',
		loading: false,
	}),
	watch: {
		images: {
			handler(val, oldVal){
				this.$nextTick(() => {
					if(this.lightGallery) this.lightGallery.refresh();
				});
			},
			deep: true,
		},
	},
	methods: {
		// инициализация галереи "до ремонта"
		onLightGalleryInit(detail){
            this.lightGallery = detail.instance;
        },
		
		// успешная загрузка изображения
		async onBiguploadImgSuccess(response){
			if(response.success || false){
				this.$emit('uploadImg', close);
				
				let ret = await this.appModel.doGetImagesGoodsitem(this.$vfm.modals[0].params._value.goodsitem.id);
				if(ret.success){
					this.images = ret.goodsitem_images;
				} else {
					this.error = ret.message;
				}
			}
		},
		async onGoodsitemImageSetPoster(image_pkid){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doSetPosterImageGoodsitem(this.$vfm.modals[0].params._value.goodsitem.id, image_pkid);
			if(ret.success){
				this.$emit('setPosterImg', close);
				
				ret = await this.appModel.doGetImagesGoodsitem(this.$vfm.modals[0].params._value.goodsitem.id);
				if(ret.success){
					this.images = ret.goodsitem_images;
				} else {
					this.error = ret.message;
				}
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async onGoodsitemImageDelete(image_pkid){
			if(confirm('Вы уверены?')){
				this.loading = true;
				this.error = '';
				let ret = await this.appModel.doDeleteImageGoodsitem(this.$vfm.modals[0].params._value.goodsitem.id, image_pkid);
				if(ret.success){
					this.$emit('deleteImg', close);
					
					ret = await this.appModel.doGetImagesGoodsitem(this.$vfm.modals[0].params._value.goodsitem.id);
					if(ret.success){
						this.images = ret.goodsitem_images;
					} else {
						this.error = ret.message;
					}
				} else {
					this.error = ret.message;
				}
				this.loading = false;
			}
		},
		
		async onEdit(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditGoodsItem(this.$vfm.modals[0].params._value.goodsitem.id, {
				name: this.name,
				count: this.count,
				price: this.price,
			});
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	async mounted(){
		this.id = this.$vfm.modals[0].params._value.goodsitem.id;
		this.name = this.$vfm.modals[0].params._value.goodsitem.name;
		this.count = this.$vfm.modals[0].params._value.goodsitem.count;
		this.price = this.$vfm.modals[0].params._value.goodsitem.price;
		this.archived = this.$vfm.modals[0].params._value.goodsitem.archived;
		this.sales_count = this.$vfm.modals[0].params._value.goodsitem.sales_count;
		this.images = this.$vfm.modals[0].params._value.goodsitem.images.filter((el) => el.pkid);
		
		setTimeout(() => {
			this.$refs.name.focus();
		}, 300);
	},
	components: {
		Lightgallery,
		BigUpload,
	},
}
</script>