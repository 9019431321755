<template>
	<div class="lock-word animated fadeInDown">
		<span class="first-word">LOCKED</span><span>SCREEN</span>
	</div>
	<div class="middle-box text-center lockscreen animated fadeInDown">
		<div>
			<div class="m-b-md">
				<img
					alt="image"
					style="width: 140px; height: 140px;"
					class="img-circle circle-border"
					src="/assets/img/mastersmirsc.png"
				/>
			</div>
			<h3>MasterSMI Учет</h3>
			<p>Доступ запрещен.<br/>Вам нужно ввести пароль, чтобы получить доступ</p>
			<form class="m-t" role="form" @submit.prevent="onLoginSubmit">
				<div class="form-group">
					<input
						type="password"
						class="form-control text-center"
						id="password"
						placeholder="******"
						ref="password"
						v-model="password"
						autocomplete="current-password"
						required
					/>
				</div>
				<button type="submit" class="btn btn-primary block full-width" :disabled="btnLoginDisabled">Войти</button>
				<div class="alert alert-warning text-center m-t-sm m-b-none" v-if="errorLogin">{{errorLogin}}</div>
			</form>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Авторизация | MasterSMI Учет'});
	},
	data: () => ({
		password: '',
		
		errorLogin: '',
		btnLoginDisabled: false,
	}),
	methods: {
		async onLoginSubmit(){
			this.errorLogin = '';
			this.btnLoginDisabled = true;
			
			let res = await this.authModel.doLogin(this.password);
			
			this.btnLoginDisabled = false;
			if(res.success){
				this.$router.push('/');
			} else {
				this.errorLogin = res.error;
			}
		},
	},
	mounted(){
		this.$refs.password.focus();
	},
	beforeMount(){
		window.scrollTo(0, 0);
		document.body.classList.add('gray-bg');
	},
	beforeUnmount(){
		document.body.classList.remove('gray-bg');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('gray-bg');
	},
};
</script>