<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Изменение типа инструмента</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="form-group">
							<label>Инструмент <span class="text-danger">*</span></label>
							<select class="form-control" v-model="instrument_id" required>
								<option value="" disabled="disabled">Инструмент не выбран</option>
								<option :value="instrumentname.id" v-for="instrumentname in instrumentnames" :key="instrumentname.id">{{instrumentname.name}}</option>
							</select>
							<!--
							<vue-multiselect
								v-model="instrument"
								:options="instrumentnames"
								track-by="name"
								label="name"
								placeholder="Инструмент"
							></vue-multiselect>
							-->
						</div>
						<div class="form-group" :class="{'m-b-none': !error}">
							<label>Название типа инструмента <span class="text-danger">*</span></label>
							<input type="text" ref="instrumentType" placeholder="Название типа инструмента" class="form-control" v-model="instrumentType" required />
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
//import VueMultiselect from 'vue-multiselect';
//import 'vue-multiselect/dist/vue-multiselect.css';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		instrumentnames: [],
		
		//instrument: null,
		instrument_id: '',
		instrumentType: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onEdit(close){
			this.loading = true;
			this.error = '';
			//if(!this.instrument){
			if(!this.instrument_id){
                this.error = 'Инструмент не выбран';
                this.loading = false;
                return;
            }
			let ret = await this.appModel.doEditInstrumentType(this.$vfm.modals[0].params._value.instrumentType.id, {
				//instrument_id: this.instrument.id,
				instrument_id: this.instrument_id,
				type: this.instrumentType,
			});
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	async mounted(){
		this.instrumentnames = this.$vfm.modals[0].params._value.instrumentnames;
		
		/*this.instrument = {
			id: this.$vfm.modals[0].params._value.instrumentType.instrument_id,
			name: this.instrumentnames.find((el) => el.id === this.$vfm.modals[0].params._value.instrumentType.instrument_id).name,
		};*/
		this.instrument_id = this.$vfm.modals[0].params._value.instrumentType.instrument_id;
		this.instrumentType = this.$vfm.modals[0].params._value.instrumentType.type;
		
		setTimeout(() => {
			this.$refs.instrumentType.focus();
		}, 300);
	},
	components: {
		//VueMultiselect,
	},
}
</script>