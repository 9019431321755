<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-lg" :click-to-close="false">
		<div class="modal-content">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Предпросмотр файла</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message text-center">
						<img alt="image" style="max-width: 100%;" :src="file.url" v-if="contains(file.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)" />
						<audio controls v-else-if="contains(file.extension, ['mp3', 'amr', 'wav', 'ogg', 'm4a'], true)">
							<source :src="file.url" />
							Аудиоплеер не поддерживается данным браузером
						</audio>
						<video width="100%" height="400" controls="controls" v-else-if="contains(file.extension, ['mp4', 'avi', 'wmv', 'mov', 'mkv', 'mpg', 'mpeg', 'mpeg4', '3gp', 'webm', 'ogv', 'm4v'], true)">
							<source :src="file.url" />
							Видеоплеер не поддерживается данным браузером.
						</video>
						<div class="alert alert-warning" v-else>Предпросмотр недоступен</div>
						<div class="m-t-sm">
							<a class="btn btn-info" :href="file.url" download>Скачать</a>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="button" class="btn btn-primary" @click="onOpenInNewWindow(close)">Открыть в новой вкладке</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Закрыть</button>
					</div>
				</div>
			</div>
		</div>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		file: {
			id: null,
			act_id: null,
			pkid: null,
			descr: '',
			extension: '',
			ugmtime_add: '',
			url: '',
		},
	}),
	methods: {
		contains: lib.contains,
		
		onOpenInNewWindow(close){
			window.open(this.appModel.project_url + this.file.url, '_blank');
		},
	},
	mounted(){
		this.file = this.$vfm.modals[0].params._value.file;
	},
}
</script>