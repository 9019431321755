<template>
	<div id="wrapper">
		<div id="page-wrapper" class="gray-bg">
			<div class="row border-bottom white-bg">
				<nav class="navbar navbar-static-top" role="navigation">
					<div class="navbar-header">
						<button aria-controls="navbar" aria-expanded="false" class="navbar-toggle" type="button" @click="navbarShow = !navbarShow">
							<i class="fa fa-reorder"></i>
						</button>
						<router-link :to="{path: '/orderinfo'}" class="navbar-brand">MasterSMI Учет</router-link>
					</div>
					<div class="navbar-collapse" :class="{'collapse': !navbarShow}">
						<ul class="nav navbar-top-links navbar-right">
							<li>
								<router-link :to="{path: '/orderinfo'}">
									<i class="fa fa-sign-out"></i>Назад
								</router-link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<div class="wrapper wrapper-content" :style="{padding: !$device.isMobile ? 'auto' : '20px 10px 40px'}">
				<div :class="{'container': !$device.isMobile}" v-if="tpl == 'loading'">
					<div style="text-align: center;">
						<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
						<h1>ЗАГРУЗКА ДАННЫХ...</h1>
					</div>
				</div>
				<div :class="{'container': !$device.isMobile}" v-if="tpl == 'notexists'">
					<div style="color: #f00; text-align: center;">
						<h1>РЕМОНТ НЕ НАЙДЕН</h1>
						<div class="m-t-lg">
							<router-link :to="{path: '/orderinfo'}" class="btn btn-primary waves-effect waves-light">Назад</router-link>
						</div>
					</div>
				</div>
				<div :class="{'container': !$device.isMobile}" v-if="tpl == 'info'">
					<div class="row">
						<div class="col-md-7">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Информация о ремонте</h5>
								</div>
								<div class="ibox-content no-padding">
									<ul class="list-group">
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Ремонт №:</b>
													<b class="visible-xs">Рем. №:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.id}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Клиент:</b>
												</div>
												<div class="col-xs-8">
													<div>{{clientinfo.fio}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.defect_descr">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Состояние и общее описание неисправностей:</b>
													<b class="visible-xs">Состояние и общее описание неисправн.:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.defect_descr}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.complection">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Комплектность:</b>
													<b class="visible-xs">Комплектн.:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.complection}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.comment">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Комментарий:</b>
													<b class="visible-xs">Коммент.:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.comment}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Статус:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.status_name}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Дата начала ремонта:</b>
													<b class="visible-xs">Дата начала:</b>
												</div>
												<div class="col-xs-8">
													<div>{{formatDate(orderinfo.dateadd, 'DD.MM.YYYY')}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.dateend != '0000-00-00 00:00:00'">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Дата завершения ремонта:</b>
													<b class="visible-xs">Дата заверш.:</b>
												</div>
												<div class="col-xs-8">
													<div>{{formatDate(orderinfo.dateend, 'DD.MM.YYYY')}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Длительность ремонта:</b>
													<b class="visible-xs">Длит-ть:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderDuration}}</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-md-5">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Инструмент</h5>
								</div>
								<div class="ibox-content no-padding">
									<ul class="list-group">
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b class="hidden-xs">Инструмент:</b>
													<b class="visible-xs">Инстр-т:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.instrument_name}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Тип:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.instrument_type_name}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Размер:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.instrument_size_name}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Фирма:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.firm_name}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.instrument_model">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Модель:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.instrument_model}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.instrument_color">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Цвет:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.instrument_color}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item" v-if="orderinfo.instrument_serial_number">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Серийный номер:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.instrument_serial_number}}</div>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<div class="row">
												<div class="col-xs-4 text-right">
													<b>Кейс:</b>
												</div>
												<div class="col-xs-8">
													<div>{{orderinfo.casename_name}}</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div :class="{'col-md-6': orderinfo.images_after.length > 0, 'col-md-12': orderinfo.images_after.length <= 0}" v-if="orderinfo.images_before.length > 0">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Фото до ремонта</h5>
								</div>
								<div class="ibox-content">
									<div class="lightBoxGallery">
										<lightgallery
											:settings="lightgallerySettings"
										>
											<div class="property-image" v-for="(image, index) in orderinfo.images_before" :key="index" @click="origin_images_before = index">
												<a class="image" :href="image.url">
													<img :src="image.urlSmall" style="width: 100px; height: 100px;" />
												</a>
											</div>
										</lightgallery>
									</div>
								</div>
							</div>
						</div>
						<div :class="{'col-md-6': orderinfo.images_before.length > 0, 'col-md-12': orderinfo.images_before.length <= 0}" v-if="orderinfo.images_after.length > 0">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Фото в процессе и после ремонта</h5>
								</div>
								<div class="ibox-content">
									<div class="lightBoxGallery">
										<lightgallery
											:settings="lightgallerySettings"
										>
											<div class="property-image" v-for="(image, index) in orderinfo.images_after" :key="index" @click="origin_images_after = index">
												<a class="image" :href="image.url">
													<img :src="image.urlSmall" style="width: 100px; height: 100px;" />
												</a>
											</div>
										</lightgallery>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Предполагаемая стоимость</h5>
								</div>
								<div class="ibox-content text-center">
									<h1 class="no-margins">{{number_format(orderinfo.amount_paid)}} &#8376;</h1>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Сумма по документу</h5>
								</div>
								<div class="ibox-content text-center">
									<h1 class="no-margins">{{number_format(orderCost)}} &#8376;</h1>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Внесенная сумма</h5>
								</div>
								<div class="ibox-content text-center">
									<h1 class="no-margins" :class="{'text-info': orderPaidcost > 0}">{{number_format(orderPaidcost)}} &#8376;</h1>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Долг по документу</h5>
								</div>
								<div class="ibox-content text-center">
									<h1 class="no-margins" :class="{'text-danger': orderDebtcost > 0}">{{number_format(orderDebtcost)}} &#8376;</h1>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Производимые работы</h5>
								</div>
								<div class="ibox-content">
									<div class="table-responsive" v-if="orderinfo.services.length > 0">
										<table class="table table-striped">
											<thead>
												<tr>
													<th>Производимая работа</th>
													<th class="text-center" width="15%">Кол-во</th>
													<th class="text-center" width="15%">Цена</th>
													<th class="text-center" width="15%">Сумма</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="service in orderinfo.services" :key="service.id">
													<td style="vertical-align: middle;">{{service.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{service.count}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(service.price)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(service.price * service.count)}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div v-else>Производимых работ нет</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Требуемые материалы</h5>
								</div>
								<div class="ibox-content">
									<div class="table-responsive" v-if="orderinfo.materials.length > 0">
										<table class="table table-striped">
											<thead>
												<tr>
													<th>Материал</th>
													<th class="text-center" width="15%">Кол-во</th>
													<th class="text-center" width="15%">Цена</th>
													<th class="text-center" width="15%">Сумма</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="material in orderinfo.materials" :key="material.id">
													<td style="vertical-align: middle;">{{material.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{material.count}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(material.price)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(material.price * material.count)}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div v-else>Требуемых материалов нет</div>
								</div>
							</div>
						</div>
					</div>
					<!--
					<div class="row">
						<div class="col-md-4 col-md-offset-4">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Выгрузить акты</h5>
								</div>
								<div class="ibox-content no-padding">
									<div class="list-group">
										<a href="#" class="list-group-item">
											Акт приемки
										</a>
										<a href="#" class="list-group-item">
											Приложение к акту приемки
										</a>
										<a href="#" class="list-group-item">
											Акт выполненных работ
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					-->
					<div class="row">
						<div class="col-md-offset-2 col-md-8">
							<div class="ibox float-e-margins">
								<div class="ibox-title">
									<h5>Связаться с мастером</h5>
								</div>
								<div class="ibox-content text-center">
									<h3>Владимир Леонидович Михалёв</h3><br/>
									<div class="row">
										<div class="col-md-6">
											<div>
												<a class="btn btn-info" href="tel:+77016226396" style="white-space: normal; margin-bottom: 10px;">
													<i class="fa fa-phone"></i>&nbsp;&nbsp;Позвонить +7 701 622 63 96
												</a>
											<div>
											</div>
												<a class="btn btn-info" href="tel:+77057825182" style="white-space: normal; margin-bottom: 10px;">
													<i class="fa fa-phone"></i>&nbsp;&nbsp;Позвонить +7 705 782 51 82
												</a>
											</div>
											<div>
												<a class="btn btn-info" href="tel:+77076226396" style="white-space: normal; margin-bottom: 10px;">
													<i class="fa fa-phone"></i>&nbsp;&nbsp;Позвонить +7 707 622 63 96
												</a>
											</div>
										</div>
										<div class="col-md-6">
											<div>
												<a class="btn btn-primary" :href="!$device.isMobile ? 'https://wa.me/77016226396' : 'whatsapp://send?phone=+77016226396'" style="white-space: normal; margin-bottom: 10px;">
													<i class="fa fa-whatsapp"></i>&nbsp;&nbsp;Написать в WhatsApp
												</a>
											</div>
											<div>
												<a class="btn btn-info" :href="!$device.isMobile ? 'https://telegram.me/miholia' : 'tg://resolve?domain=miholia'" style="white-space: normal; margin-bottom: 10px;">
													<i class="fa fa-telegram"></i>&nbsp;&nbsp;Написать в Telegram
												</a>
											</div>
											<div>
												<a class="btn btn-info" href="mailto:stoska57@mail.ru" style="white-space: normal; margin-bottom: 10px;">
													<i class="fa fa-envelope-o"></i>&nbsp;&nbsp;Написать на stoska57@mail.ru
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="pull-right">
					MasterSMI Учет
				</div>
				<div>
					Mikhalev's Guitars &copy; 2018-{{new Date().getFullYear()}}
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import '@/assets/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';

.lightBoxGallery .property-image {
	display: inline-block;
}
.lightBoxGallery .property-image a.image,
.lightBoxGallery .property-image a.btn {
	display: block;
}
.lightBoxGallery .property-image a.btn {
	margin: 0px 5px 5px 5px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Информация о ремонте | MasterSMI Учет'});
	},
	data: () => ({
		navbarShow: false,
		
		tpl: 'loading',
		
		orderinfo: {},
		clientinfo: {},
		wheretheylearns: [],
		materials: [],
		services: [],
		historystatuses: [],
		historypayments: [],
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
	}),
	computed: {
		imagesBefore(){
			return this.orderinfo.images_before.map((el) => el.url);
		},
		imagesAfter(){
			return this.orderinfo.images_after.map((el) => el.url);
		},
		orderDuration(){
			return (this.orderinfo.status_id == 4
				|| this.orderinfo.status_id == 5
				|| this.orderinfo.status_id == 6 ?
					(lib.dateDiff(this.formatDate(this.orderinfo.dateadd, 'YYYY-MM-DD'), this.formatDate(this.orderinfo.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(this.orderinfo.dateadd, 'YYYY-MM-DD'), this.formatDate(this.orderinfo.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(this.orderinfo.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days - 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(this.orderinfo.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days - 1)
				);
		},
		orderServicesCost(){
			let ret = 0;
			for(let service of this.services){
				ret += (service.price * service.count);
			}
			return ret;
		},
		orderMaterialsCost(){
			let ret = 0;
			for(let material of this.materials){
				ret += (material.price * material.count);
			}
			return ret;
		},
		orderCost(){
			return this.orderServicesCost + this.orderMaterialsCost;
		},
		orderPaidcost(){
			let ret = 0;
			for(let historypayment of this.historypayments){
				ret += historypayment.amount;
			}
			return ret;
		},
		orderDebtcost(){
			let ret = this.orderCost - this.orderPaidcost;
			if(ret < 0) ret = 0;
			return ret;
		},
	},
	methods: {
		async loadData(){
			let ret = await this.appModel.doGetOrderOutData(this.$route.params.id);
			if(ret.success){
				if(ret.exists){
					this.orderinfo = ret.orderinfo;
					this.clientinfo = ret.clientinfo;
					this.wheretheylearns = ret.wheretheylearns;
					this.materials = ret.materials;
					this.services = ret.services;
					this.historystatuses = ret.historystatuses;
					this.historypayments = ret.historypayments;
					
					this.tpl = 'info';
				} else {
					this.tpl = 'notexists';
				}
			}
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
		document.body.classList.add('top-navigation');
	},
	beforeUnmount(){
		document.body.classList.remove('top-navigation');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('top-navigation');
	},
	components: {
		Lightgallery,
	},
};
</script>