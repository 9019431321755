<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Добавление производимой работы</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Название производимой работы</label>
									<input type="text" ref="serviceName" placeholder="Название производимой работы" class="form-control" v-model="serviceName" />
								</div>
							</div>
						</div>
						<div class="row m-b-n">
							<div class="col-md-12 m-b text-center" v-if="loadingServices && services.length <= 0">
								<i class="fa fa-spinner fa-spin m-r-sm"></i> Поиск производимых работ...
							</div>
							<div class="col-md-12" :style="loadingServices ? {background: '#eee'} : {}" v-else>
								<a @click="onSelect(index)" class="list-group-item" :class="{'active': selectedService.id == service.id}" v-for="(service, index) in services" :key="service.id">
									<div class="m-l-md pull-right">
										<small>{{number_format(service.price)}} тг</small>
									</div>
									<span>{{service.name}}</span>
								</a>
								<a @click="onSelectAdd()" class="list-group-item" :class="{'active': selectedService.name == serviceName}" v-if="!loadingServices && serviceName != '' && hasUniqueName">
									<div class="pull-right">
										<i class="fa fa-plus"></i>
									</div>
									<span>{{serviceName}}</span>
								</a>
								<div class="text-center m-t m-b-sm" v-if="getPagesCount() > 1">
									<!--
									<div class="btn-group">
										<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
									</div>
									-->
									<div v-if="$device.isMobile">
										<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
											<i class="fa fa-angle-double-left"></i>
										</span>
										<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
											<i class="fa fa-chevron-left"></i>
										</span>
										<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
											<i class="fa fa-angle-double-right"></i>
										</span>
										<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
											<i class="fa fa-chevron-right"></i>
										</span>
										<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
									</div>
									<div class="btn-group" v-else>
										<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
											<i class="fa fa-chevron-left"></i>
										</span>
										<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
										<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
										<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
										<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
										<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
										<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
											<i class="fa fa-chevron-right"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="selectedService.name != ''">
							<div class="hr-line-dashed"></div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group" :class="{'m-b-none': !error}">
										<label>Кол-во <span class="text-danger">*</span></label>
										<input type="number" placeholder="Количество" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="selectedService.count" required />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group" :class="{'m-b-none': !error}">
										<label>Цена <span class="text-danger">*</span></label>
										<input type="number" placeholder="Цена" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="selectedService.price" required />
									</div>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading || selectedService.name == ''">Добавить в ремонт</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		instrument_id: null,
		
		serviceName: '',
		
		servicesTimeoutId: null,
		services: [],
		servicesCount: 0,
		
		selectedService: {
			id: null,
			name: '',
			count: 1,
			price: 0,
		},
		
		page: 1,
		limit: 10,
		error: '',
		loadingServices: false,
		loading: false,
		
		paginationAlpha: 3,
	}),
	computed: {
		hasUniqueName(){
			return this.services.filter((el) => el.name.toLowerCase() == this.serviceName.toLowerCase()).length <= 0;
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	watch: {
		serviceName(val, oldVal){
			this.selectedService = {
				id: null,
				name: '',
				count: 1,
				price: 0,
			};
			clearTimeout(this.servicesTimeoutId);
			this.servicesTimeoutId = setTimeout(async () => await this.loadData(), 500);
		},
	},
	methods: {
		range: lib.range,
		
		// выбрать существующую услугу
		onSelect(index){
			this.selectedService.id = this.services[index].id;
			this.selectedService.name = this.services[index].name;
			this.selectedService.price = this.services[index].price;
			this.selectedService.count = 1;
		},
		// добавить новую услугу в базу и выбрать ее
		async onSelectAdd(){
			this.selectedService.id = null;
			this.selectedService.name = this.serviceName;
			this.selectedService.price = '';
			this.selectedService.count = 1;
		},
		
		async onAdd(close){
			this.loading = true;
			this.error = '';
			let ret;
			if(this.selectedService.id == null){
				ret = await this.appModel.doAddService({
					instrument_id: this.instrument_id,
					name: this.selectedService.name,
					price: this.selectedService.price,
				});
				this.selectedService.id = ret.id;
			} else {
				let service = this.services.find((el) => el.id == this.selectedService.id);
				if(this.selectedService.price != service.price){
					ret = await this.appModel.doEditService(this.selectedService.id, {
						price: this.selectedService.price,
					});
				} else {
					ret = {
						success: true,
					};
				}
			}
			if(ret.success){
				this.$emit('add', this.selectedService, close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		async loadData(){
			this.loadingServices = true;
			this.error = '';
			let ret = await this.appModel.doGetListServices({
				instrument_id: this.instrument_id,
				search: this.serviceName,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			});
			if(ret.success){
				this.services = ret.services;
				this.servicesCount = ret.services_count;
			} else {
				this.error = ret.message;
			}
			this.loadingServices = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.servicesCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		this.instrument_id = this.$vfm.modals[0].params._value.instrument_id;
		
		await this.loadData();
		
		setTimeout(() => {
			this.$refs.serviceName.focus();
		}, 300);
	},
	components: {},
}
</script>