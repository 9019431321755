<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-10">
			<h2>Справочники</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/lists'}">
						<strong>Справочники</strong>
					</router-link>
				</li>
			</ol>
		</div>
		<div class="col-lg-2"></div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/services'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База производимых работ</h5>
						</div>
					</div>
				</router-link>
			</div>
		</div>
		<hr/>
		<div class="row">
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/instrument-names'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База инструментов</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/instrument-types'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База типов инструментов</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/instrument-sizes'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База размеров инструментов</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/case-names'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База кейсов</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/firms'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База фирм-производителей</h5>
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-lg-4">
				<router-link :to="{path: '/lists/where-they-learns'}">
					<div class="ibox float-e-margins">
						<div class="ibox-title">
							<h5>База "Откуда узнали о мастере"</h5>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Справочники | MasterSMI Учет'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>