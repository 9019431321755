<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-12">
			<h2>Калькулятор стоимости ремонта</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/orders'}">База ремонтов</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/orders/calc'}">
						<strong>Калькулятор стоимости ремонта</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox">
					<div class="ibox-content">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Инструмент <span class="text-danger">*</span></label>
									<select class="form-control" required v-model="instrument_id" @change="clearData">
										<option value="" disabled="disabled">Инструмент не выбран</option>
										<option :value="instrumentname.id" v-for="instrumentname in instrumentnames">{{instrumentname.name}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="ibox">
					<div class="ibox-title">
						<h5>Производимые работы</h5>
					</div>
					<div class="ibox-content">
						<div class="row">
							<div class="col-md-12">
								<button type="button" style="white-space: normal;" class="btn btn-info pull-right m-b" @click="onShowAddServiceModal">Добавить производимую работу</button>
							</div>
						</div>
						<div class="table-responsive">
							<table class="table table-hover m-b-none">
								<thead>
									<tr>
										<th width="55%">Производимая работа</th>
										<th width="10%" class="text-center">Кол-во</th>
										<th width="15%" class="text-center">Цена</th>
										<th width="15%" class="text-center">Сумма</th>
										<th width="5%">&nbsp;</th>
									</tr>
								</thead>
								<tbody class="order-services">
									<tr v-for="(service, index) in services" :key="service.id">
										<td style="vertical-align: middle;">{{service.name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{service.count}}</td>
										<td style="vertical-align: middle;" class="text-center">{{service.price}}</td>
										<td style="vertical-align: middle;" class="text-center">{{parseInt(service.price) * parseInt(service.count)}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<button type="button" class="btn btn-danger" @click="services.splice(index, 1)">
												<i class="fa fa-times"></i>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="ibox">
					<div class="ibox-title">
						<h5>Требуемые материалы</h5>
					</div>
					<div class="ibox-content">
						<div class="row">
							<div class="col-md-12">
								<button type="button" style="white-space: normal;" class="btn btn-info pull-right m-b" @click="onShowAddMaterialModal">Добавить требуемый материал</button>
							</div>
						</div>
						<div class="table-responsive">
							<table class="table table-hover m-b-none">
								<thead>
									<tr>
										<th width="55%">Материал</th>
										<th width="10%" class="text-center">Кол-во</th>
										<th width="15%" class="text-center">Цена</th>
										<th width="15%" class="text-center">Сумма</th>
										<th width="5%">&nbsp;</th>
									</tr>
								</thead>
								<tbody class="order-materials">
									<tr v-for="material in materials" :key="material.id">
										<td style="vertical-align: middle;">{{material.name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{material.count}}</td>
										<td style="vertical-align: middle;" class="text-center">{{material.price}}</td>
										<td style="vertical-align: middle;" class="text-center">{{parseInt(material.price) * parseInt(material.count)}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<button type="button" class="btn btn-danger" @click="materials.splice(index, 1)">
												<i class="fa fa-times"></i>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row m-t-lg">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content">
						<div class="row">
							<div class="col-md-4">
								<div class="form-group">
									<label>Стоимость услуг</label>
									<input type="text" class="form-control text-center" v-model="servicesCost" disabled="disabled" />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label>Стоимость материалов</label>
									<input type="text" class="form-control text-center" v-model="materialsCost" disabled="disabled" />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group has-error">
									<label>Сумма по документу</label>
									<input type="text" class="form-control text-center" :value="servicesCost + materialsCost" disabled="disabled" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import ServiceAddModal from '@/components/modals/Orders/ServiceAddModal.vue';
import MaterialAddModal from '@/components/modals/Orders/MaterialAddModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Калькулятор стоимости ремонта | MasterSMI Учет'});
	},
	data: () => ({
		instrumentnames: [],
		
		instrument_id: '',
		services: [],
		materials: [],
		
		loading: true,
		error: '',
	}),
	computed: {
		servicesCost(){
			return this.services.reduce((acc, el) => acc + (parseInt(el.price) * parseInt(el.count)), 0);
		},
		materialsCost(){
			return this.materials.reduce((acc, el) => acc + (parseInt(el.price) * parseInt(el.count)), 0);
		},
	},
	watch: {
		instrument_id(val, oldVal){
			this.error = '';
		},
	},
	methods: {
		onShowAddServiceModal(){
			if(this.instrument_id == ''){
				this.error = 'Выберите инструмент';
				return false;
			}
			let that = this;
			this.$vfm.show({
				component: ServiceAddModal,
				on: {
					add(selectedService, close){
						that.services.push(selectedService);
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					instrument_id: that.instrument_id,
				},
			});
		},
		onShowAddMaterialModal(){
			if(this.instrument_id == ''){
				this.error = 'Выберите инструмент';
				return false;
			}
			let that = this;
			this.$vfm.show({
				component: MaterialAddModal,
				on: {
					add(selectedMaterial, close){
						that.materials.push(selectedMaterial);
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					instrument_id: that.instrument_id,
				},
			});
		},
		
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames({});
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		clearData(){
			this.services = [];
			this.materials = [];
		},
	},
	async mounted(){
		await this.loadInstrumentNames();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>