<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-danger size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Удаление клиента</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="alert alert-info">{{fio}}</div>
						<div>Вы действительно хотите безвозвратно удалить данного клиента?</div>
						<div class="tabs-container m-t-lg">
							<ul class="nav nav-tabs">
								<li :class="{'active': action == 'only-delete'}">
									<a @click="action = 'only-delete'">Удалить</a>
								</li>
								<li :class="{'active': action == 'delete-orders'}">
									<a @click="action = 'delete-orders'">Удалить с ремонтами</a>
								</li>
								<li :class="{'active': action == 'delete-sales'}">
									<a @click="action = 'delete-sales'">Удалить с продажами</a>
								</li>
								<li :class="{'active': action == 'delete-orders-sales'}">
									<a @click="action = 'delete-orders-sales'">Удалить с ремонтами и продажами</a>
								</li>
							</ul>
							<div class="tab-content">
								<div class="tab-pane" :class="{'active': action == 'only-delete'}" v-show="action == 'only-delete'">
									<div class="panel-body">
										<div><span style="color: #900;">Внимание!</span> При удалении данного клиента, в ремонтах и продажах, где был выбран этот клиент, будет установлено значение клиента: "Клиент не выбран".</div>
									</div>
								</div>
								<div class="tab-pane" :class="{'active': action == 'delete-orders'}" v-show="action == 'delete-orders'">
									<div class="panel-body">
										<div><span style="color: #900;">Внимание!</span> При удалении данного клиента, будут также удалены ремонты, связанные с ним. В продажах, где был выбран этот клиент, будет установлено значение клиента: "Клиент не выбран".</div>
									</div>
								</div>
								<div class="tab-pane" :class="{'active': action == 'delete-sales'}" v-show="action == 'delete-sales'">
									<div class="panel-body">
										<div><span style="color: #900;">Внимание!</span> При удалении данного клиента, будут также удалены продажи, связанные с ним. В ремонтах, где был выбран этот клиент, будет установлено значение клиента: "Клиент не выбран".</div>
									</div>
								</div>
								<div class="tab-pane" :class="{'active': action == 'delete-orders-sales'}" v-show="action == 'delete-orders-sales'">
									<div class="panel-body">
										<div><span style="color: #900;">Внимание!</span> При удалении данного клиента, будут также удалены ремонты и продажи, связанные с ним.</div>
									</div>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-danger" :disabled="loading" @click="onDelete(close)">Удалить</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		action: 'only-delete',
		
		fio: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onDelete(close){
			this.loading = true;
			this.error = '';
			let ret = {
				success: false,
				message: 'unknown method',
			};
			if(this.action == 'only-delete'){
				ret = await this.appModel.doDeleteClient(this.$vfm.modals[0].params._value.client.id);
			} else if(this.action == 'delete-orders'){
				ret = await this.appModel.doDeleteClientWithOrders(this.$vfm.modals[0].params._value.client.id);
			} else if(this.action == 'delete-sales'){
				ret = await this.appModel.doDeleteClientWithSales(this.$vfm.modals[0].params._value.client.id);
			} else if(this.action == 'delete-orders-sales'){
				ret = await this.appModel.doDeleteClientWithOrdersAndSales(this.$vfm.modals[0].params._value.client.id);
			}
			if(ret.success){
				this.$emit('delete', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	mounted(){
		this.fio = this.$vfm.modals[0].params._value.client.fio;
	},
}
</script>