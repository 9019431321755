<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-md-12">
			<h2>База ремонтов</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/orders'}">
						<strong>База ремонтов</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-1 col-md-push-8">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content hidden-xs" style="padding: 6px;">
						<router-link :to="{path: '/orders/calc'}" style="white-space: normal;" class="btn btn-block btn-info" content="Калькулятор стоимости ремонта" v-tippy="{placement: 'top'}">
							<i class="fa fa-calculator"></i>
						</router-link>
					</div>
					<div class="ibox-content visible-xs" style="padding: 6px 10px;">
						<router-link :to="{path: '/orders/calc'}" style="white-space: normal;" class="btn btn-block btn-info">
							Калькулятор стоимости ремонта
						</router-link>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-md-push-8">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<button type="button" style="white-space: normal;" class="btn btn-block btn-info" @click="onShowAddModal">Добавить ремонт</button>
					</div>
				</div>
			</div>
			<div class="col-md-8 col-md-pull-4">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по ремонтам (серийн., цвет, модель, опис. неиспр., компл-ть, коммент., др.)" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-8">
				<div class="ibox" :class="{'collapsed': !filterShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Фильтр <small class="badge m-l-xs">{{filter.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="filterShow = !filterShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="filterShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(fi, index) in filter">
								<label class="col-sm-3 control-label">{{getStructParam(fi.field).label}}</label>
								<div class="col-sm-2" :class="!$device.isMobile ? {paddingRight: 0} : {}">
									<select class="form-control" v-model="fi.operator">
										<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
									</select>
								</div>
								<div class="col-sm-6" :class="!$device.isMobile ? {paddingLeft: 0} : {}">
									<input type="text" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
									<input type="number" class="form-control" :class="{'m-t': $device.isMobile}" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
									<input type="date" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
									<input type="datetime-local" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
									<input type="email" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
									<select class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
										<option value=""></option>
										<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Фильтр по</label>
								<div class="col-sm-8">
									<select class="form-control" v-model="filterAddField" @change="onAddFilterParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<button type="button" class="btn btn-info" :class="{'btn-block m-t': $device.isMobile}" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
										<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="ibox" :class="{'collapsed': !sortShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Сортировка <small class="badge m-l-xs">{{sort.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="sortShow = !sortShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="sortShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(si, index) in sort">
								<label class="col-sm-6 control-label">{{getStructParam(si.field).label}}</label>
								<div class="col-sm-4">
									<select class="form-control" v-model="si.direction" @change="page = 1; loadData();">
										<option value="asc">а-я</option>
										<option value="desc">я-а</option>
									</select>
								</div>
								<div class="col-sm-2" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelSortParam(index)" content="Удалить параметр сортировки" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-6 control-label">Сортировка по</label>
								<div class="col-sm-6">
									<select class="form-control" v-model="sortAddField" @change="onAddSortParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsSort">{{elem.label}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content" :style="loading ? {background: '#eee'} : {}">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="order in orders" :key="order.id">
								<router-link :to="{path: '/orders/'+order.id}">
									<div class="contact-box" :class="{'bg-warning': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">
										<div class="col-sm-12">
											<div class="row m-t-xs" v-if="order.dateend != '0000-00-00 00:00:00'">
												<div class="col-xs-4">
													<b>Дата заверш.:</b>
												</div>
												<div class="col-xs-8">{{formatDate(order.dateend, 'DD.MM.YYYY')}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Дата начала:</b>
												</div>
												<div class="col-xs-8">
													<div>{{formatDate(order.dateadd, 'DD.MM.YYYY')}}</div>
													<div>({{getDateDuration(order)}})</div>
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Статус:</b>
												</div>
												<div class="col-xs-8">{{order.status_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Клиент:</b>
												</div>
												<div class="col-xs-8">{{order.client_fio}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<span class="hidden-xs"><b>Инструмент:</b></span>
													<span class="visible-xs"><b>Инстр.:</b></span>
												</div>
												<div class="col-xs-8">{{order.instrument_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Тип:</b>
												</div>
												<div class="col-xs-8">{{order.instrument_type_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Размер:</b>
												</div>
												<div class="col-xs-8">{{order.instrument_size_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Фирма:</b>
												</div>
												<div class="col-xs-8">{{order.instrument_firm_name}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Сумма:</b>
												</div>
												<div class="col-xs-8">{{number_format(order.fullamount)}}</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Внесено:</b>
												</div>
												<div class="col-xs-8" :class="{'text-danger': order.amount_payed < order.fullamount && order.status_id != 6}">{{number_format(order.amount_payed)}}</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none">
								<thead>
									<tr>
										<th width="100" style="vertical-align: middle;" class="text-center">Дата</th>
										<th width="120" style="vertical-align: middle;" class="text-center">Статус</th>
										<th style="vertical-align: middle;">Клиент</th>
										<th style="vertical-align: middle;">Инструмент, тип (размер), фирма</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Сумма</th>
										<th width="100" style="vertical-align: middle;" class="text-center">Внесено</th>
										<th width="100" class="text-right" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="$device.isTablet">
									<tr @click="$router.push('/orders/'+order.id)" v-for="order in orders" :key="order.id" :class="{'bg-warning': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">
										<td style="vertical-align: middle;" class="text-center">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="order.dateend != '0000-00-00 00:00:00'">{{formatDate(order.dateend, 'DD.MM.YYYY')}}</div>
											<small>({{getDateDuration(order)}})</small>
											<div content="Дата начала" v-tippy="{placement: 'bottom'}">{{formatDate(order.dateadd, 'DD.MM.YYYY')}}</div>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{order.status_name}}</td>
										<td style="vertical-align: middle;">{{order.client_fio}}</td>
										<td style="vertical-align: middle;">{{order.instrument_name}}, {{order.instrument_type_name}} ({{order.instrument_size_name}}), {{order.instrument_firm_name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(order.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">{{number_format(order.amount_payed)}}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="order in orders" :key="order.id" :class="{'bg-warning': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">
										<td style="vertical-align: middle;" class="text-center">
											<div content="Дата завершения" v-tippy="{placement: 'top'}" v-if="order.dateend != '0000-00-00 00:00:00'">{{formatDate(order.dateend, 'DD.MM.YYYY')}}</div>
											<small>({{getDateDuration(order)}})</small>
											<div content="Дата начала" v-tippy="{placement: 'bottom'}">{{formatDate(order.dateadd, 'DD.MM.YYYY')}}</div>
										</td>
										<td style="vertical-align: middle;" class="text-center">{{order.status_name}}</td>
										<td style="vertical-align: middle;">{{order.client_fio}}</td>
										<td style="vertical-align: middle;">{{order.instrument_name}}, {{order.instrument_type_name}} ({{order.instrument_size_name}}), {{order.instrument_firm_name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(order.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-center" :class="{'text-danger': order.amount_payed < order.fullamount && order.status_id != 6 && !loading}">{{number_format(order.amount_payed)}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/orders/'+order.id}" style="margin-bottom: 0;" class="btn btn-default">Подробнее</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Ремонтов:</b> {{number_format(ordersCount)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import moment from 'moment-timezone';

import OrderAddModal from '@/components/modals/Orders/OrderAddModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'База ремонтов | MasterSMI Учет'});
	},
	data: () => ({
		structParams: [],
		
		ordersStatuses: [],
		instrumentnames: [],
		firms: [],
		casenames: [],
		
		orders: [],
		ordersCount: 0,
		
		search: '',
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		sort: [
			{field: 'lai.status_id', direction: 'asc'},
			{field: 'lai.dateadd', direction: 'desc'},
		],
		sortAddField: '',
		sortShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		structParamsSort(){
			return this.structParams.filter((el) => el.sort && !this.sort.map(el => el.field).includes(el.field));
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		// добавление ремонта
		onShowAddModal(){
			let that = this;
			this.$vfm.show({
				component: OrderAddModal,
				on: {
					add(order_ids, close){
						that.loadData();
						close();
					},
					addGoto(order_ids, close){
						if(order_ids.length > 1){
							that.filter = [];
							for(let id of order_ids){
								that.filter.push({
									field: 'lai.id',
									operator: '=',
									value: id,
								});
							}
							that.loadData();
						} else if(order_ids.length == 1){
							that.$router.push({path: '/orders/' + order_ids[0]});
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {},
			});
		},
		
		async loadOrdersStatuses(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetOrdersStatuses();
			if(ret.success){
				this.ordersStatuses = ret.orders_statuses;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadFirms(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListFirms();
			if(ret.success){
				this.firms = ret.firms;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadCaseNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListCaseNames();
			if(ret.success){
				this.casenames = ret.casenames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				search: this.search,
				filter: JSON.stringify(this.filter),
				sort: JSON.stringify(this.sort),
				page: this.page,
			})});
			let params = {
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			for(let [index, el] of Object.entries(this.sort)){
				params['sort['+index+'][field]'] = el.field;
				params['sort['+index+'][direction]'] = el.direction;
			}
			let ret = await this.appModel.doGetListOrders(params);
			if(ret.success){
				this.orders = ret.orders;
				this.ordersCount = ret.orders_count;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		onAddSortParam(){
			if(this.sortAddField != ''){
				this.sort.push({
					field: this.sortAddField,
					direction: 'asc',
				});
				this.sortAddField = '';
				this.page = 1;
				this.loadData();
			}
		},
		onDelSortParam(index){
			this.sort.splice(index, 1);
			this.page = 1;
			this.loadData();
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.ordersCount / this.limit);
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadOrdersStatuses();
		await this.loadInstrumentNames();
		await this.loadFirms();
		await this.loadCaseNames();
		
		this.structParams = [
			{
				type: 'number',
				field: 'lai.id',
				label: 'ID ремонта',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.client_id',
				label: 'ID клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'input',
				field: 'lcl.fio',
				label: 'ФИО клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.status_id',
				label: 'Статус ремонта',
				options: this.ordersStatuses.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.instrument_id',
				label: 'Инструмент',
				options: this.instrumentnames.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.instrument_type_id',
				label: 'Тип инструмента',
				filter: false,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.instrument_size_id',
				label: 'Размер инструмента',
				filter: false,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.instrument_firm_id',
				label: 'Фирма инструмента',
				options: this.firms.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'lai.casename_id',
				label: 'Кейс',
				options: this.casenames.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_serial_number',
				label: 'Серийный номер инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_color',
				label: 'Цвет инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_model',
				label: 'Модель инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_paid',
				label: 'Предполагаемая сумма оплаты',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_services',
				label: 'Сумма за услуги',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_materials',
				label: 'Сумма за материалы',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.discount',
				label: 'Сумма скидки',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.fullamount',
				label: 'Общая сумма',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_payed',
				label: 'Внесенная сумма',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateadd',
				label: 'Дата добавления (начала)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateend',
				label: 'Дата завершения',
				filter: true,
				sort: true,
			},
		];
		
		if(this.$route.query.search) this.search = this.$route.query.search;
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		if(this.$route.query.sort) this.sort = JSON.parse(this.$route.query.sort || '[]');
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		window.scrollTo(0, 0);
		next();
	},
	components: {},
};
</script>