<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-10">
			<h2>Отчёт по оказанным услугам (за всё время)</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/reports'}">Отчёты</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/reports/services'}">
						<strong>Отчёт по оказанным услугам (за всё время)</strong>
					</router-link>
				</li>
			</ol>
		</div>
		<div class="col-lg-2"></div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<select class="form-control" v-model="instrument_id" @change="page = 1; loadData();">
							<option :value="instrumentname.id" v-for="instrumentname in instrumentnames" :key="instrumentname.id">{{instrumentname.name}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" style="margin-bottom: 10px;">
					<div class="ibox-content" style="padding: 6px 10px;">
						<form role="form" @submit.prevent="page = 1; loadData();">
							<div class="input-group">
								<input type="text" v-model="search" class="form-control" placeholder="Поиск по названию производимых работ" />
								<div class="input-group-btn">
									<button type="submit" class="btn btn-info" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}">
										<i class="fa" :class="{'fa-search': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox" :class="{'collapsed': !filterShow}" style="margin-bottom: 10px;">
					<div class="ibox-title">
						<h5>Фильтр <small class="badge m-l-xs">{{filter.length}}</small></h5>
						<div class="ibox-tools">
							<a class="collapse-link" @click="filterShow = !filterShow">
								<i class="fa fa-chevron-up"></i>
							</a>
						</div>
					</div>
					<div class="ibox-content" style="padding-top: 0;" v-if="filterShow">
						<div class="form-horizontal">
							<div class="form-group m-b-none m-t" v-for="(fi, index) in filter">
								<label class="col-sm-3 control-label">{{getStructParam(fi.field).label}}</label>
								<div class="col-sm-2" :class="!$device.isMobile ? {paddingRight: 0} : {}">
									<select class="form-control" v-model="fi.operator">
										<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
									</select>
								</div>
								<div class="col-sm-6" :class="!$device.isMobile ? {paddingLeft: 0} : {}">
									<input type="text" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
									<input type="number" class="form-control" :class="{'m-t': $device.isMobile}" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
									<input type="date" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
									<input type="datetime-local" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
									<input type="email" class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
									<select class="form-control" :class="{'m-t': $device.isMobile}" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
										<option value=""></option>
										<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
										<i class="fa fa-times"></i>
									</div>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Фильтр по</label>
								<div class="col-sm-8">
									<select class="form-control" v-model="filterAddField" @change="onAddFilterParam">
										<option value="" disabled="disabled"></option>
										<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
									</select>
								</div>
								<div class="col-sm-1" :class="!$device.isMobile ? {padding: 0} : {}">
									<button type="button" class="btn btn-info" :class="{'btn-block m-t': $device.isMobile}" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
										<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
									</button>
								</div>
							</div>
							<div class="form-group m-b-none m-t">
								<label class="col-sm-3 control-label">Показывать не оказанные услуги</label>
								<div class="col-sm-9">
									<div class="btn-group">
										<button class="btn" :class="{'btn-primary': showCount0, 'btn-white': !showCount0}" type="button" @click="showCount0 = true">Да</button>
										<button class="btn" :class="{'btn-primary': !showCount0, 'btn-white': showCount0}" type="button" @click="showCount0 = false">Нет</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning alert-dismissable m-b-sm" v-if="error">
			<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
			{{error}}
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="ibox float-e-margins">
					<div class="ibox-content" :style="loading ? {background: '#eee'} : {}">
						<div class="row grid" v-if="$device.isMobile">
							<div class="col-md-4 grid-item" v-for="inel in servicesf" :key="inel.id">
								<router-link :to="{path: '/orders', query: (inel.count > 0 ? {filter: JSON.stringify(inel.orders_id.map((el) => ({
									field: 'lai.id',
									operator: '=',
									value: el
								})))} : {})}">
									<div class="contact-box">
										<div class="col-sm-12">
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Инструмент:</b>
												</div>
												<div class="col-xs-8">
													{{inel.instrument_name}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Услуга:</b>
												</div>
												<div class="col-xs-8">
													{{inel.name}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Ремонтов:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.orders_count)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Количество:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.count)}}
												</div>
											</div>
											<div class="row m-t-xs">
												<div class="col-xs-4">
													<b>Сумма:</b>
												</div>
												<div class="col-xs-8">
													{{number_format(inel.fullamount)}}
												</div>
											</div>
										</div>
										<div class="clearfix"></div>
									</div>
								</router-link>
							</div>
						</div>
						<div class="table-responsive" v-else>
							<table class="table table-hover m-b-none table-orders">
								<thead>
									<tr>
										<th class="text-center">Инструмент</th>
										<th>Услуга</th>
										<th width="100" class="text-center">Ремонтов</th>
										<th width="100" class="text-center">Количество</th>
										<th width="150" class="text-center">Сумма</th>
										<th class="text-right" width="100" v-if="!$device.isTablet">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="$device.isTablet">
									<tr @click="$router.push({path: '/orders', query: (inel.count > 0 ? {filter: JSON.stringify(inel.orders_id.map((el) => ({
										field: 'lai.id',
										operator: '=',
										value: el
									})))} : {})})" v-for="inel in servicesf" :key="inel.id">
										<td style="vertical-align: middle;" class="text-center">{{inel.instrument_name}}</td>
										<td style="vertical-align: middle;">{{inel.name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.orders_count)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.count)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.fullamount)}}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="inel in servicesf" :key="inel.id">
										<td style="vertical-align: middle;" class="text-center">{{inel.instrument_name}}</td>
										<td style="vertical-align: middle;">{{inel.name}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.orders_count)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.count)}}</td>
										<td style="vertical-align: middle;" class="text-center">{{number_format(inel.fullamount)}}</td>
										<td style="vertical-align: middle;" class="text-right">
											<router-link :to="{path: '/orders', query: (inel.count > 0 ? {filter: JSON.stringify(inel.orders_id.map((el) => ({
												field: 'lai.id',
												operator: '=',
												value: el
											})))} : {})}" style="margin-bottom: 0;" class="btn btn-default">
												<i class="fa fa-chevron-right"></i>	
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="hr-line-dashed"></div>
				<div class="text-center m-b-lg">
					<!--
					<div class="btn-group">
						<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
					</div>
					-->
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<i class="fa fa-angle-double-left"></i>
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-angle-double-right"></i>
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<i class="fa fa-chevron-left"></i>
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<i class="fa fa-chevron-right"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer fixed">
		<div class="row">
			<div class="col-md-12 text-center">
				<b>Услуг:</b> {{number_format(servicesCount)}}, <b>Итого:</b> {{number_format(servicesSum)}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчёт по оказанным услугам (за всё время) | MasterSMI Учет'});
	},
	data: () => ({
		structParams: [],
		
		instrumentnames: [],
		
		showCount0: false,
		
		services: [],
		servicesCount: 0,
		servicesSum: 0,
		
		instrument_id: '',
		search: '',
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		servicesf(){
			if(this.showCount0) return this.services;
			else return this.services.filter((el) => el.count > 0);
		},
		
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
				this.instrumentnames.unshift({
					id: '',
					name: 'Все',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				instrument_id: this.instrument_id,
				search: this.search,
				filter: JSON.stringify(this.filter),
				page: this.page,
			})});
			let params = {
				instrument_id: this.instrument_id,
				search: this.search,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			let ret = await this.appModel.doGetReportServices(params);
			if(ret.success){
				this.services = ret.services;
				this.servicesCount = ret.services_count;
				this.servicesSum = ret.services_sum;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.servicesCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		await this.loadInstrumentNames();
		
		this.structParams = [
			{
				type: 'date',
				field: 'lai.dateadd',
				label: 'Дата добавления (начала)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateend',
				label: 'Дата завершения',
				filter: true,
				sort: true,
			},
		];
		
		if(this.$route.query.instrument_id) this.instrument_id = this.$route.query.instrument_id;
		if(this.$route.query.search) this.search = this.$route.query.search;
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		
		await this.loadData();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
	},
};
</script>