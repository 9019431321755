<template>
	<div class="middle-box text-center animated fadeInDown">
        <h1>404</h1>
        <h3 class="font-bold">Страница не найдена</h3>

        <div class="error-desc">
            Страница, которую вы ищете, не найдена. Попробуйте проверить URL-адрес на наличие ошибок, затем нажмите кнопку обновления страницы в браузере или перейдите на главную страницу.
            <div class="m-t-lg">
				<router-link :to="{path: '/'}" class="btn btn-primary waves-effect waves-light">На главную</router-link>
			</div>
        </div>
    </div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Страница не найдена | MasterSMI Учет'});
	},
	methods: {
		
	},
	mounted(){
		
	},
	beforeMount(){
		window.scrollTo(0, 0);
		document.body.classList.add('gray-bg');
	},
	beforeUnmount(){
		document.body.classList.remove('gray-bg');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('gray-bg');
	},
};
</script>