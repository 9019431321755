<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-danger size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Удаление файла</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="text-center m-b-lg">
							<div class="file-box">
								<div class="file">
									<div class="file-pre image" style="height: auto;" v-if="contains(file.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)">
										<img alt="image" class="img-responsive" :src="file.url" />
									</div>
									<div class="file-pre icon" v-else-if="contains(file.extension, ['mp3', 'amr', 'wav', 'ogg', 'm4a'], true)">
										<i class="fa fa-music"></i>
									</div>
									<div class="file-pre icon" v-else-if="contains(file.extension, ['mp4', 'avi', 'wmv', 'mov', 'mkv', 'mpg', 'mpeg', 'mpeg4', '3gp', 'webm', 'ogv', 'm4v'], true)">
										<i class="fa fa-film"></i>
									</div>
									<div class="file-pre icon" v-else>
										<i class="fa fa-file"></i>
									</div>
									<div class="file-name">
										<div class="file-descr" v-if="file.descr">{{file.descr}}</div>
										<div class="file-extension">{{file.extension}}</div>
										<div class="file-date">{{formatDate(file.ugmtime_add, 'DD.MM.YYYY HH:mm')}}</div>
									</div>
								</div>
							</div>
						</div>
						<div>Вы действительно хотите безвозвратно удалить данный файл из данной продажи?</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-danger" :disabled="loading">Удалить</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>
.file-box {
	float: none;
	display: inline-block;
	text-align: center;
	width: 256px;
}
.file {
	margin: 10px;
}
.file .file-pre {
	cursor: pointer;
}
</style>

<script>
import lib from '@/lib';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		file: {
			id: null,
			sale_id: null,
			pkid: null,
			descr: '',
			extension: '',
			ugmtime_add: '',
			url: '',
		},
		
		error: '',
		loading: false,
	}),
	methods: {
		contains: lib.contains,
		
		async onDelete(close){
			this.$emit('delete', close);
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	mounted(){
		this.file = this.$vfm.modals[0].params._value.file;
	},
}
</script>