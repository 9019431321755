<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-sm" :click-to-close="false">
		<div class="modal-content">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Выберите номер для отправки</div>
				</div>
			</div>
			<div class="modal-body no-padding">
				<div class="list-group m-b-none">
					<a @click="onSelect(mobtel, close)" class="list-group-item text-center" v-for="mobtel in mobtels">{{mobtel}}</a>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</div>
	</vue-final-modal>
</template>

<style lang="scss" scoped>
.list-group-item {
	padding: 15px;
    font-size: 16px;
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		mobtels: [],
	}),
	computed: {},
	watch: {},
	methods: {
		onSelect(mobtel, close){
			this.$emit('select', mobtel, close);
		},
	},
	mounted(){
		this.mobtels = this.$vfm.modals[0].params._value.mobtels || [];
	},
	components: {},
}
</script>