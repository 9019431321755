// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lightgallery.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-rotate.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-pager.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-thumbnail.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js!../../assets/css/lg-thumbnail.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-transitions.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-video.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/lightgallery/css/lg-zoom.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bigUploadContainer[data-v-237c151c]{max-width:350px;padding:20px;margin:0 auto;background-color:#fff;border:1px solid #e5e5e5;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-box-shadow:0 1px 2px rgba(0,0,0,.05);-webkit-box-shadow:0 1px 2px rgba(0,0,0,.05);box-shadow:0 1px 2px rgba(0,0,0,.05);text-align:center}.file-box[data-v-237c151c]{float:none;display:inline-block;width:256px}.file[data-v-237c151c]{margin:10px}.file .file-pre[data-v-237c151c]{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/pages/SalesDetail.vue"],"names":[],"mappings":"AAUA,qCACC,eAAA,CACA,YAAA,CACA,aAAA,CACA,qBAAA,CACA,wBAAA,CACA,yBAAA,CACA,sBAAA,CACA,iBAAA,CACA,yCAAA,CACA,4CAAA,CACA,oCAAA,CACA,iBAAA,CAGD,2BACC,UAAA,CACA,oBAAA,CACA,WAAA,CAED,uBACC,WAAA,CAED,iCACC,cAAA","sourcesContent":["\r\n@import 'lightgallery/css/lightgallery.css';\r\n@import 'lightgallery/css/lg-rotate.css';\r\n@import 'lightgallery/css/lg-pager.css';\r\n@import 'lightgallery/css/lg-thumbnail.css';\r\n@import '@/assets/css/lg-thumbnail.css';\r\n@import 'lightgallery/css/lg-transitions.css';\r\n@import 'lightgallery/css/lg-video.css';\r\n@import 'lightgallery/css/lg-zoom.css';\r\n\r\n.bigUploadContainer {\r\n\tmax-width: 350px;\r\n\tpadding: 20px;\r\n\tmargin: 0 auto;\r\n\tbackground-color: #fff;\r\n\tborder: 1px solid #e5e5e5;\r\n\t-webkit-border-radius: 5px;\r\n\t-moz-border-radius: 5px;\r\n\tborder-radius: 5px;\r\n\t-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);\r\n\t-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);\r\n\tbox-shadow: 0 1px 2px rgba(0,0,0,.05);\r\n\ttext-align: center;\r\n}\r\n\r\n.file-box {\r\n\tfloat: none;\r\n\tdisplay: inline-block;\r\n\twidth: 256px;\r\n}\r\n.file {\r\n\tmargin: 10px;\r\n}\r\n.file .file-pre {\r\n\tcursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
