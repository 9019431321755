<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-danger size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Удаление размера инструмента</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="alert alert-info">{{instrumentSize}}</div>
						<div>Вы действительно хотите безвозвратно удалить данный размер инструмента?</div>
						<div class="m-t"><span style="color: #900;">Внимание!</span> При удалении данного размера инструмента, в ремонтах, где был выбран этот размер инструмента, будет установлено значение размера инструмента: "Размер инструмента не выбран"</div>
						<div class="alert alert-warning alert-dismissable m-t m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-danger" :disabled="loading">Удалить</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		instrumentSize: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onDelete(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doDeleteInstrumentSize(this.$vfm.modals[0].params._value.instrumentSize.id);
			if(ret.success){
				this.$emit('delete', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	mounted(){
		this.instrumentSize = this.$vfm.modals[0].params._value.instrumentSize.size;
	},
}
</script>