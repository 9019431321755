<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-sm" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Изменение производимой работы</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row">
							<div class="col-md-12">
								<div class="alert alert-info text-center">{{name}}</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Цена <span class="text-danger">*</span></label>
									<input type="number" ref="price" class="form-control text-center" v-model.number="price" @wheel="$event.target.blur()" required />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Кол-во <span class="text-danger">*</span></label>
									<input type="number" ref="count" class="form-control text-center" v-model.number="count" @wheel="$event.target.blur()" required />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Сумма</label>
									<input type="text" ref="surrcost" class="form-control text-center" :value="number_format(fullamount)" readonly />
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		material: {},
		name: '',
		price: '',
		count: 1,
		
		error: '',
		loading: false,
	}),
	computed: {
		fullamount(){
			return this.price * this.count;
		},
	},
	watch: {},
	methods: {
		async onEdit(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetMaterial(this.material.material_id);
			if(ret.success){
				let basematerial = ret.material;
				if(this.price != basematerial.price){
					ret = await this.appModel.doEditMaterial(this.material.material_id, {
						price: this.price,
					});
				} else {
					ret = {
						success: true,
					};
				}
				if(ret.success){
					ret = await this.appModel.doEditMaterialOrder(
						this.$vfm.modals[0].params._value.order_id,
						this.$vfm.modals[0].params._value.order_material_id,
						{
							price: this.price,
							count: this.count,
						}
					);
					if(ret.success){
						this.$emit('edit', close);
					} else {
						this.error = ret.message;
					}
				} else {
					this.error = ret.message;
				}
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	mounted(){
		this.material = this.$vfm.modals[0].params._value.material || {};
		this.name = this.material.name || '';
		this.price = this.material.price || 0;
		this.count = this.material.count || 1;
		
		setTimeout(() => {
			this.$refs.price.focus();
		}, 300);
	},
	components: {},
}
</script>