<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Добавление товара</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group m-b-none">
									<label>Название товара</label>
									<input type="text" ref="goodsitemName" placeholder="Название товара" class="form-control" v-model="goodsitemName" />
								</div>
							</div>
						</div>
						<div class="row m-b-n">
							<div class="col-md-12 m-t m-b text-center" v-if="loadingGoods && goods.length <= 0">
								<i class="fa fa-spinner fa-spin m-r-sm"></i> Поиск товаров...
							</div>
							<div class="col-md-12 m-t" :style="loadingGoods ? {background: '#eee'} : {}" v-else>
								<table class="table table-hover">
									<thead v-if="(goodsitemName != '' && hasUniqueName) || goods.length > 0">
										<th width="70">&nbsp;</th>
										<th>Товар</th>
										<th width="100" style="min-width: 100px;" class="text-center">Цена</th>
									</thead>
									<tbody style="border: 1px solid #e5e6e7;">
										<tr v-for="(goodsitem, index) in goods" :key="goodsitem.id" @click="onSelect(index)" :class="{'active': selectedGoodsitem.id == goodsitem.id}" style="cursor: pointer;">
											<td style="vertical-align: middle;">
												<img :src="getPoster(goodsitem.images).urlSmall" style="width: 50px; height: 50px;" />
											</td>
											<td style="vertical-align: middle;">{{goodsitem.name}}</td>
											<td style="vertical-align: middle;" class="text-center">
												{{number_format(goodsitem.price)}} тг
											</td>
										</tr>
										<tr @click="onSelectAdd()" :class="{'active': selectedGoodsitem.name == goodsitemName}" v-if="!loadingGoods && goodsitemName != '' && hasUniqueName" style="cursor: pointer;">
											<td style="vertical-align: middle;" class="text-center">
												<i class="fa fa-3x fa-plus"></i>
											</td>
											<td style="vertical-align: middle;" colspan="2">{{goodsitemName}}</td>
										</tr>
									</tbody>
								</table>
								<!--
								<a @click="onSelectAdd()" class="list-group-item" :class="{'active': selectedGoodsitem.name == goodsitemName}" v-if="!loadingGoods && goodsitemName != '' && hasUniqueName">
									<div class="pull-right">
										<i class="fa fa-plus"></i>
									</div>
									<span>{{goodsitemName}}</span>
								</a>
								<a @click="onSelect(index)" class="list-group-item" :class="{'active': selectedGoodsitem.id == goodsitem.id}" v-for="(goodsitem, index) in goods" :key="goodsitem.id">
									<div class="m-l-md pull-right">
										<small>{{number_format(goodsitem.price)}} тг</small>
									</div>
									<span>{{goodsitem.name}}</span>
								</a>
								-->
								<div class="text-center m-t m-b-sm" v-if="getPagesCount() > 1">
									<!--
									<div class="btn-group">
										<a class="btn btn-white" :class="{'active': page == p}" href="javascript://" @click="page = p; loadData();" v-for="p in getPagesCount()">{{p}}</a>						
									</div>
									-->
									<div v-if="$device.isMobile">
										<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
											<i class="fa fa-angle-double-left"></i>
										</span>
										<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
											<i class="fa fa-chevron-left"></i>
										</span>
										<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
											<i class="fa fa-angle-double-right"></i>
										</span>
										<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
											<i class="fa fa-chevron-right"></i>
										</span>
										<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
									</div>
									<div class="btn-group" v-else>
										<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
											<i class="fa fa-chevron-left"></i>
										</span>
										<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
										<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
										<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
										<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
										<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
										<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
											<i class="fa fa-chevron-right"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="selectedGoodsitem.name != ''">
							<div class="hr-line-dashed"></div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group" :class="{'m-b-none': !error}">
										<label>Кол-во <span class="text-danger">*</span></label>
										<input type="number" placeholder="Количество" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="selectedGoodsitem.count" required />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group" :class="{'m-b-none': !error}">
										<label>Цена <span class="text-danger">*</span></label>
										<input type="number" placeholder="Цена" class="form-control text-center" @wheel="$event.target.blur()" v-model.number="selectedGoodsitem.price" required />
									</div>
								</div>
							</div>
						</div>
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading || selectedGoodsitem.name == ''">Добавить в продажу</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import moment from 'moment-timezone';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		goodsitemName: '',
		
		goodsTimeoutId: null,
		goods: [],
		goodsCount: 0,
		
		selectedGoodsitem: {
			id: null,
			name: '',
			count: 1,
			price: 0,
			images: [],
		},
		
		page: 1,
		limit: 10,
		error: '',
		loadingGoods: false,
		loading: false,
		
		paginationAlpha: 3,
	}),
	computed: {
		hasUniqueName(){
			return this.goods.filter((el) => el.name.toLowerCase() == this.goodsitemName.toLowerCase()).length <= 0;
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	watch: {
		async goodsitemName(val, oldVal){
			this.selectedGoodsitem = {
				id: null,
				name: '',
				count: 1,
				price: 0,
				images: [],
			};
			clearTimeout(this.goodsTimeoutId);
			this.goodsTimeoutId = setTimeout(async () => await this.loadData(), 500);
		},
	},
	methods: {
		range: lib.range,
		
		getPoster(images){
			return images.find((el) => el.poster);
		},
		getImages(images){
			return images.filter((el) => !el.poster);
		},
		
		// выбрать существующий товар
		onSelect(index){
			this.selectedGoodsitem.id = this.goods[index].id;
			this.selectedGoodsitem.name = this.goods[index].name;
			this.selectedGoodsitem.price = this.goods[index].price;
			this.selectedGoodsitem.count = 1;
			this.selectedGoodsitem.images = this.goods[index].images;
		},
		// добавить новый товар в базу и выбрать его
		async onSelectAdd(){
			this.selectedGoodsitem.id = null;
			this.selectedGoodsitem.name = this.goodsitemName;
			this.selectedGoodsitem.price = '';
			this.selectedGoodsitem.count = 1;
			this.selectedGoodsitem.images = [{
				id: null,
				pkid: null,
				poster: 1,
				url: '/assets/img/no-image.png',
				urlSmall: '/assets/img/no-image.png',
				descr: '',
				ugmtime_add: this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
			}];
		},
		
		async onAdd(close){
			this.loading = true;
			this.error = '';
			let ret;
			if(this.selectedGoodsitem.id == null){
				ret = await this.appModel.doAddGoodsItem({
					name: this.selectedGoodsitem.name,
					count: 0,
					price: this.selectedGoodsitem.price,
				});
				this.selectedGoodsitem.id = ret.id;
			} else {
				let goodsitem = this.goods.find((el) => el.id == this.selectedGoodsitem.id);
				if(this.selectedGoodsitem.price != goodsitem.price){
					ret = await this.appModel.doEditGoodsItem(this.selectedGoodsitem.id, {
						price: this.selectedGoodsitem.price,
					});
				} else {
					ret = {
						success: true,
					};
				}
			}
			if(ret.success){
				this.$emit('add', this.selectedGoodsitem, close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		async loadData(){
			this.loadingGoods = true;
			this.error = '';
			let params = {
				instrument_id: this.instrument_id,
				search: this.goodsitemName,
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			let filter = [
				{field: 'lg.count', operator: '>', value: 0},
			];
			for(let [index, el] of Object.entries(filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			let ret = await this.appModel.doGetListGoods(params);
			if(ret.success){
				this.goods = ret.goods.map((el) => {
					if(el.images.length <= 0) el.images.push({
						id: null,
						pkid: null,
						poster: 1,
						url: '/assets/img/no-image.png',
						urlSmall: '/assets/img/no-image.png',
						descr: '',
						ugmtime_add: this.formatDate(new Date().toUTCString(), 'YYYY-MM-DDTHH:mm'),
					});
					return el;
				});
				this.goodsCount = ret.goods_count;
			} else {
				this.error = ret.message;
			}
			this.loadingGoods = false;
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.goodsCount / this.limit);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadData();
		
		setTimeout(() => {
			this.$refs.goodsitemName.focus();
		}, 300);
	},
	components: {},
}
</script>