<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-lg-12">
			<h2>Информация о ремонте № {{$route.params.id}}</h2>
			<ol class="breadcrumb">
				<li>
					<router-link :to="{path: '/'}">Главная</router-link>
				</li>
				<li>
					<router-link :to="{path: '/orders'}">База ремонтов</router-link>
				</li>
				<li class="active">
					<router-link :to="{path: '/orders/' + $route.params.id}">
						<strong>Информация о ремонте</strong>
					</router-link>
				</li>
			</ol>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row" v-if="tpl == 'loading'">
			<div class="col-md-12" style="text-align: center;">
				<i class="fa fa-spinner fa-spin m-b" style="font-size: 150px;"></i>
				<h1>ЗАГРУЗКА ДАННЫХ...</h1>
			</div>
		</div>
		<div class="row" v-if="tpl == 'notexists'">
			<div class="col-md-12" style="color: #f00; text-align: center;">
				<h1>РЕМОНТ НЕ НАЙДЕН</h1>
				<div class="m-t-lg">
					<router-link :to="{path: '/orders'}" class="btn btn-primary waves-effect waves-light">Назад</router-link>
				</div>
			</div>
		</div>
		<div v-if="tpl == 'info'">
			<div class="row">
				<div class="col-md-12">
					<div class="tabs-container">
						<ul class="nav nav-tabs">
							<li :class="{'active': tab1 == '1'}">
								<a @click="tab1 = '1'">Данные клиента</a>
							</li>
							<li :class="{'active': tab1 == '2'}">
								<a @click="tab1 = '2'">Инструмент</a>
							</li>
							<li :class="{'active': tab1 == '3'}">
								<a @click="tab1 = '3'">Производимые работы</a>
							</li>
							<li :class="{'active': tab1 == '4'}">
								<a @click="tab1 = '4'">Требуемые материалы</a>
							</li>
							<li :class="{'active': tab1 == '5'}">
								<a @click="tab1 = '5'">Файлы</a>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{'active': tab1 == '1'}" v-show="tab1 == '1'">
								<div class="panel-body" style="padding-bottom: 5px;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>Фамилия, Имя и Отчество клиента <span class="text-danger">*</span></label>
												<vue-bootstrap-typeahead
													inputClass="text-center"
													placeholder="ФИО клиента"
													v-model="clientinfo.fio"
													:data="clients"
													:serializer="s => s.fio"
													@hit="onSelectClient($event)"
													@input="getClients($event.target.value)"
												>
													<template v-slot:prepend>
														<span class="input-group-btn">
															<button type="button" class="btn btn-white" :disabled="clientinfo.id == null" @click="onClearClient()" content="Отменить выбор клиента" v-tippy="{placement: 'top'}">
																<i class="fa fa-times"></i>
															</button>
														</span>
													</template>
													<template v-slot:append>
														<span class="input-group-btn" v-if="clientinfo.id">
															<router-link :to="{path: '/clients/' + clientinfo.id}" class="btn btn-white" content="Перейти к клиенту" v-tippy="{placement: 'top'}">
																<i class="fa fa-chevron-right"></i>
															</router-link>
														</span>
													</template>
												</vue-bootstrap-typeahead>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-5">
											<div class="form-group">
												<label>Телефоны</label>
												<div class="input-group">
													<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="clientinfo.tel[0].value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onTelAdd()">
															<i class="fa fa-plus"></i>
														</button>
													</span>
												</div>
											</div>
											<div class="form-group" v-for="(tel, index) in clientinfo.tel.slice(1)">
												<div class="input-group">
													<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="tel.value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onTelDel(index + 1)">
															<i class="fa fa-minus"></i>
														</button>
													</span>
												</div>
											</div>
										</div>
										<div class="col-md-7">
											<div class="form-group">
												<label>Мобильные телефоны</label>
												<div class="input-group">
													<span class="input-group-btn">
														<a :href="'tel:'+clientinfo.mobtel[0].value.replace(/\s+/g, '')" class="btn btn-white" content="Позвонить" v-tippy="{placement: 'top'}">
															<i class="fa fa-phone"></i>
														</a>
													</span>
													<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="clientinfo.mobtel[0].value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onMobtelAdd()">
															<i class="fa fa-plus"></i>
														</button>
													</span>
												</div>
											</div>
											<div class="form-group" v-for="(mobt, index) in clientinfo.mobtel.slice(1)">
												<div class="input-group">
													<span class="input-group-btn">
														<a :href="'tel:'+mobt.value.replace(/\s+/g, '')" class="btn btn-white" content="Позвонить" v-tippy="{placement: 'top'}">
															<i class="fa fa-phone"></i>
														</a>
													</span>
													<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="mobt.value" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="onMobtelDel(index + 1)">
															<i class="fa fa-minus"></i>
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label>Город</label>
												<input type="text" placeholder="Город" class="form-control text-center" v-model="clientinfo.city" />
											</div>
										</div>
										<div class="col-md-8">
											<div class="form-group">
												<label>Адрес клиента</label>
												<input type="text" placeholder="Адрес клиента" class="form-control text-center" v-model="clientinfo.address" />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-5">
											<div class="form-group">
												<label>Email клиента</label>
												<input type="text" placeholder="Email клиента" class="form-control text-center" v-model="clientinfo.email" />
											</div>
										</div>
										<div class="col-md-7">
											<div class="form-group">
												<label>Статус клиента</label>
												<div class="input-group">
													<input type="text" placeholder="Статус клиента" class="form-control text-center text-danger" :style="{WebkitTextSecurity: clientStatusShow ? 'none' : 'disc'}" v-model="clientinfo.status" />
													<span class="input-group-btn">
														<button type="button" class="btn btn-white" @click="clientStatusShow = !clientStatusShow">
															<i class="fa fa-eye" :class="{'fa-eye': !clientStatusShow, 'fa-eye-slash': clientStatusShow}"></i>
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-8">
											<div class="form-group">
												<label>Заметка к клиенту</label>
												<textarea ref="client_comment" placeholder="Заметка к клиенту" class="form-control" style="resize: none;" v-model="clientinfo.comment"></textarea>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label>Откуда узнали о мастере</label>
												<select class="form-control" v-model="clientinfo.wheretheylearn_id">
													<option :value="wheretheylearn.id" v-for="wheretheylearn in wheretheylearns" :key="wheretheylearn.id">{{wheretheylearn.name}}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '2'}" v-show="tab1 == '2'">
								<div class="panel-body">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Инструмент <span class="text-danger">*</span></label>
												<select class="form-control" v-model="orderinfo.instrument_id" @change="orderinfo.instrument_type_id = ''; orderinfo.instrument_size_id = '';" :disabled="orderinfo.services.length > 0 || orderinfo.materials.length > 0">
													<option value="" disabled="disabled">Инструмент не выбран</option>
													<option :value="instrumentname.id" v-for="instrumentname in instrumentnames" :key="instrumentname.id">{{instrumentname.name}}</option>
												</select>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Тип инструмента <span class="text-danger">*</span></label>
												<select class="form-control" v-model="orderinfo.instrument_type_id" :disabled="!orderinfo.instrument_id">
													<option value="" disabled="disabled">Тип инструмента не выбран</option>
													<option :value="instrumenttype.id" v-for="instrumenttype in instrumenttypesf" :key="instrumenttype.id">{{instrumenttype.type}}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label>Размер инструмента <span class="text-danger">*</span></label>
												<select class="form-control" v-model="orderinfo.instrument_size_id" :disabled="!orderinfo.instrument_id">
													<option value="" disabled="disabled">Размер инструмента не выбран</option>
													<option :value="instrumentsize.id" v-for="instrumentsize in instrumentsizesf" :key="instrumentsize.id">{{instrumentsize.size}}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label>Фирма-производитель <span class="text-danger">*</span></label>
												<select class="form-control" v-model="orderinfo.instrument_firm_id">
													<option value="" disabled="disabled">Фирма не выбрана</option>
													<option :value="firm.id" v-for="firm in firms" :key="firm.id">{{firm.name}}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label>Кейс <span class="text-danger">*</span></label>
												<select class="form-control" v-model="orderinfo.casename_id">
													<option value="" disabled="disabled">Кейс не выбран</option>
													<option :value="casename.id" v-for="casename in casenames" :key="casename.id">{{casename.name}}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label>Цвет</label>
												<input type="text" class="form-control text-center" v-model="orderinfo.instrument_color" />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label>Модель</label>
												<input type="text" class="form-control text-center" v-model="orderinfo.instrument_model" />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label>Серийный номер</label>
												<input type="text" class="form-control text-center" v-model="orderinfo.instrument_serial_number" />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Состояние и общее описание неисправностей</label>
												<textarea ref="order_defect_descr" class="form-control" placeholder="Состояние и общее описание неисправностей" style="resize: none;" v-model="orderinfo.defect_descr"></textarea>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Комплектность</label>
												<textarea ref="order_complection" class="form-control" placeholder="Комплектность" style="resize: none;" v-model="orderinfo.complection"></textarea>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>Комментарий</label>
												<textarea ref="order_comment" class="form-control" placeholder="Комментарий" style="resize: none;" v-model="orderinfo.comment"></textarea>
											</div>
										</div>
									</div>
									<hr/>
									<div class="row">								
										<div class="col-md-6">
											<div class="text-center">
												<label>Изображения "До ремонта"</label>
											</div>
											<div class="order-images-gallery">
												<lightgallery
													:settings="lightgallerySettings"
													:onInit="onLightGalleryInit0"
												>
													<div class="property-image imgbl" v-for="(image, index) in orderinfoImagesType0" :key="image.id">
														<a :href="image.url" class="image block">
															<img class="img-responsive" :src="image.urlSmall" :style="{width: (!$device.isMobile ? '150px' : '225px'), height: (!$device.isMobile ? '150px' : '225px')}" />
														</a>
														<div>{{formatDate(image.ugmtime_add, 'DD.MM.YYYY HH:mm')}}</div>
														<div class="btn-group">
															<button type="button" class="btn btn-info" @click="onOrderImageEditType(1, image.pkid)" :disabled="loadingImg" content="Сделать как В процессе ремонта" v-tippy="{placement: 'top'}">Это в проц.</button>
															<button type="button" class="btn btn-danger" @click="onShowOrderImageDeleteModal(image.pkid)" :disabled="loadingImg" content="Удалить изображение" v-tippy="{placement: 'top'}">
																<i class="fa fa-times"></i>
															</button>
														</div>
													</div>
												</lightgallery>
											</div>
										</div>
										<hr class="visible-xs" />
										<div class="col-md-6">
											<div class="text-center">
												<label>Изображения "В процессе ремонта"</label>
											</div>
											<div class="order-images-gallery">
												<lightgallery
													:settings="lightgallerySettings"
													:onInit="onLightGalleryInit1"
												>
													<div class="property-image imgbl" v-for="(image, index) in orderinfoImagesType1" :key="image.id">
														<a :href="image.url" class="image block">
															<img class="img-responsive" :src="image.urlSmall" :style="{width: (!$device.isMobile ? '150px' : '225px'), height: (!$device.isMobile ? '150px' : '225px')}" />
														</a>
														<div>{{formatDate(image.ugmtime_add, 'DD.MM.YYYY HH:mm')}}</div>
														<div class="btn-group">
															<button type="button" class="btn btn-info" @click="onOrderImageEditType(0, image.pkid)" :disabled="loadingImg" content="Сделать как До ремонта" v-tippy="{placement: 'top'}">Это до</button>
															<button type="button" class="btn btn-danger" @click="onShowOrderImageDeleteModal(image.pkid)" :disabled="loadingImg" content="Удалить изображение" v-tippy="{placement: 'top'}">
																<i class="fa fa-times"></i>
															</button>
														</div>
													</div>
												</lightgallery>
											</div>
										</div>
										<!--
										<div class="col-md-12 m-t-sm addphotobtn">
											<a href="javascript://" title="Добавить фото">
												<img src="{{system_settings.site_url}}/tpl/site/{{system_settings.template_name}}/img/plus.jpg" style="opacity: .6; width: 100%; height: 125px;">
											</a>
											<input type="file" style="display: none;" class="uploadpic" accept="image/jpeg,image/png,image/gif,capture=camera" />
										</div>
										-->
									</div>
									<hr />
									<div class="row">
										<div class="col-md-12 m-t-sm">
											<div class="bigUploadContainer">
												<h1 class="m-t-none">Загрузка изображений</h1>
												<div class="btn-group m-b-sm">
													<button class="btn" :class="{'btn-primary': biguploadImgType == 0, 'btn-white': biguploadImgType != 0}" type="button" @click="biguploadImgType = 0" :disabled="biguploadImgTypeDisabled">До ремонта</button>
													<button class="btn" :class="{'btn-primary': biguploadImgType == 1, 'btn-white': biguploadImgType != 1}" type="button" @click="biguploadImgType = 1" :disabled="biguploadImgTypeDisabled">В проц. ремонта</button>
												</div>
												<big-upload
													inputFileAccept="image/jpeg,image/png,image/gif,capture=camera"
													:scriptPath="'/api/data/lists/orders/'+orderinfo.id+'/images/upload-chunk'"
													:scriptPathParams="biguploadImgParams"
													:onStart="() => {biguploadImgTypeDisabled = true}"
													:onSuccess="onBiguploadImgSuccess"
													:onFail="() => {biguploadImgTypeDisabled = false}"
													:onCancel="() => {biguploadImgTypeDisabled = false}"
												></big-upload>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '3'}" v-show="tab1 == '3'">
								<div class="panel-body">
									<div class="row">
										<div class="col-md-12">
											<button type="button" style="white-space: normal;" class="btn btn-info pull-right m-b" @click="onShowAddServiceModal" :disabled="!orderinfo.instrument_id">Добавить производимую работу</button>
										</div>
									</div>
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th>Производимая работа</th>
													<th width="10%" class="text-center">Кол-во</th>
													<th width="15%" class="text-center">Цена</th>
													<th width="15%" class="text-center">Сумма</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="service in orderinfo.services" :key="service.id">
													<td style="vertical-align: middle;">{{service.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{service.count}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(service.price)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(parseInt(service.price) * parseInt(service.count))}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditServiceModal(service.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteServiceModal(service.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '4'}" v-show="tab1 == '4'">
								<div class="panel-body">
									<div class="row">
										<div class="col-md-12">
											<button type="button" style="white-space: normal;" class="btn btn-info pull-right m-b" @click="onShowAddMaterialModal">Добавить требуемый материал</button>
										</div>
									</div>
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th>Материал</th>
													<th width="10%" class="text-center">Кол-во</th>
													<th width="15%" class="text-center">Цена</th>
													<th width="15%" class="text-center">Сумма</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="material in orderinfo.materials" :key="material.id">
													<td style="vertical-align: middle;">{{material.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{material.count}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(material.price)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(parseInt(material.price) * parseInt(material.count))}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditMaterialModal(material.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteMaterialModal(material.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab1 == '5'}" v-show="tab1 == '5'">
								<div class="panel-body">
									<div class="row">
										<div class="col-lg-12 text-center">
											<div class="file-box" v-for="file in orderinfo.files" :key="file.id">
												<div class="file">
													<div class="file-pre image" @click="onShowOrderFIlePreviewModal(file.pkid)" v-if="contains(file.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)">
														<img alt="image" class="img-responsive" :src="file.url" />
													</div>
													<div class="file-pre icon" @click="onShowOrderFIlePreviewModal(file.pkid)" v-else-if="contains(file.extension, ['mp3', 'amr', 'wav', 'ogg', 'm4a'], true)">
														<i class="fa fa-music"></i>
													</div>
													<div class="file-pre icon" @click="onShowOrderFIlePreviewModal(file.pkid)" v-else-if="contains(file.extension, ['mp4', 'avi', 'wmv', 'mov', 'mkv', 'mpg', 'mpeg', 'mpeg4', '3gp', 'webm', 'ogv', 'm4v'], true)">
														<i class="fa fa-film"></i>
													</div>
													<div class="file-pre icon" @click="onShowOrderFIlePreviewModal(file.pkid)" v-else>
														<i class="fa fa-file"></i>
													</div>
													<div class="file-name">
														<div class="file-extension">{{file.extension}}</div>
														<div class="file-date">{{formatDate(file.ugmtime_add, 'DD.MM.YYYY HH:mm')}}</div>
														<button type="button" class="btn btn-sm m-t-sm btn-danger" @click="onShowOrderFileDeleteModal(file.pkid)" :disabled="loadingFile">Удалить</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div class="row">
										<div class="col-md-12 m-t-sm">
											<div class="bigUploadContainer">
												<h1 class="m-t-none">Загрузка файлов</h1>
												<big-upload
													inputFileAccept="image/*,audio/*,video/*,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.rtf,.csv,.svg,capture=camera,capture=camcorder,capture=microphone,capture=filesystem"
													:scriptPath="'/api/data/lists/orders/'+orderinfo.id+'/files/upload-chunk'"
													:onSuccess="onBiguploadFileSuccess"
												></big-upload>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row m-t-lg">
				<div class="col-md-7">
					<div class="ibox float-e-margins">
						<div class="ibox-content">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Предполагаемая стоимость ремонта</label>
										<input type="number" class="form-control text-center" min="0" @wheel="$event.target.blur()" v-model.number="orderinfo.amount_paid" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Стоимость услуг</label>
										<input type="text" class="form-control text-center" :value="number_format(orderServicesCost)" disabled="disabled" />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>Стоимость материалов</label>
										<input type="text" class="form-control text-center" :value="number_format(orderMaterialsCost)" disabled="disabled" />
									</div>
								</div>
								
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Сумма скидки</label>
										<input type="number" class="form-control text-center" min="0" @wheel="$event.target.blur()" v-model.number="orderinfo.discount" />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group has-error">
										<label>Сумма по документу</label>
										<input type="text" class="form-control text-center" :value="number_format(orderCost)" disabled="disabled" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group has-success">
										<label>Внесенная сумма</label>
										<input type="text" class="form-control text-center" :value="number_format(orderPaidcost)" disabled="disabled" />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group has-error">
										<label>Долг по документу</label>
										<input type="text" class="form-control text-center" :value="number_format(orderDebtcost)" disabled="disabled" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<button type="button" style="white-space: normal;" class="btn btn-block btn-warning" @click="onShowAddPrepaymentModal()">Внести предоплату</button>
								</div>
								<div class="col-md-6">
									<button type="button" style="white-space: normal;" class="btn btn-block btn-warning" @click="onShowAddPaymentModal()">Внести оплату</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="ibox float-e-margins">
								<div class="ibox-content">
									<div class="form-group m-b-none">
										<label>Дата создания</label>
										<input type="datetime-local" placeholder="Дата и время" class="form-control text-center" v-model="orderinfo.dateadd" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="ibox float-e-margins">
								<div class="ibox-content">
									<div class="form-group m-b-none">
										<label>Длительность ремонта</label>
										<input type="text" readonly class="form-control text-center" :value="getDateDuration(orderinfo)" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="ibox float-e-margins">
						<div class="ibox-content">
							<div class="form-group" :class="{'m-b-none': orderinfo.status_id != 2 && orderinfo.status_id != 4}">
								<label>Текущий статус</label>
								<select class="form-control" v-model="orderinfo.status_id">
									<option :value="orderStat.id" v-for="orderStat in ordersStatuses" :key="orderStat.id">{{orderStat.name}}</option>
								</select>
							</div>
							<div class="row m-b-none" v-if="orderinfo.status_id == 2 && (orderinfo.services.length > 0 || orderinfo.materials.length > 0)">
								<div class="visible-xs">
									<div class="col-md-6">
										<button type="button" style="white-space: normal;" class="btn btn-block btn-success" @click="onOrderSendSms1()">Отправить SMS</button>
									</div>
									<div class="col-md-6">
										<button type="button" style="white-space: normal;" class="btn btn-block btn-success" @click="onOrderSendWa1()">Отправить WhatsApp</button>
									</div>
								</div>
								<div class="hidden-xs">
									<div class="col-md-offset-3 col-md-6">
										<button type="button" style="white-space: normal;" class="btn btn-block btn-success" @click="onOrderSendWa1()">Отправить WhatsApp</button>
									</div>
								</div>
							</div>
							<div class="row m-b-none" v-if="orderinfo.status_id == 4">
								<div class="visible-xs">
									<div class="col-md-6">
										<button type="button" style="white-space: normal;" class="btn btn-block btn-success" @click="onOrderSendSms2()">Отправить SMS</button>
									</div>
									<div class="col-md-6">
										<button type="button" style="white-space: normal;" class="btn btn-block btn-success" @click="onOrderSendWa2()">Отправить WhatsApp</button>
									</div>
								</div>
								<div class="hidden-xs">
									<div class="col-md-offset-3 col-md-6">
										<button type="button" style="white-space: normal;" class="btn btn-block btn-success" @click="onOrderSendWa2()">Отправить WhatsApp</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tabs-container">
						<ul class="nav nav-tabs">
							<li :class="{'active': tab2 == '1'}">
								<a @click="tab2 = '1'">История оплат</a>
							</li>
							<li :class="{'active': tab2 == '2'}">
								<a @click="tab2 = '2'">История статусов</a>
							</li>
							<li :class="{'active': tab2 == '3'}">
								<a @click="tab2 = '3'">Посещения</a>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{'active': tab2 == '1'}" v-show="tab2 == '1'">
								<div class="panel-body">
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th width="15%" class="text-center">Дата</th>
													<th width="30%" class="text-center">Сумма</th>
													<th class="text-center">Описание</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="historypayment in orderinfo.historypayments" :key="historypayment.id">
													<td style="vertical-align: middle;" class="text-center">{{formatDate(historypayment.dateadd, 'DD.MM.YYYY HH:mm')}}</td>
													<td style="vertical-align: middle;" class="text-center">{{number_format(historypayment.amount)}}</td>
													<td style="vertical-align: middle;" class="text-center">{{historypayment.descr}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditHistoryPaymentModal(historypayment.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteHistoryPaymentModal(historypayment.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab2 == '2'}" v-show="tab2 == '2'">
								<div class="panel-body">
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th width="15%" class="text-center">Дата</th>
													<th width="30%" class="text-center">Статус</th>
													<th class="text-center">Описание</th>
													<th width="100">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="historystatus in orderinfo.historystatuses" :key="historystatus.id">
													<td style="vertical-align: middle;" class="text-center">{{formatDate(historystatus.dateadd, 'DD.MM.YYYY HH:mm')}}</td>
													<td style="vertical-align: middle;" class="text-center">{{historystatus.name}}</td>
													<td style="vertical-align: middle;" class="text-center">{{historystatus.descr}}</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-default" @click="onShowEditHistoryStatusModal(historystatus.id)">
															<i class="fa fa-pencil"></i>
														</button>
														<button class="btn btn-sm btn-danger m-l-xs" @click="onShowDeleteHistoryStatusModal(historystatus.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="tab-pane" :class="{'active': tab2 == '3'}" v-show="tab2 == '3'">
								<div class="panel-body">
									<div class="table-responsive">
										<table class="table table-hover m-b-none">
											<thead>
												<tr>
													<th width="15%" class="text-center">Дата</th>
													<th class="text-center">Информация</th>
													<th width="50">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="historyvisit in orderinfo.historyvisits" :key="historyvisit.id">
													<td style="vertical-align: middle;" class="text-center">{{formatDate(historyvisit.ugmtime_add, 'DD.MM.YYYY HH:mm')}}</td>
													<td style="vertical-align: middle;" class="text-center">
														<div v-if="historyvisit.ip">{{historyvisit.ip}}</div>
														<div v-if="historyvisit.country_code">Страна: {{historyvisit.country_name}} ({{historyvisit.country_code}})</div>
														<div v-if="historyvisit.device_type">Устройство: {{historyvisit.device_type}}</div>
														<div v-if="historyvisit.device_browser">Браузер: {{historyvisit.device_browser}} {{historyvisit.device_browser_version}}</div>
														<div v-if="historyvisit.device_os">ОС: {{historyvisit.device_os}} {{historyvisit.device_os_version}}</div>
													</td>
													<td style="vertical-align: middle;" class="text-right">
														<button class="btn btn-sm btn-danger" @click="onShowDeleteHistoryVisitModal(historyvisit.id)">
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<div class="visible-xs text-center m-t-sm m-b-xs">
						<button type="button" style="white-space: normal;" class="btn btn-default m-t-xs m-r-xs" @click="onOrderLinkShow()">Показать ссылку</button>
						<button type="button" style="white-space: normal;" class="btn btn-default m-t-xs m-r-xs" @click="onOrderLinkSendSms()">Отправить ссылку по SMS</button>
						<button type="button" style="white-space: normal;" class="btn btn-default m-t-xs" @click="onOrderLinkSendWa()">Отправить ссылку в WhatsApp</button>
					</div>
					<div class="hidden-xs">
						<button type="button" style="white-space: normal;" class="btn btn-default m-t-xs m-r-xs" @click="onOrderLinkShow()">Показать ссылку</button>
						<button type="button" style="white-space: normal;" class="btn btn-default m-t-xs" @click="onOrderLinkSendWa()">Ссылку в WhatsApp</button>
					</div>
				</div>
				<div class="col-md-8 text-right">
					<hr class="visible-xs" />
					<div :class="{'text-center': $device.isMobile}">
						<a :href="'/api/pdf/report-actpriem/'+orderinfo.id" target="_blank" style="white-space: normal;" class="btn btn-default m-t-xs m-r-xs">Акт приемки</a>
						<a :href="'/orders/'+orderinfo.id+'/report-actpriempril'" target="_blank" style="white-space: normal;" class="btn btn-default m-t-xs m-r-xs">Приложение к акту приемки</a>
						<a :href="'/api/pdf/report-actviprab/'+orderinfo.id" target="_blank" style="white-space: normal;" class="btn btn-default m-t-xs">Акт выполненных работ</a>
					</div>
				</div>
			</div>
			<div class="row m-t-md">
				<div class="col-xs-3">
					<button type="button" style="white-space: normal;" class="btn btn-danger" @click="onShowDeleteOrderModal" :disabled="loading2">
						<i class="fa fa-trash" :class="{'m-r-sm': !$device.isMobile}"></i>
						<span v-if="!$device.isMobile">Удалить ремонт</span>
					</button>
				</div>
				<div class="col-xs-9 text-right">
					<button type="button" @click="onShowOrderSaveVidatModal()" style="white-space: normal;" class="btn btn-warning m-r-xs" :disabled="loading2 || loadingSaveVidat">
						<span v-if="!$device.isMobile">Выдать инструмент</span>
						<span v-else>Выдать</span>
						<i class="fa m-l-sm" :class="{'fa-angle-double-right': !loadingSaveVidat, 'fa-spinner fa-spin': loadingSaveVidat}"></i>
					</button>
					<button type="button" @click="onOrderSave()" style="white-space: normal;" class="btn btn-info" :disabled="!orderSaveEnable || loading2 || loadingSave">
						<i class="fa m-r-sm" :class="{'fa-floppy-o': !loadingSave, 'fa-spinner fa-spin': loadingSave}"></i>
						<span v-if="!$device.isMobile">Сохранить изменения</span>
						<span v-else>Сохранить</span>
					</button>
				</div>
			</div>
			<div class="alert alert-warning alert-dismissable m-t-md m-b-sm" v-if="error">
				<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
				{{error}}
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import '@/assets/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';

.bigUploadContainer {
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	text-align: center;
}

.order-images-gallery {
	margin: 0;
	padding: 0;
	text-align: center;
}
.order-images-gallery .property-image {
	display: inline-block;
	margin: 10px 10px;
}
.order-images-gallery .property-image a.image,
.order-images-gallery .property-image a.btn {
	display: block;
}
.order-images-gallery .property-image a.image {
	padding-bottom: 5px;
}

.file-box {
	float: none;
	display: inline-block;
	width: 256px;
}
.file {
	margin: 10px;
}
.file .file-pre {
	cursor: pointer;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import { vMaska } from 'maska';
import autosize from 'autosize';
import moment from 'moment-timezone';
import underscore from 'underscore';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead/VueBootstrapTypeahead.vue';
import BigUpload from '@/components/BigUpload.vue';

import SelectMobtelModal from '@/components/modals/Orders/SelectMobtelModal.vue';
import ImageDeleteModal from '@/components/modals/Orders/ImageDeleteModal.vue';
import FilePreviewModal from '@/components/modals/Orders/FilePreviewModal.vue';
import FileDeleteModal from '@/components/modals/Orders/FileDeleteModal.vue';
import ChangeStatusModal from '@/components/modals/Orders/ChangeStatusModal.vue';
import PrepaymentAddModal from '@/components/modals/Orders/PrepaymentAddModal.vue';
import PaymentAddModal from '@/components/modals/Orders/PaymentAddModal.vue';
import ServiceAddModal from '@/components/modals/Orders/ServiceAddModal.vue';
import ServiceEditModal from '@/components/modals/Orders/ServiceEditModal.vue';
import ServiceDeleteModal from '@/components/modals/Orders/ServiceDeleteModal.vue';
import MaterialAddModal from '@/components/modals/Orders/MaterialAddModal.vue';
import MaterialEditModal from '@/components/modals/Orders/MaterialEditModal.vue';
import MaterialDeleteModal from '@/components/modals/Orders/MaterialDeleteModal.vue';
import HistoryPaymentEditModal from '@/components/modals/Orders/HistoryPaymentEditModal.vue';
import HistoryPaymentDeleteModal from '@/components/modals/Orders/HistoryPaymentDeleteModal.vue';
import HistoryStatusEditModal from '@/components/modals/Orders/HistoryStatusEditModal.vue';
import HistoryStatusDeleteModal from '@/components/modals/Orders/HistoryStatusDeleteModal.vue';
import HistoryVisitDeleteModal from '@/components/modals/Orders/HistoryVisitDeleteModal.vue';
import OrderSaveVidatModal from '@/components/modals/Orders/OrderSaveVidatModal.vue';
import OrderDeleteModal from '@/components/modals/Orders/OrderDeleteModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Информация о ремонте | MasterSMI Учет'});
	},
	directives: {
		maska: vMaska,
	},
	data: () => ({
		tpl: 'loading',
		
		tab1: '1',
		tab2: '1',
		
		clientStatusShow: false,
		
		instrumentnames: [],
		instrumenttypes: [],
		instrumentsizes: [],
		firms: [],
		casenames: [],
		wheretheylearns: [],
		ordersStatuses: [],
		
		clients: [],
		
		clientinfo: {
			id: null,
			fio: '',
			tel: [{
				value: '',
			}],
			mobtel: [{
				value: '',
			}],
			city: 'Астана',
			address: '',
			email: '',
			status: '',
			comment: '',
			wheretheylearn_id: null,
		},
		orderinfo: {},
		orderinfo_showStatusModal: false,
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
		lightGallery0: null,
		lightGallery1: null,
		
		biguploadImgType: 1,
		biguploadImgTypeDisabled: false,
		
		orderSaveEnable: false,
		
		loadingSaveVidat: false,
		loadingSave: false,
		loadingImg: false,
		loadingFile: false,
		loading2: false,
		loading: true,
		error: '',
	}),
	computed: {
		// типы инструментов отфильтрованные по инструменту
		instrumenttypesf(){
			return this.instrumenttypes.filter((el) => el.instrument_id == this.orderinfo.instrument_id);
		},
		// размеры инструментов отфильтрованные по инструменту
		instrumentsizesf(){
			return this.instrumentsizes.filter((el) => el.instrument_id == this.orderinfo.instrument_id);
		},
		// изображения "до ремонта"
		orderinfoImagesType0(){
			return (this.orderinfo.images || []).filter((el) => el.type == 0);
		},
		// изображения "в процессе ремонта"
		orderinfoImagesType1(){
			return (this.orderinfo.images || []).filter((el) => el.type == 1);
		},
		// стоимость услуг
		orderServicesCost(){
			let ret = 0;
			for(let service of this.orderinfo.services){
				ret += (service.price * service.count);
			}
			return ret;
		},
		// стоимость материалов
		orderMaterialsCost(){
			let ret = 0;
			for(let material of this.orderinfo.materials){
				ret += (material.price * material.count);
			}
			return ret;
		},
		// сумма по документу
		orderCost(){
			return this.orderServicesCost + this.orderMaterialsCost - parseInt(this.orderinfo.discount);
		},
		// внесенная сумма
		orderPaidcost(){
			let ret = 0;
			for(let historypayment of this.orderinfo.historypayments){
				ret += historypayment.amount;
			}
			return ret;
		},
		// долг по документу
		orderDebtcost(){
			let ret = this.orderCost - this.orderPaidcost;
			if(ret < 0) ret = 0;
			return ret;
		},
		biguploadImgParams(){
			return new URLSearchParams({
				type: this.biguploadImgType,
			});
		},
	},
	watch: {
		clientinfo: {
			handler(val, oldVal){
				this.orderSaveEnable = true;
			},
			deep: true,
		},
		'orderinfo.instrument_id'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.instrument_type_id'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.instrument_size_id'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.instrument_firm_id'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.casename_id'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.instrument_color'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.instrument_model'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.instrument_serial_number'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.defect_descr'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.complection'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.comment'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.amount_paid'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.discount'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.dateadd'(val, oldVal){
			this.orderSaveEnable = true;
		},
		'orderinfo.status_id'(val, oldVal){
			if(this.orderinfo_showStatusModal){
				let that = this;
				this.$vfm.show({
					component: ChangeStatusModal,
					on: {
						async change(close){
							let ret = await that.appModel.doGetHistoryStatusesOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.historystatuses = ret.order_history_statuses;
							} else {
								that.error = ret.message;
							}
							close();
						},
						cancel(close){
							that.orderinfo.status_id = oldVal;
							that.orderinfo_showStatusModal = false;
							close();
						},
					},
					params: {
						order_id: that.orderinfo.id,
						status_id: val,
					},
				});
			} else {
				this.orderinfo_showStatusModal = true;
			}
		},
		'orderinfo.images': {
			handler(val, oldVal){
				this.$nextTick(() => {
					if(this.lightGallery0) this.lightGallery0.refresh();
					if(this.lightGallery1) this.lightGallery1.refresh();
				});
			},
			deep: true,
		},
	},
	methods: {
		contains: lib.contains,
		
		// загрузки данных
		// подгрузка списка клиентов для typeahead и изменение фио клиента при редактировании
		getClients: underscore.debounce(async function(query){
			//this.clientinfo.fio = query;
			let ret = await this.appModel.doGetListClients({
				'filter[0][field]': 'lcl.fio',
				'filter[0][operator]': 'like',
				'filter[0][value]': '%'+query+'%',
				'sort[0][field]': 'lcl.fio',
				'sort[0][direction]': 'asc',
				limit: 25,
			});
			if(ret.success){
				this.clients = ret.clients;
			}
		}, 500),
		// загрузка данных ремонта
		async loadData(){
			let ret = await this.appModel.doGetOrderData(this.$route.params.id);
			if(ret.success){
				if(ret.exists){
					if(ret.clientinfo){
						this.clientinfo.id = ret.clientinfo.id;
						this.clientinfo.fio = ret.clientinfo.fio;
						this.clientinfo.tel = (ret.clientinfo.tel.length > 0 ? ret.clientinfo.tel : ['']).map((el) => ({value: el}));
						this.clientinfo.mobtel = (ret.clientinfo.mobtel.length > 0 ? ret.clientinfo.mobtel : ['']).map((el) => ({value: el}));
						this.clientinfo.city = ret.clientinfo.city;
						this.clientinfo.address = ret.clientinfo.address;
						this.clientinfo.email = ret.clientinfo.email;
						this.clientinfo.status = ret.clientinfo.status;
						this.clientinfo.comment = ret.clientinfo.comment;
						this.clientinfo.wheretheylearn_id = ret.clientinfo.wheretheylearn_id;
					}
					this.orderinfo = ret.orderinfo;
					this.orderinfo.dateadd = this.formatDate(ret.orderinfo.dateadd, 'YYYY-MM-DDTHH:mm');
					if(ret.orderinfo.dateend != '0000-00-00 00:00:00'){
						this.orderinfo.dateend = this.formatDate(ret.orderinfo.dateend, 'YYYY-MM-DDTHH:mm');
					}
					setTimeout(() => {
						this.orderSaveEnable = false;
					}, 200);
					
					this.tpl = 'info';
				} else {
					this.tpl = 'notexists';
				}
			}
		},
		// загрузка списка статусов ремонтов
		async loadOrdersStatuses(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetOrdersStatuses();
			if(ret.success){
				this.ordersStatuses = ret.orders_statuses;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка инструментов
		async loadInstrumentNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentNames();
			if(ret.success){
				this.instrumentnames = ret.instrumentnames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка типов инструментов
		async loadInstrumentTypes(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentTypes();
			if(ret.success){
				this.instrumenttypes = ret.instrumenttypes;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка размеров инструментов
		async loadInstrumentSizes(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListInstrumentSizes();
			if(ret.success){
				this.instrumentsizes = ret.instrumentsizes;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка фирм-производителей
		async loadFirms(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListFirms();
			if(ret.success){
				this.firms = ret.firms;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка кейсов
		async loadCaseNames(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListCaseNames();
			if(ret.success){
				this.casenames = ret.casenames;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		// загрузка списка "откуда узнали о мастере"
		async loadWhereTheyLearns(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetListWhereTheyLearns();
			if(ret.success){
				this.wheretheylearns = ret.wheretheylearns;
				this.wheretheylearns.unshift({
					id: null,
					name: 'Неизвестно',
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		// диалоговые окна
		// показ окна удаления изображения
		async onShowOrderImageDeleteModal(image_pkid){
			let that = this;
			this.$vfm.show({
				component: ImageDeleteModal,
				on: {
					async delete(close){
						that.loading2 = true;
						that.loadingImg = true;
						that.error = '';
						let ret = await that.appModel.doDeleteImageOrder(that.orderinfo.id, image_pkid);
						if(ret.success){
							ret = await that.appModel.doGetImagesOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.images = ret.order_images;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						that.loading2 = false;
						that.loadingImg = false;
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					image: that.orderinfo.images.find((el) => el.pkid == image_pkid),
				},
			});
		},
		// показ окна предпросмотра файла
		onShowOrderFIlePreviewModal(file_pkid){
			let that = this;
			this.$vfm.show({
				component: FilePreviewModal,
				on: {
					cancel(close){
						close();
					},
				},
				params: {
					file: that.orderinfo.files.find((el) => el.pkid == file_pkid),
				},
			});
		},
		// показ окна удаления файла
		async onShowOrderFileDeleteModal(file_pkid){
			let that = this;
			this.$vfm.show({
				component: FileDeleteModal,
				on: {
					async delete(close){
						that.loading2 = true;
						that.loadingFile = true;
						that.error = '';
						let ret = await that.appModel.doDeleteFileOrder(that.orderinfo.id, file_pkid);
						if(ret.success){
							ret = await that.appModel.doGetFilesOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.files = ret.order_files;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						that.loading2 = false;
						that.loadingFile = false;
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					file: that.orderinfo.files.find((el) => el.pkid == file_pkid),
				},
			});
		},
		// показ окна добавления предоплаты
		onShowAddPrepaymentModal(){
			let that = this;
			this.$vfm.show({
				component: PrepaymentAddModal,
				on: {
					async add(close){
						let ret = await that.appModel.doGetHistoryPaymentsOrder(that.orderinfo.id);
						if(ret.success){
							that.orderinfo.historypayments = ret.order_history_payments;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					order_id: that.orderinfo.id,
				},
			});
		},
		// показ окна добавления оплаты
		onShowAddPaymentModal(){
			let that = this;
			this.$vfm.show({
				component: PaymentAddModal,
				on: {
					async add(close){
						let ret = await that.appModel.doGetHistoryPaymentsOrder(that.orderinfo.id);
						if(ret.success){
							that.orderinfo.historypayments = ret.order_history_payments;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					order_id: that.orderinfo.id,
					debtcost: that.orderDebtcost,
				},
			});
		},
		// показ окна добавления производимой работы в ремонт
		onShowAddServiceModal(){
			if(this.orderinfo.instrument_id == ''){
				this.error = 'Выберите инструмент';
				return false;
			}
			let that = this;
			this.$vfm.show({
				component: ServiceAddModal,
				on: {
					async add(selectedService, close){
						let ret = await that.appModel.doAddServiceOrder(that.orderinfo.id, {
							service_id: selectedService.id,
							name: selectedService.name,
							count: selectedService.count,
							price: selectedService.price,
						});
						if(ret.success){
							ret = await that.appModel.doGetServicesOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.services = ret.order_services;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					instrument_id: that.orderinfo.instrument_id,
				},
			});
		},
		// показ окна изменения производимой работы ремонта
		onShowEditServiceModal(id){
			let that = this;
			this.$vfm.show({
				component: ServiceEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetServicesOrder(that.orderinfo.id);
						if(ret.success){
							that.orderinfo.services = ret.order_services;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					order_id: that.orderinfo.id,
					order_service_id: id,
					service: that.orderinfo.services.find((el) => el.id == id),
				},
			});
		},
		// показ окна удаления производимой работы из ремонта
		onShowDeleteServiceModal(id){
			let that = this;
			this.$vfm.show({
				component: ServiceDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteServiceOrder(that.orderinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetServicesOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.services = ret.order_services;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					service: that.orderinfo.services.find((el) => el.id == id),
				},
			});
		},
		// показ окна добавления требуемого материала в ремонт
		onShowAddMaterialModal(){
			if(this.orderinfo.instrument_id == ''){
				this.error = 'Выберите инструмент';
				return false;
			}
			let that = this;
			this.$vfm.show({
				component: MaterialAddModal,
				on: {
					async add(selectedMaterial, close){
						let ret = await that.appModel.doAddMaterialOrder(that.orderinfo.id, {
							material_id: selectedMaterial.id,
							name: selectedMaterial.name,
							count: selectedMaterial.count,
							price: selectedMaterial.price,
						});
						if(ret.success){
							ret = await that.appModel.doGetMaterialsOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.materials = ret.order_materials;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					instrument_id: that.orderinfo.instrument_id,
				},
			});
		},
		// показ окна изменения требуемого материала ремонта
		onShowEditMaterialModal(id){
			let that = this;
			this.$vfm.show({
				component: MaterialEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetMaterialsOrder(that.orderinfo.id);
						if(ret.success){
							that.orderinfo.materials = ret.order_materials;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					order_id: that.orderinfo.id,
					order_material_id: id,
					material: that.orderinfo.materials.find((el) => el.id == id),
				},
			});
		},
		// показ окна удаления требуемого материала из ремонта
		onShowDeleteMaterialModal(id){
			let that = this;
			this.$vfm.show({
				component: MaterialDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteMaterialOrder(that.orderinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetMaterialsOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.materials = ret.order_materials;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					material: that.orderinfo.materials.find((el) => el.id == id),
				},
			});
		},
		// показ окна изменения элемента истории оплат ремонта
		onShowEditHistoryPaymentModal(id){
			let that = this;
			let payment = that.orderinfo.historypayments.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryPaymentEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetHistoryPaymentsOrder(that.orderinfo.id);
						if(ret.success){
							that.orderinfo.historypayments = ret.order_history_payments;
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					order_id: that.orderinfo.id,
					historypayment_id: id,
					amount: payment.amount,
					description: payment.descr,
					dateadd: payment.dateadd,
				},
			});
		},
		// показ окна удаления элемента истории оплат ремонта
		onShowDeleteHistoryPaymentModal(id){
			let that = this;
			let payment = that.orderinfo.historypayments.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryPaymentDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteHistoryPaymentOrder(that.orderinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetHistoryPaymentsOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.historypayments = ret.order_history_payments;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					amount: payment.amount,
					description: payment.descr,
					dateadd: payment.dateadd,
				},
			});
		},
		// показ окна измения элемента истории статусов ремонта
		onShowEditHistoryStatusModal(id){
			let that = this;
			let status = that.orderinfo.historystatuses.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryStatusEditModal,
				on: {
					async edit(close){
						let ret = await that.appModel.doGetHistoryStatusesOrder(that.orderinfo.id);
						if(ret.success){
							that.orderinfo.historystatuses = ret.order_history_statuses;
							
							ret = await that.appModel.doGetOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.status_id = ret.order.status_id;
								that.orderinfo_showStatusModal = false;
								
								that.orderinfo.dateadd = that.formatDate(ret.order.dateadd, 'YYYY-MM-DDTHH:mm');
								if(ret.order.dateend != '0000-00-00 00:00:00'){
									that.orderinfo.dateend = that.formatDate(ret.order.dateend, 'YYYY-MM-DDTHH:mm');
								}
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					ordersStatuses: that.ordersStatuses,
					order_id: that.orderinfo.id,
					historystatus_id: id,
					status_id: status.status_id,
					description: status.descr,
					dateadd: status.dateadd,
				},
			});
		},
		// показ окна удаления элемента истории статусов ремонта
		onShowDeleteHistoryStatusModal(id){
			let that = this;
			let status = that.orderinfo.historystatuses.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryStatusDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteHistoryStatusOrder(that.orderinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetHistoryStatusesOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.historystatuses = ret.order_history_statuses;
								
								ret = await that.appModel.doGetOrder(that.orderinfo.id);
								if(ret.success){
									that.orderinfo.status_id = ret.order.status_id;
									that.orderinfo_showStatusModal = false;
								} else {
									that.error = ret.message;
								}
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					statusName: status.name,
					description: status.descr,
					dateadd: status.dateadd,
				},
			});
		},
		// показ окна удаления элемента истории посещений ремонта
		onShowDeleteHistoryVisitModal(id){
			let that = this;
			let visit = that.orderinfo.historyvisits.find((el) => el.id == id);
			this.$vfm.show({
				component: HistoryVisitDeleteModal,
				on: {
					async delete(close){
						let ret = await that.appModel.doDeleteHistoryVisitOrder(that.orderinfo.id, id);
						if(ret.success){
							ret = await that.appModel.doGetHistoryVisitsOrder(that.orderinfo.id);
							if(ret.success){
								that.orderinfo.historyvisits = ret.order_history_guests_visits;
							} else {
								that.error = ret.message;
							}
						} else {
							that.error = ret.message;
						}
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					historyvisit: visit,
				},
			});
		},
		// показ окна удаления ремонта
		onShowDeleteOrderModal(){
			let that = this;
			this.$vfm.show({
				component: OrderDeleteModal,
				on: {
					async delete(close){
						that.$router.push({path: '/orders'});
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					order_id: that.orderinfo.id,
				},
			});
		},
		// сохранение изменений ремонта и выдача
		async onShowOrderSaveVidatModal(){
			this.loadingSaveVidat = true;
			if(this.orderSaveEnable){
				await this.onOrderSave();
			}
			if(!this.loading2){
				let that = this;
				this.$vfm.show({
					component: OrderSaveVidatModal,
					on: {
						saveVidat(close){
							that.orderSaveEnable = false;
							that.$router.push({path: '/orders'});
							close();
						},
						cancel(close){
							close();
						},
					},
					params: {
						order_id: that.orderinfo.id,
					},
				});
			}
			this.loadingSaveVidat = false;
		},
		
		// события-действия
		// выбор клиента через typeahead
		onSelectClient(val){
			let tel = JSON.parse(val.tel || '[]');
			let mobtel = JSON.parse(val.mobtel || '[]');
			this.clientinfo = {
				id: val.id,
				fio: val.fio,
				tel: (tel.length > 0 ? tel : ['']).map((el) => ({value: el})),
				mobtel: (mobtel.length > 0 ? mobtel : ['']).map((el) => ({value: el})),
				city: val.city,
				address: val.address,
				email: val.email,
				status: val.status,
				comment: val.comment,
				wheretheylearn_id: val.wheretheylearn_id,
			};
		},
		// отмена выбора клиента
		onClearClient(){
			if(confirm('Вы уверены?')){
				this.clientinfo = {
					id: null,
					fio: '',
					tel: [{
						value: '',
					}],
					mobtel: [{
						value: '',
					}],
					city: 'Астана',
					address: '',
					email: '',
					status: '',
					comment: '',
					wheretheylearn_id: null,
				};
			}
		},
		// инициализация галереи "до ремонта"
		onLightGalleryInit0(detail){
            this.lightGallery0 = detail.instance;
        },
		// инициализация галереи "в процессе ремонта"
		onLightGalleryInit1(detail){
            this.lightGallery1 = detail.instance;
        },
		// успешная загрузка файла
		async onBiguploadFileSuccess(response){
			if(response.success || false){
				let ret = await this.appModel.doGetFilesOrder(this.orderinfo.id);
				if(ret.success){
					this.orderinfo.files = ret.order_files;
				} else {
					this.error = ret.message;
				}
			}
		},
		// успешная загрузка изображения
		async onBiguploadImgSuccess(response){
			if(response.success || false){
				let ret = await this.appModel.doGetImagesOrder(this.orderinfo.id);
				if(ret.success){
					this.orderinfo.images = ret.order_images;
				} else {
					this.error = ret.message;
				}
			}
			this.biguploadImgTypeDisabled = false;
		},
		// отправка sms при статусе "на согласовании"
		onOrderSendSms1(){
			let msgtext = '';
			for(let service of this.orderinfo.services){
				msgtext += service.name+' '+(parseInt(service.price) * parseInt(service.count))+'тг, ';
			}
			for(let material of this.orderinfo.materials){
				msgtext += material.name+' '+(parseInt(material.price) * parseInt(material.count))+'тг, ';
			}
			if(msgtext != '') msgtext = msgtext.substr(0, msgtext.length - 2);
			
			let mobtels = this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value.replace(/\s+/g, '').replace('+7', '8'));
			if(mobtels.length > 0){
				if(mobtels.length > 1){
					let that = this;
					this.$vfm.show({
						component: SelectMobtelModal,
						on: {
							select(mobtel, close){
								window.open('sms:'+mobtel+'?&body=Здравствуйте. '+msgtext+'. Итого '+that.orderCost+' тг', '_blank');
								close();
							},
							cancel(close){
								close();
							},
						},
						params: {
							mobtels,
						},
					});
				} else {
					window.open('sms:'+mobtels[0]+'?&body=Здравствуйте. '+msgtext+'. Итого '+this.orderCost+' тг', '_blank');
				}
			} else {
				alert('У клиента отсутствуют номера мобильных телефонов!');
			}
		},
		// отправка whatsapp при статусе "на согласовании"
		onOrderSendWa1(){
			let msgtext = '';
			for(let service of this.orderinfo.services){
				msgtext += service.name+' '+(parseInt(service.price) * parseInt(service.count))+'тг, ';
			}
			for(let material of this.orderinfo.materials){
				msgtext += material.name+' '+(parseInt(material.price) * parseInt(material.count))+'тг, ';
			}
			if(msgtext != '') msgtext = msgtext.substr(0, msgtext.length - 2);
			
			let mobtels = this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value.replace(/\s+/g, ''));
			if(mobtels.length > 0){
				if(mobtels.length > 1){
					let that = this;
					this.$vfm.show({
						component: SelectMobtelModal,
						on: {
							select(mobtel, close){
								if(!that.$device.isMobile){
									window.open('https://wa.me/'+mobtel.replace(/\+/g, '').replace(/\s/g, '')+'?text='+encodeURI('Здравствуйте. '+msgtext+'. Итого '+that.orderCost+' тг'), '_blank');
								} else {
									window.open('whatsapp://send?&phone='+mobtel+'&text='+encodeURI('Здравствуйте. '+msgtext+'. Итого '+that.orderCost+' тг'), '_blank');
								}
								close();
							},
							cancel(close){
								close();
							},
						},
						params: {
							mobtels,
						},
					});
				} else {
					if(!this.$device.isMobile){
						window.open('https://wa.me/'+mobtels[0].replace(/\+/g, '').replace(/\s/g, '')+'?text='+encodeURI('Здравствуйте. '+msgtext+'. Итого '+this.orderCost+' тг'), '_blank');
					} else {
						window.open('whatsapp://send?&phone='+mobtels[0]+'&text='+encodeURI('Здравствуйте. '+msgtext+'. Итого '+this.orderCost+' тг'), '_blank');
					}
				}
			} else {
				alert('У клиента отсутствуют номера мобильных телефонов!');
			}
		},
		// отправка sms при статусе "готов"
		onOrderSendSms2(){
			let mobtels = this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value.replace(/\s+/g, '').replace('+7', '8'));
			if(mobtels.length > 0){
				if(mobtels.length > 1){
					let that = this;
					this.$vfm.show({
						component: SelectMobtelModal,
						on: {
							select(mobtel, close){
								window.open('sms:'+mobtel+'?&body=Здравствуйте. Инструмент готов', '_blank');
								close();
							},
							cancel(close){
								close();
							},
						},
						params: {
							mobtels,
						},
					});
				} else {
					window.open('sms:'+mobtels[0]+'?&body=Здравствуйте. Инструмент готов', '_blank');
				}
			} else {
				alert('У клиента отсутствуют номера мобильных телефонов!');
			}
		},
		// отправка whatsapp при статусе "готов"
		onOrderSendWa2(){
			let mobtels = this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value.replace(/\s+/g, ''));
			if(mobtels.length > 0){
				if(mobtels.length > 1){
					let that = this;
					this.$vfm.show({
						component: SelectMobtelModal,
						on: {
							select(mobtel, close){
								if(!that.$device.isMobile){
									window.open('https://wa.me/'+mobtel.replace(/\+/g, '').replace(/\s/g, '')+'?text='+encodeURI('Здравствуйте. Инструмент готов'), '_blank');
								} else {
									window.open('whatsapp://send?&phone='+mobtel+'&text='+encodeURI('Здравствуйте. Инструмент готов'), '_blank');
								}
								close();
							},
							cancel(close){
								close();
							},
						},
						params: {
							mobtels,
						},
					});
				} else {
					if(!this.$device.isMobile){
						window.open('https://wa.me/'+mobtels[0].replace(/\+/g, '').replace(/\s/g, '')+'?text='+encodeURI('Здравствуйте. Инструмент готов'), '_blank');
					} else {
						window.open('whatsapp://send?&phone='+mobtels[0]+'&text='+encodeURI('Здравствуйте. Инструмент готов'), '_blank');
					}
				}
			} else {
				alert('У клиента отсутствуют номера мобильных телефонов!');
			}
		},
		// отправка ссылки на ремонт по sms
		onOrderLinkSendSms(){
			let mobtels = this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value.replace(/\s+/g, '').replace('+7', '8'));
			if(mobtels.length > 0){
				if(mobtels.length > 1){
					let that = this;
					this.$vfm.show({
						component: SelectMobtelModal,
						on: {
							select(mobtel, close){
								window.open('sms:'+mobtel+'?&body='+that.appModel.project_url+'/orderinfo/'+that.orderinfo.id, '_blank');
								close();
							},
							cancel(close){
								close();
							},
						},
						params: {
							mobtels,
						},
					});
				} else {
					window.open('sms:'+mobtels[0]+'?&body='+this.appModel.project_url+'/orderinfo/'+this.orderinfo.id, '_blank');
				}
			} else {
				alert('У клиента отсутствуют номера мобильных телефонов!');
			}
		},
		// отправка ссылки на ремонт в whatsapp
		onOrderLinkSendWa(){
			let mobtels = this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value.replace(/\s+/g, ''));
			if(mobtels.length > 0){
				if(mobtels.length > 1){
					let that = this;
					this.$vfm.show({
						component: SelectMobtelModal,
						on: {
							select(mobtel, close){
								if(!that.$device.isMobile){
									window.open('https://wa.me/'+mobtel.replace(/\+/g, '').replace(/\s/g, '')+'?text='+encodeURI(that.appModel.project_url+'/orderinfo/'+that.orderinfo.id), '_blank');
								} else {
									window.open('whatsapp://send?&phone='+mobtel+'&text='+encodeURI(that.appModel.project_url+'/orderinfo/'+that.orderinfo.id), '_blank');
								}
								close();
							},
							cancel(close){
								close();
							},
						},
						params: {
							mobtels,
						},
					});
				} else {
					if(!this.$device.isMobile){
						window.open('https://wa.me/'+mobtels[0].replace(/\+/g, '').replace(/\s/g, '')+'?text='+encodeURI(this.appModel.project_url+'/orderinfo/'+this.orderinfo.id), '_blank');
					} else {
						window.open('whatsapp://send?&phone='+mobtels[0]+'&text='+encodeURI(this.appModel.project_url+'/orderinfo/'+this.orderinfo.id), '_blank');
					}
				}
			} else {
				alert('У клиента отсутствуют номера мобильных телефонов!');
			}
		},
		// показ ссылки на страницу ремонта
		onOrderLinkShow(){
			prompt('Ссылка на страницу ремонта', this.appModel.project_url+'/orderinfo/'+this.$route.params.id);
		},
		
		// добавление новой строки для ввода телефона клиента
		onTelAdd(){
			this.clientinfo.tel.push({value: ''});
		},
		// удаление строки ввода телефона клиента
		onTelDel(index){
			this.clientinfo.tel.splice(index, 1);
		},
		// добавление новой строки для ввода мобильного телефона клиента
		onMobtelAdd(){
			this.clientinfo.mobtel.push({value: ''});
		},
		// удаление строки ввода мобильного телефона клиента
		onMobtelDel(index){
			this.clientinfo.mobtel.splice(index, 1);
		},
		// изменение типа изображения
		async onOrderImageEditType(type, image_pkid){
			this.loading2 = true;
			this.loadingImg = true;
			this.error = '';
			let ret = await this.appModel.doEditTypeImageOrder(this.orderinfo.id, {
				image_pkid,
				type,
			});
			if(ret.success){
				ret = await this.appModel.doGetImagesOrder(this.orderinfo.id);
				if(ret.success){
					this.orderinfo.images = ret.order_images;
				} else {
					this.error = ret.message;
				}
			} else {
				this.error = ret.message;
			}
			this.loading2 = false;
			this.loadingImg = false;
		},
		// сохранение изменений ремонта
		async onOrderSave(){
			this.loading2 = true;
			this.loadingSave = true;
			this.error = '';
			let ret = await this.appModel.doEditOrder(this.orderinfo.id, {
				client_id: this.clientinfo.id,
				fio: this.clientinfo.fio,
				tel: this.clientinfo.tel.filter((el) => el.value != '').map((el) => el.value),
				mobtel: this.clientinfo.mobtel.filter((el) => el.value != '').map((el) => el.value),
				city: this.clientinfo.city,
				address: this.clientinfo.address,
				email: this.clientinfo.email,
				status: this.clientinfo.status,
				description: this.clientinfo.comment,
				wheretheylearn_id: this.clientinfo.wheretheylearn_id,
				instrument_id: this.orderinfo.instrument_id,
				instrument_type_id: this.orderinfo.instrument_type_id,
				instrument_size_id: this.orderinfo.instrument_size_id,
				firm_id: this.orderinfo.instrument_firm_id,
				casename_id: this.orderinfo.casename_id,
				instrument_color: this.orderinfo.instrument_color,
				instrument_model: this.orderinfo.instrument_model,
				instrument_serial_number: this.orderinfo.instrument_serial_number,
				defect_descr: this.orderinfo.defect_descr,
				complection: this.orderinfo.complection,
				comment: this.orderinfo.comment,
				amount_paid: this.orderinfo.amount_paid,
				discount: this.orderinfo.discount,
				dateadd: moment.tz(this.orderinfo.dateadd, 'Asia/Almaty').utc().format('YYYY-MM-DDTHH:mm'),
			});
			if(ret.success){
				await this.loadData();
			} else {
				this.error = ret.message;
			}
			this.loading2 = false;
			this.loadingSave = false;
		},
		
		// функции
		// получение длительности ремонта
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		await this.loadOrdersStatuses();
		await this.loadInstrumentNames();
		await this.loadInstrumentTypes();
		await this.loadInstrumentSizes();
		await this.loadFirms();
		await this.loadCaseNames();
		await this.loadWhereTheyLearns();
		
		await this.loadData();
		
		autosize(this.$refs.client_comment);
		autosize(this.$refs.order_defect_descr);
		autosize(this.$refs.order_complection);
		autosize(this.$refs.order_comment);
		
		setTimeout(() => {
			this.orderSaveEnable = false;
		}, 200);
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteLeave(to, from, next){
		if(this.orderSaveEnable){
			if(confirm('Имеются не сохраненные изменения! Вы действительно хотите их отменить?')){
				next();
			}
		} else {
			next();
		}
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		VueBootstrapTypeahead,
		Lightgallery,
		BigUpload,
	},
};
</script>