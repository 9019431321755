<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal bootstrap-dialog type-default size-wide fade in" content-class="modal-dialog modal-lg" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header">
				<div class="bootstrap-dialog-header">
					<div class="bootstrap-dialog-close-button" style="display: none;">
						<button type="button" class="close" @click="close">×</button>
					</div>
					<div class="bootstrap-dialog-title">Изменение клиента</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="bootstrap-dialog-body">
					<div class="bootstrap-dialog-message">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Фамилия, Имя и Отчество клиента <span class="text-danger">*</span></label>
									<input type="text" ref="fio" placeholder="ФИО клиента" class="form-control text-center" v-model="fio" required />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-5">
								<div class="form-group">
									<label>Телефоны</label>
									<div class="input-group">
										<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="tels[0].value" />
										<span class="input-group-btn">
											<button type="button" class="btn btn-white" @click="onTelAdd()">
												<i class="fa fa-plus"></i>
											</button>
										</span>
									</div>
								</div>
								<div class="form-group" v-for="(tel, index) in tels.slice(1)">
									<div class="input-group">
										<input type="text" class="form-control text-center" v-maska data-maska="##-##-##" v-model="tel.value" />
										<span class="input-group-btn">
											<button type="button" class="btn btn-white" @click="onTelDel(index + 1)">
												<i class="fa fa-minus"></i>
											</button>
										</span>
									</div>
								</div>
							</div>
							<div class="col-md-7">
								<div class="form-group">
									<label>Мобильные телефоны</label>
									<div class="input-group">
										<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="mobtels[0].value" />
										<span class="input-group-btn">
											<button type="button" class="btn btn-white" @click="onMobtelAdd()">
												<i class="fa fa-plus"></i>
											</button>
										</span>
									</div>
								</div>
								<div class="form-group" v-for="(mobtel, index) in mobtels.slice(1)">
									<div class="input-group">
										<input type="text" class="form-control text-center" v-maska data-maska="+7 ### ### ####" v-model="mobtel.value" />
										<span class="input-group-btn">
											<button type="button" class="btn btn-white" @click="onMobtelDel(index + 1)">
												<i class="fa fa-minus"></i>
											</button>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-4">
								<div class="form-group">
									<label>Город</label>
									<input type="text" ref="city" placeholder="Город" class="form-control" v-model="city" />
								</div>
							</div>
							<div class="col-md-8">
								<div class="form-group">
									<label>Адрес клиента</label>
									<input type="text" ref="address" placeholder="Адрес клиента" class="form-control" v-model="address" />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-5">
								<div class="form-group">
									<label>Email клиента</label>
									<input type="email" ref="email" placeholder="Email клиента" class="form-control" v-model="email" />
								</div>
							</div>
							<div class="col-md-7">
								<div class="form-group">
									<label>Статус клиента</label>
									<input type="text" ref="status" placeholder="Статус клиента" class="form-control" v-model="status" />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-8">
								<div class="form-group" :class="{'m-b-none': !error}">
									<label>Заметка к клиенту</label>
									<textarea ref="comment" placeholder="Заметка к клиенту" class="form-control client-description" style="resize: none; overflow: hidden; overflow-wrap: break-word; height: 54px;" v-model="comment"></textarea>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group" :class="{'m-b-none': !error}">
									<label>Откуда узнали о мастере</label>
									<select class="form-control" v-model="wheretheylearn_id">
										<option :value="wheretheylearn.id" v-for="wheretheylearn in wheretheylearns" :key="wheretheylearn.id">{{wheretheylearn.name}}</option>
									</select>
								</div>
							</div>
						</div>
						
						<div class="alert alert-warning alert-dismissable m-b-none" v-if="error">
							<button aria-hidden="true" class="close" type="button" @click="error = ''">×</button>
							{{error}}
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="bootstrap-dialog-footer">
					<div class="bootstrap-dialog-footer-buttons">
						<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
						<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
					</div>
				</div>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import { vMaska } from 'maska';
import autosize from 'autosize';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	directives: {
		maska: vMaska,
	},
	data: () => ({
		wheretheylearns: [],
		
		fio: '',
		tels: [{
			value: '',
		}],
		mobtels: [{
			value: '',
		}],
		city: '',
		address: '',
		email: '',
		status: '',
		comment: '',
		wheretheylearn_id: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onEdit(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditClient(this.$vfm.modals[0].params._value.client.id, {
				fio: this.fio,
				tels: this.tels.filter((el) => el.value != '').map((el) => el.value),
                mobtels: this.mobtels.filter((el) => el.value != '').map((el) => el.value),
                city: this.city,
                address: this.address,
                email: this.email,
				status: this.status,
                comment: this.comment,
                wheretheylearn_id: this.wheretheylearn_id,
			});
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		onTelAdd(){
			this.tels.push({value: ''});
		},
		onTelDel(index){
			this.tels.splice(index, 1);
		},
		onMobtelAdd(){
			this.mobtels.push({value: ''});
		},
		onMobtelDel(index){
			this.mobtels.splice(index, 1);
		},
	},
	mounted(){
		this.wheretheylearns = this.$vfm.modals[0].params._value.wheretheylearns;
		
		this.fio = this.$vfm.modals[0].params._value.client.fio;
		this.tels = (this.$vfm.modals[0].params._value.client.tel.length > 0 ? this.$vfm.modals[0].params._value.client.tel : ['']).map((el) => ({value: el}));
		this.mobtels = (this.$vfm.modals[0].params._value.client.mobtel.length > 0 ? this.$vfm.modals[0].params._value.client.mobtel : ['']).map((el) => ({value: el}));
		this.city = this.$vfm.modals[0].params._value.client.city;
		this.address = this.$vfm.modals[0].params._value.client.address;
		this.email = this.$vfm.modals[0].params._value.client.email;
		this.status = this.$vfm.modals[0].params._value.client.status;
		this.comment = this.$vfm.modals[0].params._value.client.comment;
		this.wheretheylearn_id = this.$vfm.modals[0].params._value.client.wheretheylearn_id;
		
		setTimeout(() => {
			this.$refs.fio.focus();
			
			autosize(this.$refs.comment);
		}, 300);
	},
	components: {},
}
</script>