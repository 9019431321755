<template>
	<div id="wrapper">
		<nav class="navbar-default navbar-static-side" style="overflow-y: auto;" role="navigation" v-if="navbarShow">
			<div class="sidebar-collapse">
				<ul class="nav metismenu" id="side-menu">
					<router-link custom :to="{path: (item.path||'')}" v-slot="{href, route, navigate, isActive, isExactActive}" v-for="item in menu">
						<li :class="{'active': isExactActive}" v-if="item.type == 'link'">
							<a :href="href" @click="onMenuClick($event, navigate)">
								<i :class="item.icon"></i>
								<span class="nav-label">{{item.name}}</span>
							</a>
						</li>
						<li v-if="item.type == 'divider'" class="text-center">- - - - - - - - - - - - - - - - - - - - - - - -</li>
					</router-link>
				</ul>
			</div>
		</nav>
		<div id="page-wrapper" class="gray-bg">
			<div class="row border-bottom" v-if="authModel.isAuth()">
				<nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0;">
					<div class="navbar-header">
						<a class="navbar-minimalize minimalize-styl-2 btn btn-primary" href="javascript://" @click="onNavbarToggle()">
							<i class="fa fa-bars"></i>
						</a>
					</div>
					<ul class="nav navbar-top-links navbar-right pull-right">
						<li>
							<router-link :to="{path: '/logout'}" class="admin-logout-btn">
								<i class="fa fa-sign-out"></i> Выйти
							</router-link>
						</li>
					</ul>
				</nav>
			</div>
			<router-view/>
		</div>
	</div>
</template>

<style lang="sass">
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'MasterSMI Учет'});
	},
	data: () => ({
		menu: [
			{type: 'link', path: '/', icon: 'fa fa-dashboard', name: 'Статистика'},
			{type: 'divider'},
			{type: 'link', path: '/orders', icon: 'fa fa-gavel', name: 'Ремонты'},
			{type: 'link', path: '/clients', icon: 'fa fa-users', name: 'Клиенты'},
			{type: 'link', path: '/warehouse', icon: 'fa fa-inbox', name: 'Склад'},
			{type: 'divider'},
			{type: 'link', path: '/sales', icon: 'fa fa-inbox', name: 'Продажи'},
			{type: 'link', path: '/goods', icon: 'fa fa-inbox', name: 'Товары'},
			{type: 'divider'},
			{type: 'link', path: '/lists', icon: 'fa fa-database', name: 'Справочники'},
			{type: 'divider'},
			{type: 'link', path: '/reports', icon: 'fa fa-bar-chart-o', name: 'Отчеты'},
		],
		
		navbarShow: true,
	}),
	methods: {
		onMenuClick(event, navigate){
			if(this.$device.isMobile){
				document.body.classList.remove('mini-navbar');
				this.navbarShow = false;
			}
			navigate(event);
		},
		
		// показ/скрытие navbar
		onNavbarToggle(){
			this.navbarShow = !this.navbarShow;
			if(this.$device.isMobile){
				if(this.navbarShow){
					document.body.classList.add('mini-navbar');
				} else {
					document.body.classList.remove('mini-navbar');
				}
			} else {
				if(this.navbarShow){
					document.body.classList.remove('mini-navbar');
				} else {
					document.body.classList.add('mini-navbar');
				}
			}
		},
		onResize(){
			document.getElementById('page-wrapper').style.minHeight = window.innerHeight + 'px';
		},
	},
	mounted(){
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	beforeMount(){
		window.scrollTo(0, 0);
		
		if(this.$device.isMobile){
			document.body.classList.add('body-small');
			this.navbarShow = false;
		}
		
		document.body.classList.add('fixed-sidebar');
		document.body.classList.add('no-skin-config');
		document.body.classList.add('full-height-layout');
	},
	beforeUnmount(){
		document.body.classList.remove('fixed-sidebar');
		document.body.classList.remove('no-skin-config');
		document.body.classList.remove('full-height-layout');
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		document.body.classList.add('fixed-sidebar');
		document.body.classList.add('no-skin-config');
		document.body.classList.add('full-height-layout');
	},
	beforeDestroy(){
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.authModel.doLogout();
				}
				throw err;
			});
		});
	},
}
</script>